import cogoToast from "cogo-toast";
import { ReactElement, useContext, useEffect, useState } from "react"
import { Card, Spinner } from "react-bootstrap";
import { useParams } from "react-router";
import { getOrdenProceso } from "../api/OrdenesProcesoApi";
import { getReparacion } from "../api/ReparacionesApi";
import ReparacionDetails from "../components/reparaciones/ReparacionDetails";
import AuthContext from "../contexts/AuthContext";
import useBreadcrumb from "../hooks/useBreadcrumb";
import { OrdenProceso } from "../models/estatusGeneral/OrdenProceso";
import { Reparacion } from "../models/Reparaciones"
import { getUserPermissionFunction } from "../utils/users";
import DetallesEvaluacionReparacionApi from "../api/EvaluacionesReparacionesApi";
import { EvaluacionOrReparacionComponenteResumenEtapa } from "../models/EvaluacionesReparaciones";
import { Persona } from "../models/users/Users";

const ReparacionPage = (): ReactElement => {

    const [ reparacion, setReparacion ] = useState<Reparacion>();
    const [ evaulacionReparacionComponenteResumenEtapas, setEvaulacionReparacionComponenteResumenEtapas ] = useState<EvaluacionOrReparacionComponenteResumenEtapa[]>();
    const [ ordenProceso, setOrdenProceso ] = useState<OrdenProceso>();
    const { codigoOP } = useParams<{ codigoOP: string }>();
    const { userRoles, userEmail, userName, userRut } = useContext(AuthContext);
    const breadcrumb = useBreadcrumb();
    const [ isLoading, setIsLoading ] = useState<boolean>(true);
    const detallesApi = new DetallesEvaluacionReparacionApi(codigoOP, true);
    const [ participantesGenerales, setParticipantesGenerales ] = useState<Persona[]>([]);

    const changeParticipantesGenerales = (resumen: EvaluacionOrReparacionComponenteResumenEtapa) => {
        setParticipantesGenerales(resumen.participantes?.map((participante) => ({ name: participante.name, email: participante.email, rut: participante.rut })) || []);
    }

    useEffect(() => {
        if (reparacion) {
            breadcrumb.setItems([
                {
                    label: 'Estatus General',
                    href: '/ordenesProceso'
                },
                {
                    label: codigoOP,
                    href: `/ordenesProceso/${codigoOP}`
                },
                {
                    label: 'Reparación',
                    inactive: true
                }
            ]);
        }
    }, [reparacion])

    useEffect(() => {
        getReparacion(codigoOP)
            .then(async (response) => {
                await getOrdenProceso(codigoOP)
                    .then(async (response) => {
                        if (response.data.codigoOP) {
                            await detallesApi.getEvaluacionOrReparacionComponenteResumen()
                                .then(async (response) => {
                                    if (response.data) {
                                        await detallesApi.getEvaluacionOrReparacionComponenteParticipantesGenerales()
                                            .then((response) => {
                                                if (response.data) {
                                                    setParticipantesGenerales(response.data.participantes?.map((participante) => ({ name: participante.name, email: participante.email, rut: participante.rut })) || []);
                                                }
                                            })
                                            .catch(() => {
                                                cogoToast.error('No fue posible obtener los participantes generales');
                                            })
                                        setEvaulacionReparacionComponenteResumenEtapas(response.data);
                                    }
                                })
                                .catch(() => {
                                    cogoToast.error('No fue posible obtener el detalle de la reparación')
                                })
                            setOrdenProceso(response.data);
                        } else {
                            // cogoToast.error(response.data);
                            cogoToast.error('No fue posible obtener la orden de proceso asociada al componente');
                        }
                    })
                    .catch(() => {
                        // cogoToast.error(error);
                        cogoToast.error('No fue posible obtener la orden de proceso asociada al componente');
                    });
                setReparacion(response.data);
                setIsLoading(false);
            })
            .catch(() => {
                // cogoToast.error(error);
                cogoToast.error('No fue posible obtener la reparación del componente');
            })
    }, [codigoOP]);

    const changeReparacion = (reparacion: Reparacion) => {
        setReparacion(reparacion);
    }

    if (!reparacion || !ordenProceso) {
        if (isLoading) {
            return (
                <Card>
                    <Card.Body className = "text-center">
                        <Spinner animation = "border"/>
                    </Card.Body>
                </Card>
            )
        } else {
            return <p className = "text-center">No fue posible cargar la reparación del componente</p>
        }
    } else {
        return (
            <>
                <ReparacionDetails
                    reparacion = {reparacion}
                    changeReparacion = {changeReparacion}
                    userPermissionFunction = {getUserPermissionFunction(userRoles, userRut)}
                    evaulacionReparacionComponenteResumenEtapas = {evaulacionReparacionComponenteResumenEtapas}
                    ordenProceso = {ordenProceso}
                    userData = {{ userName: userName, userEmail: userEmail, userRoles: userRoles }}
                    detallesApi = {detallesApi}
                    participantesGenerales = {participantesGenerales}
                    changeParticipantesGenerales = {changeParticipantesGenerales}
                />
            </>
        )
    }
}

export default ReparacionPage;