import { Attachment, Email } from "../models/Email";
import { OrdenProceso } from "../models/estatusGeneral/OrdenProceso";
import { EvaluacionOrReparacionComponenteEtapa } from "../models/EvaluacionesReparaciones";
import { Persona } from "../models/users/Users";
import * as SibApiV3Sdk from "@sendinblue/client";
import cogoToast from "cogo-toast";
import { formatArrayToString, formatGeneralText } from "./inputs";
import { isUserEmailValid } from "./users";

export const generalSendEmailInModal = async (email: Email, userName: string, userEmail: string, setIsSending: (state: boolean) => void, hideModal?: () => void, additionalActionsAfterSend?: () => Promise<void>) => {
    const isUserEmailNotExistsInPersonas = (personas?: Persona[]): boolean => !personas ? true : !personas.some((persona) => persona.email === userEmail);
    const isPersonasEmpty = (personas?: Persona[]): boolean => !personas ? true : (personas[0].email === noAddresses.email || personas[0].email === noCopiedAddresses.email);
    setIsSending(true);
    if (!isPersonasEmpty(email.to)) {
        if (isUserEmailValid(userEmail) && isUserEmailNotExistsInPersonas(email.to) && isUserEmailNotExistsInPersonas(email.cc)) {
            const currentUserPersona = { name: userName, email: userEmail };
            if (isPersonasEmpty(email.cc)) {
                email.cc = [currentUserPersona];
            } else {
                email.cc?.push(currentUserPersona);
            }
        }
        const apiInstance = new SibApiV3Sdk.TransactionalEmailsApi();
        const sendSmtpEmail = new SibApiV3Sdk.SendSmtpEmail();
        apiInstance.setApiKey(
            SibApiV3Sdk.TransactionalEmailsApiApiKeys.apiKey,
            process.env.REACT_APP_EMAIL_API_KEY ||
                "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"
        );
        sendSmtpEmail.sender = {
            "name": userName,
            "email": userEmail
        };
        sendSmtpEmail.subject = email?.subject;
        sendSmtpEmail.htmlContent = email?.htmlContent;
        sendSmtpEmail.to = email?.to;
        sendSmtpEmail.cc = email?.cc;
        sendSmtpEmail.attachment = email?.attachment;
        await apiInstance.sendTransacEmail(sendSmtpEmail)
            .then(async () => {
                if (additionalActionsAfterSend) {
                    await additionalActionsAfterSend();
                }
                setIsSending(false);
                if (hideModal) {
                    hideModal();
                }
                cogoToast.success('Email enviado');
            })
            .catch(() => {
                setIsSending(false);
                if (hideModal) {
                    hideModal();
                }
                cogoToast.error('No fue posible enviar el correo');
            });
    } else {
        cogoToast.error('No existen destinatarios');
        setIsSending(false);
    }
}

export const getAperturaOPEmail = (
    ordenesProceso: OrdenProceso[],
    attachments: Attachment[],
    addresses?: Persona[],
    copiedAddresses?: Persona[]
): Email => {
    const opObs: { codigoOP: string, descripcionComponente: string, obs: string }[] = [];
    const sortedOPs = (((): OrdenProceso[] => {
        const sortedOPsResponse: OrdenProceso[] = [];
        ordenesProceso.map((ordenProceso) => ordenProceso.codigoOP).sort().forEach((codigoOP) => {
            const op = ordenesProceso.find((ordenProceso) => ordenProceso.codigoOP === codigoOP);
            if (op) {
                sortedOPsResponse.push(op);
                if (op.observaciones) {
                    opObs.push({ codigoOP: op.codigoOP, descripcionComponente: op.componente?.descripcion || '', obs: op.observaciones });
                }
            }
        });
        return sortedOPsResponse;
    })());
    return ({
        ...getEmailTemplateGenerals(addresses, copiedAddresses, attachments),
        subject: 'APERTURA DE OP',
        htmlContent: 
            `<html>
                <body>
                    Estimados, <br><br>
                    Se informa apertura de OP<br>
                    <table cellpadding='3' cellspacing='0' width='700' align='left' border='1'>
                        <tr>
                            <th style="text-align:center">RECEPCION</th>
                            <th style="text-align:center">CC</th>
                            <th style="text-align:center">DESCRIPCION DEL COMPONENTE</th>
                            <th style="text-align:center">MODELO</th>
                            <th style="text-align:center">N/S</th>
                            <th style="text-align:center">CLIENTE</th>
                            <th style="text-align:center">GARANTIA</th>
                            <th style="text-align:center">ORDEN PROCESO</th>
                            <th style="text-align:center">G/D CLIENTE</th>
                            <th style="text-align:center">OP ANTERIOR</th>
                            <th style="text-align:center">FECHA ENTREGA ANTERIOR</th>
                        </tr>
                        ${formatArrayToString(sortedOPs.map((op) => (
                            `<tr>
                                <td style="text-align:center">${op.recepcion?.fechaRecepcion || ''}</td>
                                <td style="text-align:center">${op.centroCosto || ''}</td>
                                <td style="text-align:center">${op.componente?.descripcion || ''}</td>
                                <td style="text-align:center">${op.componente?.modelo ? (op.recepcion?.subModeloComponente ? `${op.componente.modelo}${op.recepcion?.subModeloComponente}` : op.componente.modelo) : ''}</td>
                                <td style="text-align:center">${op.numeroSerieComponente || ''}</td>
                                <td style="text-align:center">${op.cliente || ''}</td>
                                <td style="text-align:center">${op.proceso || ''}</td>
                                <td style="text-align:center">${op.codigoOP || ''}</td>
                                <td style="text-align:center">${op.recepcion?.numeroGuiaDespacho || ''}</td>
                                <td style="text-align:center">${op.opTrazabilidad?.op || ''}</td>
                                <td style="text-align:center">${op.opTrazabilidad?.fechaEntrega || ''}</td>
                            </tr>`
                        )), false, true)}
                    </table><br><br>
                    ${formatArrayToString(opObs.map((opObs) => (`
                        Observaciones OP ${opObs.codigoOP} - ${opObs.descripcionComponente}:<br><br>
                        ${opObs.obs}<br><br>
                    `)), true, false, true)}
                </body>
            </html>`
    });
}

export const getReporteOPNSEmail = (ordenProceso: OrdenProceso, evaluacionComponenteEtapa: EvaluacionOrReparacionComponenteEtapa, addresses?: Persona[], copiedAddresses?: Persona[]): Email => {
    let opEtapa;
    let nsEtapa;
    let breakCounter = 0;
    for (let i = 0; i < evaluacionComponenteEtapa.tareas.length; i++) {
        if (evaluacionComponenteEtapa.tareas[i].nombre === 'Confirmar N/S' || evaluacionComponenteEtapa.tareas[i].nombre === 'Confirmar OP') {
            const inputs = evaluacionComponenteEtapa.tareas[i].inputs;
            if (inputs) {
                for (let e = 0; e < inputs.length; e++) {
                    if (evaluacionComponenteEtapa.tareas[i].nombre === 'Confirmar N/S' && inputs[e].name === 'Número de serie') {
                        nsEtapa = inputs[e].value;
                        break;
                    } else if (evaluacionComponenteEtapa.tareas[i].nombre === 'Confirmar OP' && inputs[e].name === 'Orden de proceso') {
                        opEtapa = inputs[e].value;
                        break;
                    }
                }
            }
            breakCounter++;
        }
        if (breakCounter === 2) {
            break;
        }
    }
    return {
        ...getEmailTemplateGenerals(addresses, copiedAddresses),
        subject: 'Diferencias de OP y/o N/S',
        htmlContent: 
        `<html>
            <body>
                Estimados, <br><br>
                Se informa incongruencias entre los datos ingresados en la recepción y evaluación del componente de la OP ${ordenProceso.codigoOP}<br><br>
                ${opEtapa !== ordenProceso.codigoOP && 
                    `Órden de proceso en recepción: ${ordenProceso.codigoOP}<br>
                    Órden de proceso en evaluación de componente: ${opEtapa && opEtapa}<br><br>`
                }
                ${nsEtapa !== ordenProceso.numeroSerieComponente && 
                    `Número de serie en recepción: ${ordenProceso.numeroSerieComponente}<br>
                    Número de serie en evaluación de componente: ${nsEtapa && nsEtapa}<br>`
                }
                
            </body>
        </html>`
    };
}

export const getCorreoEntregaEvaluacionReparacion = (
    etapaProceso: string,
    ordenProceso: OrdenProceso,
    // attachments: Attachment[],
    addresses?: Persona[],
    copiedAddresses?: Persona[],
    fechaFinal?: string,
    notas?: string,
    grado?: string,
    reparadoPor?: string,
    liberadoPor?: string,
    fechaLiberacion?: string,
    evaluadoPor?: string
): Email => {
    const isEvaluacion = etapaProceso === 'evaluación';
    return {
        ...getEmailTemplateGenerals(addresses, copiedAddresses),
        subject: `Entrega de ${etapaProceso}${ordenProceso.componente?.descripcion ? ` - ${ordenProceso.componente?.descripcion}` : ''}${ordenProceso.codigoOP ? ` - ${ordenProceso.codigoOP}` : ''}${ordenProceso.cliente ? ` - ${ordenProceso.cliente}` : ''}`,
        htmlContent: 
            `<html>
                <body>
                    Estimados,<br><br>
                    Se informa entrega de ${isEvaluacion ? 'evaluación' : 'reparación y liberación'} de componente.<br>
                    Se avisa también que la OP está lista para cambiar de estado en la plataforma por el área de planificación.<br><br>
                    Los documentos del componente pueden ser accedidos en el siguiente enlace:
                    <a href='http://ec2-15-228-240-5.sa-east-1.compute.amazonaws.com/ordenesProceso/${ordenProceso.codigoOP}/${isEvaluacion ? 'evaluacion' : 'reparacion'}' target="_blank">${ordenProceso.codigoOP}</a>
                    <br><br>
                    ${notas ? `Notas:<br>${formatGeneralText(notas, true)}<br><br>` : ''}
                    <table cellpadding='3' cellspacing='0' width='700' align='left' border='1'>
                        <tr>
                            <th style="text-align:center">FECHA RECEPCIÓN</th>
                            <th style="text-align:center">COMPONENTE</th>
                            <th style="text-align:center">MODELO</th>
                            <th style="text-align:center">N/P</th>
                            <th style="text-align:center">N/S</th>
                            <th style="text-align:center">CLIENTE</th>
                            <th style="text-align:center">ORDEN PROCESO</th>
                            <th style="text-align:center">EVALUADO POR</th>
                            <th style="text-align:center">FECHA EVALUACIÓN</th>
                            ${isEvaluacion ? '' :
                                '<th style="text-align:center">REPARADO POR</th><th style="text-align:center">FECHA REPARACIÓN</th><th style="text-align:center">LIBERADO POR</th><th style="text-align:center">FECHA LIBERACIÓN</th>'
                            }
                            ${!grado ? '' : '<th style="text-align:center">TIPO</th>'}
                        </tr>
                        <tr>
                            <td style="text-align:center">${ordenProceso.recepcion?.fechaRecepcion || ''}</td>
                            <td style="text-align:center">${ordenProceso.componente?.descripcion || ''}</td>
                            <td style="text-align:center">${ordenProceso.recepcion?.subModeloComponente ? `${ordenProceso.componente?.modelo}${ordenProceso.recepcion?.subModeloComponente}` : (ordenProceso.componente?.modelo || '')}</td>
                            <td style="text-align:center">${ordenProceso.numeroParteCliente || ''}</td>
                            <td style="text-align:center">${ordenProceso.numeroSerieComponente || ''}</td>
                            <td style="text-align:center">${ordenProceso.cliente || ''}</td>
                            <td style="text-align:center">${ordenProceso.codigoOP || ''}</td>
                            <td style="text-align:center">${evaluadoPor || ''}</td>
                            <td style="text-align:center">${(isEvaluacion ? fechaFinal : ordenProceso.evaluacion?.fechaFinal) || ''}</td>
                            ${isEvaluacion ? '' :
                                `<td style="text-align:center">${reparadoPor || ''}</td>
                                <td style="text-align:center">${fechaFinal || ''}</td>
                                <td style="text-align:center">${liberadoPor || ''}</td>
                                <td style="text-align:center">${fechaLiberacion || ''}</td>`
                            }
                            ${!grado ? '' : `<td style="text-align:center">${grado}</td>`}
                        </tr>
                    </table>
                </body>
            </html>`
    };
}

export const getCorreoOrdenProcesoAprobada = (ordenProceso: OrdenProceso, addresses?: Persona[], copiedAddresses?: Persona[]): Email => ({
    ...getEmailTemplateGenerals(addresses, copiedAddresses),
    subject: 'Estado de OP aprobada',
    htmlContent: 
        `<html>
            <body>
                Estimados,<br><br>
                Se informa aprobación de orden de proceso.<br>
                Enlace de OP en la página: <a href="http://ec2-15-228-240-5.sa-east-1.compute.amazonaws.com/ordenesProceso/${ordenProceso.codigoOP}" target="_blank">${ordenProceso.codigoOP}</a><br><br>
                <table cellpadding='3' cellspacing='0' width='700' align='left' border='1'>
                    <tr>
                        <th style="text-align:center">DESCRIPCION DEL COMPONENTE</th>
                        <th style="text-align:center">MODELO</th>
                        <th style="text-align:center">N/S</th>
                        <th style="text-align:center">CLIENTE</th>
                        <th style="text-align:center">ORDEN PROCESO</th>
                        <th style="text-align:center">CC</th>
                        <th style="text-align:center">ESTADO</th>
                    </tr>
                    <tr>
                        <td style="text-align:center">${ordenProceso.componente?.descripcion || ''}</td>
                        <td style="text-align:center">${ordenProceso.recepcion?.subModeloComponente ? `${ordenProceso.componente?.modelo}${ordenProceso.recepcion?.subModeloComponente}` : (ordenProceso.componente?.modelo || '')}</td>
                        <td style="text-align:center">${ordenProceso.numeroSerieComponente || ''}</td>
                        <td style="text-align:center">${ordenProceso.cliente || ''}</td>
                        <td style="text-align:center">${ordenProceso.codigoOP || ''}</td>
                        <td style="text-align:center">${ordenProceso.centroCosto || ''}</td>
                        <td style="text-align:center">${ordenProceso.estado || ''}</td>
                    </tr>
                </table>
            </body>
        </html>`
})

// export const getCorreoReporteDespacho = (
//     ordenProceso: OrdenProceso,
//     addresses?: Persona[],
//     copiedAddresses?: Persona[],
// ): Email => {
    
//     return {
//         ...getEmailTemplateGenerals(addresses, copiedAddresses),
//         subject: `entrega de componentes cliente ${ordenProceso.cliente} GD ${((): string => {
//             let response = '';

//         })()}`,
//         htmlContent: 
//             `<html>
//                 <body>
//                     Estimad@s,<br><br>
//                     Se informa entrega de ${etapaProceso} de componente.<br>
//                     Se avisa también que la OP está lista para cambiar de estado en la plataforma por el área de planificación.<br><br>
//                     Los documentos del componente pueden ser accedidos en el siguiente enlace:
//                     <a href='http://ec2-15-228-240-5.sa-east-1.compute.amazonaws.com/ordenesProceso/${ordenProceso.codigoOP}/${isEvaluacion ? 'evaluacion' : 'reparacion'}' target="_blank">${ordenProceso.codigoOP}</a>
//                     <br><br>
//                     ${notas ? `Notas:<br>${formatGeneralText(notas, true)}<br><br>` : ''}
//                     <table cellpadding='3' cellspacing='0' width='700' align='left' border='1'>
//                         <tr>
//                             <th style="text-align:center">FECHA RECEPCIÓN</th>
//                             <th style="text-align:center">COMPONENTE</th>
//                             <th style="text-align:center">MODELO</th>
//                             <th style="text-align:center">N/P</th>
//                             <th style="text-align:center">N/S</th>
//                             <th style="text-align:center">CLIENTE</th>
//                             <th style="text-align:center">ORDEN PROCESO</th>
//                             <th style="text-align:center">EVALUADO POR</th>
//                             <th style="text-align:center">FECHA EVALUACIÓN</th>
//                             ${isEvaluacion ? '' :
//                                 '<th style="text-align:center">REPARADO POR</th><th style="text-align:center">FECHA REPARACIÓN</th><th style="text-align:center">LIBERADO POR</th><th style="text-align:center">FECHA LIBERACIÓN</th>'
//                             }
//                             ${!grado ? '' : '<th style="text-align:center">TIPO</th>'}
//                         </tr>
//                         <tr>
//                             <td style="text-align:center">${ordenProceso.recepcion?.fechaRecepcion || ''}</td>
//                             <td style="text-align:center">${ordenProceso.componente?.descripcion || ''}</td>
//                             <td style="text-align:center">${ordenProceso.recepcion?.subModeloComponente ? `${ordenProceso.componente?.modelo}${ordenProceso.recepcion?.subModeloComponente}` : (ordenProceso.componente?.modelo || '')}</td>
//                             <td style="text-align:center">${ordenProceso.numeroParteCliente || ''}</td>
//                             <td style="text-align:center">${ordenProceso.numeroSerieComponente || ''}</td>
//                             <td style="text-align:center">${ordenProceso.cliente || ''}</td>
//                             <td style="text-align:center">${ordenProceso.codigoOP || ''}</td>
//                             <td style="text-align:center">${evaluadoPor || ''}</td>
//                             <td style="text-align:center">${isEvaluacion ? (fechaFinal || '') : (ordenProceso.evaluacion?.fechaFinal || '')}</td>
//                             ${isEvaluacion ? '' :
//                                 `<td style="text-align:center">${reparadoPor || ''}</td>
//                                 <td style="text-align:center">${fechaFinal || ''}</td>
//                                 <td style="text-align:center">${liberadoPor || ''}</td>
//                                 <td style="text-align:center">${fechaLiberacion || ''}</td>`
//                             }
//                             ${!grado ? '' : `<td style="text-align:center">${grado}</td>`}
//                         </tr>
//                     </table>
//                 </body>
//             </html>`
//     }
// }

const getEmailTemplateGenerals = (addresses?: Persona[], copiedAddresses?: Persona[], attachments?: Attachment[]): any => {
    const areThereAddresses = addresses ? addresses.length > 0 : false;
    const areThereCopiedAddresses = copiedAddresses ? copiedAddresses.length > 0 : false;
    let response;
    response = {};
    if (areThereAddresses || areThereCopiedAddresses) {
        if (areThereAddresses) {
            response = { ...response, to: addresses };
        } else {
            response = { ...response, to: copiedAddresses };
        }
        if (areThereCopiedAddresses) {
            if (areThereAddresses) {
                response = { ...response, cc: copiedAddresses };
            } else {
                response = { ...response, cc: [noCopiedAddresses] };
            }
        }
    } else {
        response = { ...response, to: [noAddresses], cc: [noCopiedAddresses] };
    }
    if (attachments && attachments.length > 0) {
        const formatAttachments: Attachment[] = []
        for (let i = 0; i < attachments.length; i++) {
            if (attachments[i].content.substring(0, 4) === 'data') {
                formatAttachments.push({ name: attachments[i].name, content: attachments[i].content.substring(attachments[i].content.indexOf(",") + 1) })
            } else {
                formatAttachments.push(attachments[i]);
            }
        }
        response = { ...response, attachment: formatAttachments }
    }
    return response;
}

const noAddresses = { name: 'noto', email: 'noto@noto.com' };

const noCopiedAddresses = { name: 'nocc', email: 'nocc@nocc.com' };