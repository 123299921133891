import { ReactElement, useContext, useState } from "react";
import { ServiciosExternosFilters } from "../../models/serviciosExternos/ServicioExterno";
import { getEmptyServiciosExternosFilters } from "../../utils/serviciosExternos";
import { Card } from "react-bootstrap";
import ServiciosExternosList from "../../components/serviciosExternos/ServiciosExternosList";
import AuthContext from "../../contexts/AuthContext";
import NuevoServicioExterno from "../../components/serviciosExternos/NuevoServicioExterno";
import { getUserPermissionFunction } from "../../utils/users";
import useGalponPlanificacion from "../../hooks/planificaciones/useGalponPlanificacion";

const ServiciosExternosPage = (): ReactElement => {
  const [serviciosExternosFilters, setServiciosExternosFilters] =
    useState<ServiciosExternosFilters>(getEmptyServiciosExternosFilters());
  const galponServicioOptions = useGalponPlanificacion();

  const { userRoles } = useContext(AuthContext);

  const [refresh, setRefresh] = useState(false);

  const handleRefresh = () => {
    setRefresh((prev) => !prev);
  };

  const serviciosExternosChange = (
    serviciosExternosFilters: ServiciosExternosFilters
  ) => {
    setServiciosExternosFilters(serviciosExternosFilters);
  };

  return (
    <Card className="cards-container">
      <Card.Body className="cards-body-groups">
        <div className="cards-header">
          <h4>Servicios Externos</h4>
          <div className="filters-buttons">
            <NuevoServicioExterno
              userPermission={getUserPermissionFunction(userRoles)({
                roles: ["ADMINISTRADOR", "JEFETALLER", "SUPERVISOR"],
              })}
              galponServiciosOptions={galponServicioOptions}
              onServicioExternoCreated={handleRefresh}
            />
          </div>
        </div>
        <ServiciosExternosList
          serviciosExternosFilters={serviciosExternosFilters}
          setServiciosExternosFilters={serviciosExternosChange}
          refresh={refresh}
        />
      </Card.Body>
    </Card>
  );
};

export default ServiciosExternosPage;
