export const checkNumber = (input: string, onlyIntegersBiggerThanMinus1?: boolean): { isNumber: boolean, isDecimal: boolean } => {
    const isNumberTest = (text: string): boolean => !!text && !(/\D+/).test(text);
    let response = { isNumber: false, isDecimal: false };
    if (onlyIntegersBiggerThanMinus1) {
        response.isNumber = isNumberTest(input) && parseInt(input) >= 0;
    } else {
        if ((/[/\\]/).test(input)) {
            const inputParts = input.split(/[/\\]/);
            if (inputParts.length < 3 && isNumberTest(inputParts[0]) && (!inputParts[1] || isNumberTest(inputParts[1]))) {
                response.isNumber = true;
                if (inputParts[1] && (parseInt(inputParts[0]) % parseInt(inputParts[1])) > 0) {
                    response.isDecimal = true;
                }
            }
        } else {
            const inputParts = input.split(/[,.]/);
            if (inputParts.length < 3 && (isNumberTest(inputParts[0]) || (!inputParts[0] && inputParts.length > 1))) {
                if (inputParts.length === 2) {
                    if (isNumberTest(inputParts[1])) {
                        response.isNumber = true;
                        response.isDecimal = true;
                    } else if (!inputParts[1]) {
                        response.isNumber = true;
                    }
                } else {
                    response.isNumber = true;
                    // response.isDecimal = false;
                }
            }
        }
    }
    return response;
}

const replaceNumberValue = (response: string, regexp: RegExp, valueFunction: (value: string) => string, skipIsNumberTest?: boolean): string =>
    (skipIsNumberTest || checkNumber(response).isNumber) ? response.replaceAll(regexp, valueFunction) : response;

export const adjustDecimalOrFractionTextGreaterThan1 = (input: string, skipIsNumberTest?: boolean): string =>
    replaceNumberValue(input, /^\d+[,./\\]$/g, (value: string) => value.substring(0, value.length - 1), skipIsNumberTest);

export const adjustDecimalTextLessThan1 = (input: string, skipIsNumberTest?: boolean): string => {
    const response = replaceNumberValue(input, /^[,.]/g, (value: string) => `0${value}`, skipIsNumberTest);
    return response;
}

export const formatGeneralText = (text: string, forEmails?: boolean): string => formatArrayToString(
    text.replaceAll(/\n{2,}/g, '\n').replaceAll(/^\n{1}/g, '').replaceAll(/\n{1}$/g, '').split('\n').map((line) => line.trim().replaceAll(/ {2,}/g, ' ')),
    true,
    false,
    forEmails
);

export const formatArrayToString = (elements: string[], withLineBreaks?: boolean, withoutElementsSeparations?: boolean, forEmails?: boolean): string => {
    const commaReplacement = '{/k0M4/}';
    return elements.map((element) => element.replaceAll(',', commaReplacement)).toString().replaceAll(',', withLineBreaks ? (forEmails ? '<br>' : '\n') : withoutElementsSeparations ? '' : ', ').replaceAll(commaReplacement, ',');
}

export const roundNumber = (value: number, decimalsToRound?: number): number => {
    const decimals = decimalsToRound !== undefined ? decimalsToRound : 4;
    const addZerosToString = (target: string, amountOfZeros?: number): string => {
        let responseWithZeros = target;
        for (let i = 0; i < (amountOfZeros === undefined ? decimals : amountOfZeros); i++) {
            responseWithZeros = responseWithZeros + '0';
        }
        return responseWithZeros;
    }
    const tempNum = parseFloat(addZerosToString('1'));
    const responseIntergersAndDecimals = (Math.round((value + Number.EPSILON) * tempNum) / tempNum).toString().split('.');
    let response = responseIntergersAndDecimals[0];
    if (decimals > 0) {
        response = response + '.';
        if (responseIntergersAndDecimals.length > 1) {
            if (responseIntergersAndDecimals[1].length < decimals) {
                response = response + responseIntergersAndDecimals[1].substring(0, responseIntergersAndDecimals[1].length);
                response = addZerosToString(response, decimals - responseIntergersAndDecimals[1].length);
            } else {
                response = response + responseIntergersAndDecimals[1].substring(0, decimals);
            }
        } else {
            response = addZerosToString(response);
        }
    }
    return (parseFloat(response));
}

export const stringToNumber = (value: string): number => parseFloat(value.replace(',', '.'));