import TipoMotivo from "../../models/desviaciones/TipoMotivo";
import { SelectOption } from "../../models/optionsFilters/Select";

const useMotivoPersonal = (): SelectOption<TipoMotivo>[] => {
  return [
    { label: "SIN CAPACIDAD", value: { motivo: "SIN CAPACIDAD" } },
    { label: "PERMISO", value: { motivo: "PERMISO" } },
    { label: "VACACIONES", value: { motivo: "VACACIONES" } },
    { label: "LICENCIA", value: { motivo: "LICENCIA" } },
    { label: "CAPACITACIÓN FUERA", value: { motivo: "CAPACITACIÓN FUERA" } },
    {
      label: "AUSENCIA SIN JUSTIFICACIÓN",
      value: { motivo: "AUSENCIA SIN JUSTIFICACIÓN" },
    },
    {
      label: "COMISIÓN O VISITA TERRENO",
      value: { motivo: "COMISIÓN O VISITA TERRENO" },
    },
  ];
};

export default useMotivoPersonal;
