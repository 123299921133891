import { ReactElement, useCallback, useEffect, useState } from "react";
import {
  ServicioExternoTabla,
  ServiciosExternosFilters,
} from "../../models/serviciosExternos/ServicioExterno";
import {
  getServiciosExternos,
  putServicioExterno,
} from "../../api/ServiciosExternosApi";
import cogoToast from "cogo-toast";
import { Spinner } from "react-bootstrap";
import TableDesviacion from "../../components/desviaciones/TableDesviacion";
import { formatServicioExterno } from "../../utils/serviciosExternos";
import { getUser } from "../../api/UsersApi";
import { User } from "../../models/users/Users";

interface ServiciosExternosProps {
  serviciosExternosFilters: ServiciosExternosFilters;
  setServiciosExternosFilters: (
    serviciosExternosFilter: ServiciosExternosFilters
  ) => void;
  refresh: boolean;
}

const ServiciosExternosList = ({
  serviciosExternosFilters,
  setServiciosExternosFilters,
  refresh,
}: ServiciosExternosProps): ReactElement => {
  const [serviciosExternos, setServiciosExternos] = useState<
    ServicioExternoTabla[]
  >([]);
  const [totalPages, setTotalPages] = useState<number>();
  const [isLoading, setIsLoading] = useState<boolean>(true);
  const [users, setUsers] = useState<User[]>([]);

  const fetchServiciosExternos = useCallback(() => {
    setIsLoading(true);
    getServiciosExternos(serviciosExternosFilters)
      .then((response) => {
        if (response.data.serviciosExternos) {
          setServiciosExternos(response.data.serviciosExternos);
          setTotalPages(response.data.totalPages);
        } else {
          cogoToast.error("No fue posible obtener los servicios externos");
        }
      })
      .catch(() => {
        cogoToast.error("No fue posible obtener los servicios externos");
      })
      .finally(() => {
        setIsLoading(false);
      });
  }, [serviciosExternosFilters]);

  const submitForm = (id: string) => {
    putServicioExterno(formatServicioExterno(id))
      .then((response) => {
        if (response.data._id) {
          cogoToast.success("Servicio externo terminado");
          fetchServiciosExternos();
        } else {
          cogoToast.error("No fue posible terminar el servicio externo");
        }
      })
      .catch(() => {
        cogoToast.error("No fue posible terminar el servicio externo");
      });
  };

  const rutList = serviciosExternos.flatMap((servicio) => servicio.personal);

  useEffect(() => {
    fetchServiciosExternos();
  }, [serviciosExternosFilters, refresh]);

  useEffect(() => {
    if (serviciosExternos.length > 0) {
      const fetchUsers = async () => {
        const usersList: User[] = [];
        for (const rut of rutList) {
          try {
            const response = await getUser(rut);
            usersList.push(response.data);
          } catch (error) {
            cogoToast.error(`No fue posible obtener el usuario con rut ${rut}`);
          }
        }
        setUsers(usersList);
      };
      fetchUsers();
    }
  }, [serviciosExternos]);

  if (isLoading) {
    return (
      <div className="text-center">
        <Spinner animation="border" />
      </div>
    );
  }

  if (!serviciosExternos.length) {
    return (
      <p className="text-center">
        No fue posible cargar los servicios externos
      </p>
    );
  }

  return (
    <TableDesviacion
      stripped
      hover
      pages={totalPages}
      activePage={serviciosExternosFilters.page}
      onPageSelect={(page) =>
        setServiciosExternosFilters({ ...serviciosExternosFilters, page })
      }
    >
      <thead>
        <tr>
          <th>Personal</th>
          <th>Galpón servicio</th>
          <th>Motivo</th>
          <th>Fecha inicio</th>
          <th>Fecha término</th>
          <th>Estado</th>
          <th>Acciones</th>
        </tr>
      </thead>
      <tbody>
        {serviciosExternos.map((servicio, index) => (
          <tr key={index}>
            <td>
            {servicio.personal.map((rut, idx) => {
                const user = users.find((u) => u.rut === rut);
                return (
                  <span key={rut}>
                    {user ? `${user.nombre} ${user.apellidos}` : rut}
                    {idx < servicio.personal.length - 1 && ", "}
                  </span>
                );
              })}
            </td>
            <td>{servicio.galpon}</td>
            <td>{servicio.motivo}</td>
            <td>{servicio.fechaInicio}</td>
            <td>{servicio.fechaTermino}</td>
            <td>{servicio.estado}</td>
            <td>
              <button
                className="btn btn-primary"
                onClick={() => submitForm(servicio._id)}
                disabled={servicio.estado === "TERMINADO"}
              >
                Solucionar
              </button>
            </td>
          </tr>
        ))}
      </tbody>
    </TableDesviacion>
  );
};

export default ServiciosExternosList;
