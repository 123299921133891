import { ReactElement, useState } from "react";
import { IPlanificacionDetails } from "../../models/planificacionesERP/PlanificacionERP";
import useFormState from "../../hooks/useFormState";
import { Button, Card, Col, Modal, Row } from "react-bootstrap";
import ObservacionesOPFormComponent from "../estatusGeneral/ObservacionesOPFormComponent";
import { putPlanificacion } from "../../api/PlanificacionesERPApi";
import { formatPlanificacionObs } from "../../utils/planificaciones";
import cogoToast from "cogo-toast";
import { format } from "date-fns";

interface ObservacionesPlanificacionComponentProps {
  planificacion: IPlanificacionDetails;
  onPlanificacionChange: (planificacion: IPlanificacionDetails) => void;
  userPermission: boolean;
  id: string;
}

const ObservacionesPlanificacionComponent = ({
  planificacion,
  onPlanificacionChange,
  userPermission,
  id,
}: ObservacionesPlanificacionComponentProps): ReactElement => {
  const formState = useFormState({ observaciones: "" });

  const [show, setShow] = useState(false);
  const handleShow = () => setShow(true);
  const handleClose = () => {
    formState.reset();
    setShow(false);
  };

  const submitForm = (obs: { observaciones: string }) => {
    putPlanificacion(formatPlanificacionObs(id, obs.observaciones))
      .then((response) => {
        if (response.data._id) {
          onPlanificacionChange(response.data);
          cogoToast.success("Observación agregada");
          handleClose();
        } else {
          cogoToast.error("No fue posible agregar la observación");
        }
        formState.setSubmitting(false);
      })
      .catch(() => {
        cogoToast.error("No fue posible agregar la observación");
        formState.setSubmitting(false);
        formState.reset();
      });
  };

  return (
    <>
      <Card className="cards-container">
        <Card.Header className="cards-header">
          <div className="body-title">Observaciones</div>
          {id && userPermission && (
            <div className="buttons-group">
              <Button onClick={handleShow}>Nueva Observación</Button>
            </div>
          )}
        </Card.Header>
        <Card.Body>
          <Row>
            <Col>
              <ul>
                {planificacion.observaciones.length > 0 ? (
                  planificacion.observaciones.map((obs, index) => (
                    <li key={index}>
                      <strong>
                        {format(new Date(obs.fecha), "dd/MM/yyyy")}:
                      </strong>{" "}
                      {obs.observacion}
                    </li>
                  ))
                ) : (
                  <p>No hay observaciones.</p>
                )}
              </ul>
            </Col>
          </Row>
        </Card.Body>
      </Card>

      {show && (
        <Modal show={show} onHide={handleClose}>
          <Modal.Header>
            <Col className="text-center">
              <Modal.Title>Nueva Observación</Modal.Title>
            </Col>
          </Modal.Header>
          <Modal.Body>
            <ObservacionesOPFormComponent
              formState={formState}
              submitForm={submitForm}
              onCancel={handleClose}
            />
          </Modal.Body>
        </Modal>
      )}
    </>
  );
};

export default ObservacionesPlanificacionComponent;
