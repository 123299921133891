import TipoMotivo from "../../models/desviaciones/TipoMotivo";
import { SelectOption } from "../../models/optionsFilters/Select";

const useMotivoInfraestructura = (): SelectOption<TipoMotivo>[] => {
  return [
    {
      label: "FALTA SALA DE LAVADO",
      value: { motivo: "FALTA SALA DE LAVADO" },
    },
    {
      label: "FALTA SALA DE PINTURA",
      value: { motivo: "FALTA SALA DE PINTURA" },
    },
    {
      label: "FALTA ESPACIO MECÁNICO",
      value: { motivo: "FALTA ESPACIO MECÁNICO" },
    },
    {
      label: "FALTA ESPACIO ELÉCTRICO",
      value: { motivo: "FALTA ESPACIO ELÉCTRICO" },
    },
    { label: "MANTENIMIENTO", value: { motivo: "MANTENIMIENTO" } },
  ];
};

export default useMotivoInfraestructura;
