import { Dispatch, ReactElement, ReactText, SetStateAction } from "react";
import { Col, Row } from "react-bootstrap";
import { SelectOption } from "../../models/optionsFilters/Select";
import { Persona, UsersFilters } from "../../models/users/Users";
import { Input } from "../form/input/Input";
import Select from "../form/select/Select";
import SelectMultiple from "../form/select/SelectMultiple";

interface UsersFiltersComponentProps {
    usersFilters: UsersFilters,
    onFiltersChange: Dispatch<SetStateAction<UsersFilters>>,
    rolUsuariosOptions: SelectOption<string>[],
    disponibilidadOptions?: SelectOption<string>[],
    isEmailRequired?: boolean,
    estadoUsuariosOptions?: SelectOption<string>[],
    isEvaluacionDesarmeReparacionArmado?: boolean
    // isForSelection?: boolean
    existingPersonas?: Persona[]
}

const UsersFiltersComponent = ({ usersFilters, onFiltersChange, estadoUsuariosOptions, rolUsuariosOptions, existingPersonas, disponibilidadOptions, isEmailRequired, isEvaluacionDesarmeReparacionArmado }: UsersFiltersComponentProps): ReactElement => {

    const existingPersonasString = ((): string[] => {
        const response: string[] = [];
        if (isEmailRequired) {
            existingPersonas?.forEach((persona) => {
                if (persona.email) {
                    response.push(persona.email);
                }
            });
        } else {
            existingPersonas?.forEach((persona) => {
                if (persona.rut) {
                    response.push(persona.rut);
                }
            });
        }
        return response;
    })();

    const handleFilterChange = (name: string, value?: ReactText | ReactText[]): void => {
        onFiltersChange((prevFilters) => {
            let valueResponse = value;
            if (!valueResponse || (valueResponse as ReactText[]).length === 0) {
                if (name === 'roles') {
                    valueResponse = rolUsuariosOptions.map((rol) => rol.value);
                }
                else if (name === 'disponibilidad' && disponibilidadOptions) {
                    valueResponse = disponibilidadOptions.map((disp) => disp.value);
                }
            }
            let filters;
            filters = {
                ...prevFilters,
                [name]: valueResponse
            }
            if (isEmailRequired) {
                filters = { ...filters, existingUsersEmails: existingPersonasString };
            } else {
                filters = { ...filters, existingUsersRut: existingPersonasString };
            }
            return filters
        });
    };

    // const handleFilterChangeWithVariableName = (name: string): (value?: ReactText | ReactText[]) => void => {
    //     return ((value?: ReactText | ReactText[]) => {
    //         handleFilterChange(name, value);
    //     })
    // }

    return (
        <div className = "body-filters">
            <Row>
                <Col lg = {3}>
                    <Input
                        value = {usersFilters.rut}
                        name = "rut"
                        onChangeWithName = {handleFilterChange}
                        type = "text"
                        title = "RUT"
                    />
                </Col>
                <Col lg = {3}>
                    <Input
                        value = {usersFilters.nombre}
                        name = "nombre"
                        onChangeWithName = {handleFilterChange}
                        type = "text"
                        title = "Nombre"
                    />
                </Col>
                <Col lg = {3}>
                    <Input
                        value = {usersFilters.apellidos}
                        name = "apellidos"
                        onChangeWithName = {handleFilterChange}
                        type = "text"
                        title = "Apellidos"
                    />
                </Col>
                <Col lg = {3}>
                    <Input
                        value = {usersFilters.email}
                        name = "email"
                        onChangeWithName = {handleFilterChange}
                        type = "text"
                        title = "Email"
                    />
                </Col>
                <Col lg = {3}>
                    <SelectMultiple
                        title = "Rol"
                        options = {rolUsuariosOptions}
                        onFieldChange = {(value?: SelectOption<string>[]) => handleFilterChange('roles', value?.map((value) => value.value))}
                    />
                    {/* <Select
                        title = "Rol"
                        name = "rol"
                        options = {rolUsuariosOptions}
                        onSelectWithName = {(name, rol): void => {
                            handleFilterChange(name, rol.value.rol);
                        }}
                        onClearWithName = {(name): void => handleFilterChange(name, '')}
                    /> */}
                </Col>
                {disponibilidadOptions &&
                    <Col lg = {3}>
                        <SelectMultiple
                            title = "Disponibilidad"
                            options = {disponibilidadOptions}
                            onFieldChange = {(value?: SelectOption<string>[]) => handleFilterChange('disponibilidad', value?.map((value) => value.value))}
                        />
                    </Col>
                }
                {estadoUsuariosOptions && 
                    (<Col lg = {3}>
                        <Select
                            title = "Estado"
                            name = "estado"
                            options = {estadoUsuariosOptions}
                            onSelectWithName = {(name, estado): void => {
                                handleFilterChange(name, estado.value);
                            }}
                            onClearWithName = {(name): void => handleFilterChange(name, '')}
                        />
                    </Col>)
                }
            </Row>
        </div>
    );
}

export default UsersFiltersComponent;