import TipoMotivo from "../../models/desviaciones/TipoMotivo";
import { SelectOption } from "../../models/optionsFilters/Select";

const useMotivoRepuestos = (): SelectOption<TipoMotivo>[] => {
  return [
    { label: "SIN STOCK", value: { motivo: "SIN STOCK" } },
    { label: "EN MAL ESTADO", value: { motivo: "EN MAL ESTADO" } },
  ];
};

export default useMotivoRepuestos;
