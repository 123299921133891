import { ReactElement, useContext } from 'react';
import LayoutContext from '../../../contexts/LayoutContext';
import logotipo from '../../../assets/images/logotipo.jpg';
import HeaderBreadcrumb from './HeaderBreadcrumb';
import AuthContext from '../../../contexts/AuthContext';
import { useHistory } from 'react-router-dom';

const Header = (): ReactElement => {
    const { headerTitle, toggleMenuBar } = useContext(LayoutContext);
    const { userName, userRut, userRoles, logout } = useContext(AuthContext);
    const history = useHistory();

    const changeMenuBarState = (): void => toggleMenuBar();

    const userProfile = (): void => {
        toggleMenuBar(false)
        history.push(`/users/currentUser/${userRut}`);
    }

    const isCliente = (): boolean => {
        return userRoles.includes('CLIENTE');
    }

    return (
        <div>
            <div className = "header">
                <img className = "header-logo" src = {logotipo} alt = "Logo Electrans"/>
                <div className = "header-title">{headerTitle}</div>
                <div className = "header-user-card">
                    <div className = "header-text-user-card">
                        {userName}
                    </div>
                    {!isCliente() && (<button
                        className = "btn header-button-user-card"
                        type = "button"
                        onClick = {userProfile}
                    >
                        <i className="fa fa-user fa-lg"></i>
                    </button>)}
                    <button
                        className = "btn header-button-user-card"
                        type = "button"
                        onClick = {logout}
                    >
                        <i className="fa fa-sign-out fa-lg"></i>   
                    </button>
                </div>
            </div>
            <div className = "sub-header">
                <button
                    className="btn menu-button"
                    type="button"
                    onClick={changeMenuBarState}
                    disabled = {isCliente()}
                >
                    <i className="fa fa-bars fa-lg"></i>
                </button>
                <div className = "breadcrumb"><HeaderBreadcrumb /></div>
            </div>
        </div>
    )
}

export default Header;