import { ReactElement, useEffect, useState } from "react";
import { Badge, Button, Card, Col, Modal, Row } from "react-bootstrap";
import {
  IPlanificacionDetails,
  PlanificacionForm,
} from "../../models/planificacionesERP/PlanificacionERP";
import useFormState from "../../hooks/useFormState";
import {
  formatPlanificacion,
  formatPlanificacionEstado,
  formatPlanificacionForm,
} from "../../utils/planificaciones";
import useGalponPlanificacion from "../../hooks/planificaciones/useGalponPlanificacion";
import { useHistory } from "react-router-dom";
import {
  deletePlanificacion,
  putPlanificacion,
} from "../../api/PlanificacionesERPApi";
import cogoToast from "cogo-toast";
import PlanificacionFormComponent from "./PlanificacionFormComponent";
import useProcesoPlanificacion from "../../hooks/planificaciones/useProcesoPlanificacion";
import { Tab, TabList, TabPanel, Tabs } from "react-tabs";
import DesviacionPage from "../../pages/desviaciones/DesviacionPage";
import useMotivoReparacion from "../../hooks/planificaciones/useMotivoReparacion";
import ObservacionesPlanificacionComponent from "./ObservacionPlanificacionComponent";
import { getEvaluacion } from "../../api/EvaluacionesApi";
import { Evaluacion } from "../../models/Evaluaciones";
import { Reparacion } from "../../models/Reparaciones";
import { getReparacion } from "../../api/ReparacionesApi";
import { User } from "../../models/users/Users";
import { getUser } from "../../api/UsersApi";
import useMesPlanificacion from "../../hooks/planificaciones/useMesPlanificacion";

interface PlanificacionDetailsProps {
  planificacion: IPlanificacionDetails;
  onPlanificacionChange: (planificacion: IPlanificacionDetails) => void;
  id?: string;
  userPermission: boolean;
}

const PlanificacionDetails = ({
  planificacion,
  onPlanificacionChange,
  id,
  userPermission,
}: PlanificacionDetailsProps): ReactElement => {
  const galponPlanificacionOptions = useGalponPlanificacion();
  const procesoPlanificacionOptions = useProcesoPlanificacion();
  const motivoReparacionOptions = useMotivoReparacion();
  const mesPlanificacionOptions = useMesPlanificacion();
  const [evaluacion, setEvaluacion] = useState<Evaluacion>();
  const [reparacion, setReparacion] = useState<Reparacion>();
  const [participantsUsers, setParticipantsUsers] = useState<User[]>([]);

  const formStatePlanificacion = useFormState(
    formatPlanificacionForm(
      planificacion,
      galponPlanificacionOptions,
      procesoPlanificacionOptions,
      motivoReparacionOptions,
      mesPlanificacionOptions
    )
  );

  const [showEditPlanificacion, setShowEditPlanificacion] = useState(false);
  const handleShowEditPlanificacion = () => setShowEditPlanificacion(true);
  const handleCloseEditPlanificacion = () => {
    formStatePlanificacion.reset();
    setShowEditPlanificacion(false);
  };

  const history = useHistory();

  const submitForm = (planificacionForm: PlanificacionForm) => {
    putPlanificacion(formatPlanificacion(id!, planificacionForm))
      .then((response) => {
        if (response.data._id) {
          onPlanificacionChange(response.data);
          cogoToast.success("Planificación editado");
          setShowEditPlanificacion(false);
        } else {
          cogoToast.error("No fue posible editar la planificación");
        }
        formStatePlanificacion.setSubmitting(false);
      })
      .catch(() => {
        cogoToast.error("No fue posible editar la planificación");
        formStatePlanificacion.setSubmitting(false);
        formStatePlanificacion.reset();
      });
  };

  const handleChangeEstado = (estado: string) => {
    putPlanificacion(formatPlanificacionEstado(id!, estado))
      .then((response) => {
        if (response.data._id) {
          onPlanificacionChange(response.data);
          cogoToast.success("Estado cambiado");
          setShowEditPlanificacion(false);
        } else {
          cogoToast.error("No fue posible cambiar el estado");
        }
        formStatePlanificacion.setSubmitting(false);
      })
      .catch(() => {
        cogoToast.error("No fue posible cambiar el estado");
        formStatePlanificacion.setSubmitting(false);
        formStatePlanificacion.reset();
      });
  };

  const deleteCurrentPlanificacion = () => {
    formStatePlanificacion.setSubmitting(true);
    deletePlanificacion(planificacion._id)
      .then(() => {
        cogoToast.success("Planificación eliminada");
        history.push("/planificacionesERP");
      })
      .catch(() => {
        formStatePlanificacion.setSubmitting(false);
        cogoToast.error("No fue posible eliminar la planificación");
      });
  };

  const onHojaRuta = (codigoOP: string, proceso: string) => {
    if (proceso === "EVALUACIÓN" && evaluacion && evaluacion.planificacionId === id) {
      history.push(`/ordenesProceso/${codigoOP}/evaluacion`);
    } else if (proceso === "REPARACIÓN" && reparacion && reparacion.planificacionId === id) {
      history.push(`/ordenesProceso/${codigoOP}/reparacion`);
    }
  };

  useEffect(() => {
    if (planificacion.proceso === "EVALUACIÓN") {
      getEvaluacion(planificacion.codigoOP)
        .then((response) => {
          if (response.data) {
            setEvaluacion(response.data);
          } else {
            cogoToast.error(
              "No fue posible obtener la evaluación del componente"
            );
          }
        })
        .catch(() => {
          cogoToast.error(
            "No fue posible obtener la evaluación del componente"
          );
        });
    } else if (planificacion.proceso === "REPARACIÓN") {
      getReparacion(planificacion.codigoOP)
        .then((response) => {
          if (response.data) {
            setReparacion(response.data);
          } else {
            cogoToast.error(
              "No fue posible obtener la reparación del componente"
            );
          }
        })
        .catch(() => {
          cogoToast.error(
            "No fue posible obtener la reparación del componente"
          );
        });
    }
  }, [id]);

  useEffect(() => {
    const fetchParticipants = async () => {
      let rutArray: string[] = [];
      if (
        planificacion.proceso === "EVALUACIÓN" &&
        evaluacion?.participantesRut &&
        evaluacion.participantesRut.length > 0
      ) {
        rutArray = evaluacion.participantesRut;
      } else if (
        planificacion.proceso === "REPARACIÓN" &&
        reparacion?.participantesRut &&
        reparacion.participantesRut.length > 0
      ) {
        rutArray = reparacion.participantesRut;
      }
      if (rutArray.length > 0) {
        const participantsList: User[] = [];
        for (const rut of rutArray) {
          try {
            const response = await getUser(rut);
            participantsList.push(response.data);
          } catch (error) {
            cogoToast.error(`No fue posible obtener el usuario con rut ${rut}`);
          }
        }
        setParticipantsUsers(participantsList);
      }
    };
    fetchParticipants();
  }, [planificacion.proceso, evaluacion, reparacion]);

  return (
    <Tabs>
      <TabList>
        <Tab>
          <h5>PLANIFICACIÓN</h5>
        </Tab>
        <Tab>
          <h5>DESVIACIONES</h5>
        </Tab>
      </TabList>

      <TabPanel>
        <Card className="cards-container">
          <Card.Header className="cards-header">
            <div className="body-title">
              {planificacion.codigoOP} / {planificacion.estado}
            </div>
            {id && userPermission && (
              <div className="buttons-group">
                <Button onClick={handleShowEditPlanificacion}>Editar</Button>
                {planificacion.proceso === "EVALUACIÓN" && evaluacion && (  
                  <Button
                    onClick={() =>
                      onHojaRuta(planificacion.codigoOP, planificacion.proceso)
                    }
                  >
                    Hoja de ruta
                  </Button>
                )}
                {planificacion.proceso === "REPARACIÓN" && reparacion && (  
                  <Button
                    onClick={() =>
                      onHojaRuta(planificacion.codigoOP, planificacion.proceso)
                    }
                  >
                    Hoja de ruta
                  </Button>
                )}
              </div>
            )}
          </Card.Header>
          <Card.Body>
            <Row>
              <Col>
                Prioridad
                <h3>{planificacion.prioridad}</h3>
              </Col>
              <Col>
                Galpón
                <h3>{planificacion.galpon}</h3>
              </Col>
              <Col>
                Proceso
                <h3>{planificacion.proceso}</h3>
              </Col>
              <Col>
                Cliente
                <h3>{planificacion.cliente}</h3>
              </Col>
              <Col>
                Tipo
                <h3>{planificacion.componente.tipo}</h3>
              </Col>
              <Col>
                Modelo componente
                <h3>{planificacion.componente.modelo}</h3>
              </Col>
            </Row>
            {planificacion.proceso === "REPARACIÓN" && (
              <Row>
                <>
                  <Col>
                    Motivo reparación
                    <h3>{planificacion.motivoReparacion}</h3>
                  </Col>
                  <Col>
                    Orden compra
                    <h3>{planificacion.ordenCompra}</h3>
                  </Col>
                  <Col>
                    Valor reparación
                    <h3>{planificacion.valorReparacion}</h3>
                  </Col>
                </>
              </Row>
            )}
            <Row>
              <Col>
                Meta 
                <h3>{planificacion.meta.isMeta ? <Badge bg="success">SI</Badge> : "NO"}</h3>
              </Col>
              <Col>
                % Avance
                <h3>{planificacion.porcentaje} %</h3>
              </Col>
              <Col>
                Fecha inicio ideal
                <h3>{planificacion.fechaInicio}</h3>
              </Col>
              <Col>
                Fecha término ideal
                <h3>{planificacion.fechaTermino}</h3>
              </Col>
              <Col>
                Fecha inicio real
                <h3>{planificacion.fechaInicioReal}</h3>
              </Col>
              <Col>
                Fecha término real
                <h3>{planificacion.fechaTerminoReal}</h3>
              </Col>
            </Row>
            <Row>
              <Col>
                Participantes
                {participantsUsers.length > 0 ? (
                  <h3>
                    {participantsUsers.map((user) => `${user.nombre} ${user.apellidos}`).join(", ")}
                  </h3>
                ) : (
                  <h3>Sin participantes asignados</h3>
                )}
              </Col>
            </Row>
          </Card.Body>
        </Card>

        <ObservacionesPlanificacionComponent
          planificacion={planificacion}
          onPlanificacionChange={onPlanificacionChange}
          userPermission={userPermission}
          id={id!}
        />

        {id && (
          <>
            <Modal
              show={showEditPlanificacion}
              onHide={handleCloseEditPlanificacion}
            >
              <Modal.Header>
                <Col className="text-center">
                  <Modal.Title>Editar Planificación</Modal.Title>
                </Col>
              </Modal.Header>
              <Modal.Body>
                {showEditPlanificacion && (
                  <>
                    <PlanificacionFormComponent
                      formState={formStatePlanificacion}
                      submitForm={submitForm}
                      onDelete={deleteCurrentPlanificacion}
                      onCancel={handleCloseEditPlanificacion}
                      procesoPlanificacionOptions={procesoPlanificacionOptions}
                      galponPlanificacionOptions={galponPlanificacionOptions}
                      motivoReparacionOptions={motivoReparacionOptions}
                      mesPlanificacionOptions={mesPlanificacionOptions}
                    />

                    <div className="modal-buttons">
                      <Button
                        onClick={() => handleChangeEstado("DETENIDO")}
                        variant="danger"
                      >
                        Detener
                      </Button>
                      <Button
                        onClick={() => handleChangeEstado("TERMINADO")}
                        variant="success"
                      >
                        Finalizar
                      </Button>
                      <Button onClick={() => handleChangeEstado("EN PROCESO")}>
                        Reanudar
                      </Button>
                    </div>
                  </>
                )}
              </Modal.Body>
            </Modal>
          </>
        )}
      </TabPanel>

      <TabPanel>
        <DesviacionPage />
      </TabPanel>
    </Tabs>
  );
};

export default PlanificacionDetails;
