import cogoToast from "cogo-toast";
import DetallesEvaluacionReparacionApi from "../../api/EvaluacionesReparacionesApi";
import { Archivo, ProcesoPhotoRequest } from "../../models/Archivos";
import { Attachment } from "../../models/Email";
import { OrdenProceso } from "../../models/estatusGeneral/OrdenProceso";
import { EvaluacionOrReparacionComponenteEtapa, EvaluacionOrReparacionComponenteEtapaInforme, EvaluacionOrReparacionComponenteResumenEtapa, EvaluacionOrReparacionElements, ModeloComponenteEvaluacionOrReparacionElements } from "../../models/EvaluacionesReparaciones";
import blowersElementsList from "./elementsLists/blowersElementsList";
import motoresTraccionElementsList from "./elementsLists/motoresTraccionElementsList";
import { getDocumentoEvaluacionResumen } from "../../api/DocumentosApi";
import { Persona } from "../../models/users/Users";

export const getComponenteEvaluacionOrReparacionElement = (
    tipoComponente: string, modeloComponente: string, subModeloComponente?: string
): EvaluacionOrReparacionElements | void => ((list?: ModeloComponenteEvaluacionOrReparacionElements[]): EvaluacionOrReparacionElements | void => {
    if (list) {
        for (let i = 0; i < list.length; i++) {
            if (modeloComponente === list[i].modeloComponente) {
                const subModelosComponenteElements = list[i].subModelosComponenteElements;
                let response = list[i] as EvaluacionOrReparacionElements;
                if (subModelosComponenteElements && subModelosComponenteElements.length > 0 && subModeloComponente) {
                    for (let e = 0; e < subModelosComponenteElements.length; e++) {
                        if (subModelosComponenteElements[e].subModelosComponente.includes(subModeloComponente)) {
                            response = subModelosComponenteElements[e] as EvaluacionOrReparacionElements;
                            break;
                        }
                    }
                }
                return response;
            }
        }
    }
})(((): ModeloComponenteEvaluacionOrReparacionElements[] | undefined => {
    if (tipoComponente === 'BLOWER') {
        return blowersElementsList;
    } else if (tipoComponente === 'MOTOR DE TRACCION') {
        return motoresTraccionElementsList;
    }
})())

export const titleWithoutIndex = (title: string): string => title.replaceAll(/^(\d+\.)+ /g, '')

export const checkFinalEtapa = (title: string): boolean => ['Informe final', 'Informe de reparación', 'Informe de evaluación'].includes(titleWithoutIndex(title));

export const generarInformeEvaluacionReparacionComponente = (
    ordenProceso: OrdenProceso, finalEtapa: EvaluacionOrReparacionComponenteEtapa, etapas: EvaluacionOrReparacionComponenteEtapaInforme[], fotos?: ProcesoPhotoRequest[], isPreview?: boolean, isReparacion?: boolean, anexo?: Archivo
): void | Attachment => {
    if (ordenProceso.componente) {
        const componenteElement = getComponenteEvaluacionOrReparacionElement(ordenProceso.componente.tipoComponente, ordenProceso.componente.modelo, ordenProceso.recepcion?.subModeloComponente);
        if (componenteElement?.getInformeReparacion || componenteElement?.getInformeEvaluacion) {
            const formatedFinalEtapa = { ...finalEtapa, tareas: finalEtapa.tareas.map((tarea) => ({ ...tarea, nombre: titleWithoutIndex(tarea.nombre) }))};
            const formatedEtapas = etapas.map((etapa) => ({ ...etapa, nombre: titleWithoutIndex(etapa.nombre), tareas: etapa.tareas.map((tarea) => ({ ...tarea, nombre: titleWithoutIndex(tarea.nombre) })) }));
            const formatedFotos = fotos?.map((foto) => ({ ...foto, nombreEtapa: titleWithoutIndex(foto.nombreEtapa), nombreTarea: titleWithoutIndex(foto.nombreTarea) }));
            if (isReparacion) {
                if (componenteElement.getInformeReparacion) {
                    componenteElement.getInformeReparacion(
                        ordenProceso,
                        formatedFinalEtapa,
                        formatedEtapas,
                        formatedFotos,
                        isPreview,
                        anexo
                    );
                }
            } else {
                if (componenteElement.getInformeEvaluacion) {
                    componenteElement.getInformeEvaluacion(
                        ordenProceso,
                        formatedFinalEtapa,
                        formatedEtapas,
                        formatedFotos,
                        isPreview,
                        anexo
                    );
                }
            }
        }
    }
}

export const getRequestsAndGenerateInforme = async (ordenProceso: OrdenProceso, detallesApi: DetallesEvaluacionReparacionApi, changeStates?: (state: boolean) => void, photosState?: (state: boolean) => void) => {
    const isReparacion = detallesApi.getEtapaProceso() === 'reparacion';
    const evaluacionReparacionText = isReparacion ? 'reparación' : 'evaluación';
    if (changeStates) {
        changeStates(true);
    }
    await detallesApi.getEvaluacionOrReparacionComponenteEtapa(`Informe de ${evaluacionReparacionText}`)
        .then(async (etapaResponse) => {
            if (etapaResponse.data) {
                await detallesApi.getEvaluacionOrReparacionComponenteEtapas()
                    .then(async (etapasResponse) => {
                        if (etapasResponse.data && etapasResponse.data.length > 0) {
                            const photos: ProcesoPhotoRequest[] = [];
                            let documentoEvaluacionResumen: { nombre: string, base64: string } | undefined = undefined;
                            let photosError = false;
                            etapasResponse.data.forEach((etapa) => etapa.tareas.forEach((tarea) => tarea.photos?.forEach((photo) => photos.push({
                                nombreVariable: photo.title,
                                nombreEtapa: etapa.nombre,
                                nombreTarea: tarea.nombre
                            }))));
                            if (photosState) {
                                photosState(true);
                            }
                            for (let i = 0; i < photos.length; i++) {
                                await detallesApi.getImagenProceso(photos[i])
                                    .then((photoResponse) => {
                                        if (photoResponse.data) {
                                            photos[i].imagen = photoResponse.data;
                                        }
                                    })
                                    .catch(() => {
                                        // console.log(error)
                                        if (!photosError) {
                                            photosError = true;
                                        }
                                    })
                            }
                            if (photosError) {
                                cogoToast.error('No fue posible obtener todas las imágenes');
                            }
                            if (photosState) {
                                photosState(false);
                            }
                            if (!isReparacion) {
                                await getDocumentoEvaluacionResumen(ordenProceso.codigoOP)
                                    .then((response) => {
                                        if (response.data?.base64) {
                                            documentoEvaluacionResumen = { nombre: response.data.nombre, base64: response.data.base64 };
                                        }
                                    }).catch(() => cogoToast.error('No fue posible obtener el documento resumen'));
                            }
                            generarInformeEvaluacionReparacionComponente(ordenProceso, etapaResponse.data, etapasResponse.data, photos, false, isReparacion, documentoEvaluacionResumen);
                            cogoToast.success('Informe generado');
                        } else {
                            cogoToast.error('No fue posible generar el informe');
                        }
                    })
                    .catch(() => {
                        // console.log(error)
                        cogoToast.error('No fue posible generar el informe');
                    })
            } else {
                cogoToast.error(`No fue posible obtener el detalle de la ${evaluacionReparacionText}`);
            }
            if (changeStates) {
                changeStates(false);
            }
        })
        .catch(() => {
            cogoToast.error(`No fue posible obtener el detalle de la ${evaluacionReparacionText}`);
            if (changeStates) {
                changeStates(false);
            }
        })
}

export const getParticipantesEmailList = (participantesGenerales?: Persona[], participantes?: Persona[]): string[] => {
    const acceptedUsers: string[] = participantesGenerales?.map((participanteGeneral) => participanteGeneral.rut || '') || [];
    participantes?.forEach((participante) => {
        if (!acceptedUsers.includes(participante.rut || '')) {
            acceptedUsers.push(participante.rut || '');
        }
    });
    return acceptedUsers;
}

export const canGenerateInforme = (ordenProceso: OrdenProceso, evaulacionReparacionComponenteResumenEtapas?: EvaluacionOrReparacionComponenteResumenEtapa[], isReparacion?: boolean): boolean => (
    !!(isReparacion ? ordenProceso.armadoReparacionComponente : ordenProceso.desarmeEvaluacionComponente) &&
    !!ordenProceso.componente?.tipoComponente &&
    !!evaulacionReparacionComponenteResumenEtapas?.some((resumenEtapa) => checkFinalEtapa(resumenEtapa.nombreEtapa) && resumenEtapa.tareas.some((resumenTarea) => isAprobacionTarea(resumenTarea.nombreTarea)))
)

export const isAprobacionTarea = (tarea: string): boolean => titleWithoutIndex(tarea) === 'Aprobación jefe de taller'