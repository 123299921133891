import axios, { AxiosPromise } from "axios";
import {
  AreaGalponRequestBody,
  GalponDetails,
  GalponRequestBody,
  PuestoTrabajoGalponRequestBody,
  UpdateEstadoPuestoTrabajoRequestBody,
} from "../models/galpones/Galpon";

export function getGalpones(): AxiosPromise<GalponDetails[]> {
  return axios.get("api/galpones");
}

export function postGalpon(
  requestBody: GalponRequestBody
): AxiosPromise<GalponDetails> {
  return axios.post("api/galpones", requestBody);
}

export function getGalpon(requestBody: string): AxiosPromise<GalponDetails> {
  return axios.get(`api/galpones/${requestBody}`);
}

export function deleteGalpon(requestBody: string): AxiosPromise<GalponDetails> {
  return axios.delete(`api/galpones/${requestBody}`);
}

export function postAreaGalpon(
  requestBody: AreaGalponRequestBody
): AxiosPromise<GalponDetails> {
  return axios.post(`api/galpones/${requestBody._id}/areas`, requestBody);
}

export function postPuestoTrabajoGalpon(
  requestBody: PuestoTrabajoGalponRequestBody
): AxiosPromise<GalponDetails> {
  return axios.post(
    `api/galpones/${requestBody._id}/areas/${requestBody.areaIndex}/puestos`,
    requestBody
  );
}

export function updateEstadoPuestoTrabajo(
  requestBody: UpdateEstadoPuestoTrabajoRequestBody
): AxiosPromise<GalponDetails> {
  return axios.put(
    `api/galpones/${requestBody._id}/areas/${requestBody.areaIndex}/puestos/${requestBody.puestoIndex}/actualizar`,
    requestBody
  );
}
