import TipoMotivo from "../../models/desviaciones/TipoMotivo";
import { SelectOption } from "../../models/optionsFilters/Select";

const useMotivoHerramientas = (): SelectOption<TipoMotivo>[] => {
  return [
    { label: "MANTENIMIENTO", value: { motivo: "MANTENIMIENTO" } },
    { label: "PRESTADO", value: { motivo: "PRESTADO" } },
    { label: "BAJA", value: { motivo: "BAJA" } },
  ];
};

export default useMotivoHerramientas;
