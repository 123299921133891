import { CalculationsTableDetails, SharedValue } from "../../../models/EvaluacionesReparaciones";
import { checkNumber, roundNumber, stringToNumber } from "../../inputs";
import TablesGeneralCalculations from "./evaluacionesReparacionesGeneralTableCalculations";

const desarmeInicialStageName = 'Desarme inicial';
const metrologiaStageName = 'Metrología';
const armadoTuboTorqueStageName = 'Armado de tubo torque';
const medirJuegoBacklashPlanetarioTaskName = 'Medir juego backlash planetarios';
const medicionBacklashTaskName = 'Medición de backlash';
const metrologiaTuboTorqueAnilloFlingerTaskName = 'Metrología tubo de torque y anillo flinger';
const interferenciasTuboTorqueAnilloFlingerTaskName = 'Interferencias tubo de torque y anillo flinger';
const metrologiaTuboTorqueTableName = 'Metrología tubo de torque';
const metrologiaAnilloFlingerTableName = 'Metrología anillo flinger';
const backlashTableName = 'Backlash';
const instalacionTuboTorqueStageName = 'Instalación tubo de torque';
const registroABCTaskName = 'Registro medida A, B y C';
const registroABCVariableName = 'Registro A, B y C';
const instalacionTapaPlanetarios = 'Instalación tapa de planetarios';

export const motorTraccion5GEB25TableCalculations = (tableDetails: CalculationsTableDetails) => {
    const generalsCalculations = new TablesGeneralCalculations(tableDetails);
    const pruebaEstanqueidadFrenos = () => {
        let resultado = 'Malo';
        if (tableDetails.table.rows[tableDetails.tableRowIndex][3].content[0] === 'SÍ') {
            resultado = 'Bueno';
        }
        tableDetails.updateTable(4, resultado);
    }
    const cantidadLainasTable = () => {
        let totalLengthSum = 0;
        const extractFirstNumberFromStringInColumnTitle = (columnIndex: number): number | void => {
            const columnTitle = tableDetails.table.columnsSettings[columnIndex].columnTitle;
            const numberBeginsIndex = ((): number => {
                let numberBeginsIndexResponse = -1;
                for (let i = 0; i < columnTitle.length; i++) {
                    const nextIndex = i + 1;
                    if (checkNumber(columnTitle.charAt(i)).isNumber || ((/[,.]/).test(columnTitle.charAt(i)) && nextIndex < columnTitle.length && checkNumber(columnTitle.charAt(nextIndex)).isNumber)) {
                        numberBeginsIndexResponse = i;
                        break;
                    }
                }
                return numberBeginsIndexResponse;
            })();
            if (numberBeginsIndex > -1) {
                return (stringToNumber(columnTitle.substring(numberBeginsIndex)));
            }
        };
        const addLength = (column: number) => {
            const lengthType = extractFirstNumberFromStringInColumnTitle(column);
            const inputNumber = generalsCalculations.cellStringToNumber(column);
            if (lengthType && !isNaN(inputNumber)) {
                totalLengthSum = totalLengthSum + (lengthType * inputNumber);
            }
        };
        addLength(1);
        addLength(2);
        addLength(3);
        tableDetails.updateTable(4, roundNumber(totalLengthSum).toString());
        generalsCalculations.standartMeasurements([4], true, { minName: 'Cantidad mínima referencial (" pulgadas)', maxName: 'Cantidad máxima referencial (" pulgadas)' });
    };
    if (!tableDetails.isReparacion) {
        const generalsCalculations = new TablesGeneralCalculations(tableDetails);
        if (tableDetails.stage === desarmeInicialStageName) {
            if (tableDetails.taskName === 'Medir juego axial de cubo de rueda') {
                if (tableDetails.variableName === 'Cantidad de lainas') {
                    cantidadLainasTable();
                } else if (generalsCalculations.checkVariableTaskNameOrStageNameInNamesArray(['Mediciones axial cubo de rueda'])) {
                    generalsCalculations.standartMeasurements([1]);
                }
            } else if (generalsCalculations.checkVariableTaskNameOrStageNameInNamesArray(['Medir juego axial de planetarios', medirJuegoBacklashPlanetarioTaskName], true)) {
                if (tableDetails.variableName !== backlashTableName) {
                    generalsCalculations.standartMeasurements([1]);
                } else {
                    tableDetails.updateSharedValues({
                        stageName: desarmeInicialStageName,
                        taskName: medirJuegoBacklashPlanetarioTaskName,
                        tableName: tableDetails.variableName,
                        columnName: 'Valor (" pulgadas)',
                        rowIndex: tableDetails.tableRowIndex,
                        value: tableDetails.table.rows[tableDetails.tableRowIndex][1].content[0]
                    });
                }
            }
        } else if (tableDetails.stage === 'Prueba líneas de freno') {
            if (tableDetails.taskName === 'Prueba de estanqueidad a cañerías de frenos') {
                pruebaEstanqueidadFrenos();
            }
        } else if (tableDetails.stage === metrologiaStageName) {
            if (tableDetails.taskName === 'Medición cubo de rueda') {
                if (generalsCalculations.checkVariableTaskNameOrStageNameInNamesArray(['Metrología cubo de rueda', 'Ángulo del cono 28°'])) {
                    generalsCalculations.promedioCalc([1, 2, 3, 4, 5, 6]);
                    generalsCalculations.standartMeasurements([7]);
                } else {
                    generalsCalculations.standartMeasurements([2]);
                }
            } else if (tableDetails.taskName === metrologiaTuboTorqueAnilloFlingerTaskName) {
                if (generalsCalculations.checkVariableTaskNameOrStageNameInNamesArray([metrologiaTuboTorqueTableName, metrologiaAnilloFlingerTableName])) {
                    tableDetails.updateSharedValues({
                        stageName: metrologiaStageName,
                        taskName: metrologiaTuboTorqueAnilloFlingerTaskName,
                        tableName: tableDetails.variableName,
                        columnName: 'Promedio',
                        rowIndex: tableDetails.tableRowIndex,
                        value: generalsCalculations.promedioCalc([1, 2, 3, 4, 5, 6])
                    });
                    generalsCalculations.standartMeasurements([7]);
                } else if (tableDetails.variableName === 'Interferencia') {
                    generalsCalculations.standartMeasurements([1]);
                } else if (tableDetails.variableName === 'Control desgaste corona tubo de torque Diámetro PIN: 0,772”') {
                    generalsCalculations.promedioCalc([1, 2, 3, 4]);
                    generalsCalculations.standartMeasurements([5]);
                } else if (tableDetails.variableName === 'Medidas anillo flinger') {
                    generalsCalculations.standartMeasurements([1]);
                }
            } else if (tableDetails.taskName === 'Metrología a piñón solar') {
                if (generalsCalculations.checkVariableTaskNameOrStageNameInNamesArray(['Desgaste de spline PIN: 0.240"', 'Desgaste de engranaje PIN: 0.576"'])) {
                    generalsCalculations.promedioCalc([1, 2, 3, 4]);
                    generalsCalculations.standartMeasurements([5]);
                }
            } else if (generalsCalculations.checkVariableTaskNameOrStageNameInNamesArray(['Metrología planetarios A', 'Metrología planetarios B', 'Metrología planetarios C'], true)) {
                if (generalsCalculations.checkVariableTaskNameOrStageNameInNamesArray([
                    'Metrología eje pista rodamiento recto',
                    'Metrología piñón planetario - PIN 0,864”',
                    'Metrología corona planetario - PIN 0,800”',
                    'Metrología eje pista rodamiento cónico pista A superior',
                    'Metrología eje pista rodamiento cónico pista B inferior'
                ])) {
                    generalsCalculations.promedioCalc([1, 2, 3, 4]);
                    generalsCalculations.standartMeasurements([5]);
                }
            } else if (generalsCalculations.checkVariableTaskNameOrStageNameInNamesArray(['Metrología de frame', 'Metrología alojamientos rod. rectos frame', 'Metrología alojamientos rod. cónicos frame'], true)) {
                if (generalsCalculations.checkVariableTaskNameOrStageNameInNamesArray([
                    'Metrología pista rodamiento inferior N° Rod: 8864953P119',
                    'Metrología pista rodamiento superior N° Rod: 8864953P120',
                    'Metrología pista rodamiento recto A N° Rod: 8864951P206',
                    'Metrología pista rodamiento recto B N° Rod: 8864951P206',
                    'Metrología pista rodamiento recto C N° Rod: 8864951P206',
                    'Metrología pista rodamiento cónico A N° Rod: 8864961P12',
                    'Metrología pista rodamiento cónico B N° Rod: 8864961P12',
                    'Metrología pista rodamiento cónico C N° Rod: 8864961P12'
                ])) {
                    generalsCalculations.promedioCalc([1, 2, 3, 4]);
                    generalsCalculations.standartMeasurements([5]);
                }
            } else if (tableDetails.taskName === 'Medición holgura entre anillo flinger y porta sello') {
                if (generalsCalculations.checkVariableTaskNameOrStageNameInNamesArray(['Holgura entre anillo flinger y porta sello', 'Medidas anillo porta sello'])) {
                    generalsCalculations.standartMeasurements([1]);
                }
            }
        }
    } else {
        const pulgadaMM3MedPromTable = () => {
            generalsCalculations.promedioCalc([1, 2, 3]);
            generalsCalculations.standartMeasurements([4], true, undefined, tableDetails.table.rows[tableDetails.tableRowIndex][5].content[0] !== 'mm' ? undefined : (value: number): number => (value / 25.4));
        };
        if (tableDetails.stage === 'Pre armado general') {
            if (generalsCalculations.checkVariableTaskNameOrStageNameInNamesArray(
                ['Metrología alojamiento rodamientos cónicos', 'Metrología alojamiento rodamientos rectos', 'Metrología pista superior de frame', 'Metrología pista inferior de frame'], true
            )) {
                if (generalsCalculations.checkVariableTaskNameOrStageNameInNamesArray([
                    'Metrología pista rodamiento recto A N° Rod: 8864951P206',
                    'Metrología pista rodamiento recto B N° Rod: 8864951P206',
                    'Metrología pista rodamiento recto C N° Rod: 8864951P206',
                    'Metrología pista rodamiento cónico A N° Rod: 8864961P12',
                    'Metrología pista rodamiento cónico B N° Rod: 8864961P12',
                    'Metrología pista rodamiento cónico C N° Rod: 8864961P12',
                    '1'
                ])) {
                    generalsCalculations.promedioCalc([1, 2, 3]);
                    generalsCalculations.standartMeasurements([4]);
                }
            } else if (tableDetails.taskName === 'Prueba de estanqueidad a 2500 PSI') {
                pruebaEstanqueidadFrenos();
            }
        } else if (tableDetails.stage === 'Acondicionamiento cubo de rueda') {
            if (generalsCalculations.checkVariableTaskNameOrStageNameInNamesArray(
                ['Metrología de bridas D1-D2-D3', 'Ángulo del cono 28°', 'Metrología de alojamiento pista superior', 'Metrología de alojamiento pista inferior'], true
            )) {
                if (tableDetails.variableName === '1') {
                    generalsCalculations.promedioCalc([1, 2, 3, 4]);
                    generalsCalculations.standartMeasurements([5]);
                }
            } else if (generalsCalculations.checkVariableTaskNameOrStageNameInNamesArray(['Metrología de pista rodamiento superior', 'Metrología de pista rodamiento inferior'], true)) {
                if (tableDetails.taskName === '1') {
                    generalsCalculations.standartMeasurements([2]);
                }
            }
        } else if (tableDetails.stage === armadoTuboTorqueStageName) {
            if (tableDetails.taskName === interferenciasTuboTorqueAnilloFlingerTaskName) {
                if (generalsCalculations.checkVariableTaskNameOrStageNameInNamesArray([metrologiaAnilloFlingerTableName, metrologiaTuboTorqueTableName])) {
                    tableDetails.updateSharedValues({
                        stageName: armadoTuboTorqueStageName,
                        taskName: interferenciasTuboTorqueAnilloFlingerTaskName,
                        tableName: tableDetails.variableName,
                        columnName: 'Promedio',
                        rowIndex: tableDetails.tableRowIndex,
                        value: generalsCalculations.promedioCalc([1, 2, 3, 4])
                    });
                    generalsCalculations.standartMeasurements([5]);
                } else if (tableDetails.variableName === 'Interferencia') {
                    generalsCalculations.standartMeasurements([1]);
                }
            } else if (tableDetails.taskName === 'Metrología flinger y felpa en tabla de medición') {
                if (tableDetails.variableName === 'Metrología anillo flinger') {
                    pulgadaMM3MedPromTable();
                }
            } else if (tableDetails.taskName === 'Metrología banda de desgaste en tabla medición') {
                if (tableDetails.variableName === 'Banda de desgaste sin instalar') {
                    pulgadaMM3MedPromTable();
                } else if (generalsCalculations.checkVariableTaskNameOrStageNameInNamesArray(['Roce banda de desgaste sin instalar con sello', 'Temperatura banda de desgaste sin instalar'])) {
                    generalsCalculations.promedioCalc([1, 2, 3]);
                    if (tableDetails.variableName === 'Roce banda de desgaste sin instalar con sello') {
                        generalsCalculations.standartMeasurements([4]);
                    }
                }
            }
        } else if (tableDetails.stage === 'Pre armado de planetario') {
            if (generalsCalculations.checkVariableTaskNameOrStageNameInNamesArray(['Metrología eje sector rodamientos cónicos', 'Metrología eje sector rodamientos rectos'], true)) {
                if (generalsCalculations.checkVariableTaskNameOrStageNameInNamesArray([
                    'Metrología eje pista rodamiento cónico pista A superior planetario A',
                    'Metrología eje pista rodamiento cónico pista B inferior planetario A',
                    'Metrología eje pista rodamiento cónico pista C inferior planetario A',
                    'Metrología eje pista rodamiento cónico pista A superior planetario B',
                    'Metrología eje pista rodamiento cónico pista B inferior planetario B',
                    'Metrología eje pista rodamiento cónico pista C inferior planetario B',
                    'Metrología eje pista rodamiento cónico pista A superior planetario C',
                    'Metrología eje pista rodamiento cónico pista B inferior planetario C',
                    'Metrología eje pista rodamiento cónico pista C inferior planetario C',
                    'Metrología eje pista rodamiento recto planetario A',
                    'Metrología eje pista rodamiento recto planetario B',
                    'Metrología eje pista rodamiento recto planetario C'
                ])) {
                    generalsCalculations.promedioCalc([1, 2, 3]);
                    generalsCalculations.standartMeasurements([4]);
                }
            } else if (generalsCalculations.checkVariableTaskNameOrStageNameInNamesArray(['Metrología dientes de corona', 'Metrología dientes de piñón'], true)) {
                if (generalsCalculations.checkVariableTaskNameOrStageNameInNamesArray([
                    'Metrología corona planetario A - PIN 0,800”',
                    'Metrología corona planetario B - PIN 0,800”',
                    'Metrología corona planetario C - PIN 0,800”',
                    'Metrología piñón planetario A - PIN 0,864”',
                    'Metrología piñón planetario B - PIN 0,864”',
                    'Metrología piñón planetario C - PIN 0,864”',
                ])) {
                    generalsCalculations.promedioCalc([1, 2, 3, 4]);
                    generalsCalculations.standartMeasurements([5]);
                }
            }
        } else if (tableDetails.stage === 'Anillo porta sello') {
            if (generalsCalculations.checkVariableTaskNameOrStageNameInNamesArray(['Metrología de anillo porta sello', 'Metrología de sello']), true) {
                if (generalsCalculations.checkVariableTaskNameOrStageNameInNamesArray(['Medidas anillo porta sello', 'Medidas sello'])) {
                    pulgadaMM3MedPromTable();
                } else if (tableDetails.variableName === 'Alojamiento sello') {
                    generalsCalculations.promedioCalc([1, 2, 3]);
                    generalsCalculations.standartMeasurements([4]);
                } else if (tableDetails.variableName === 'Temperatura anillo porta sello') {
                    generalsCalculations.promedioCalc([1, 2, 3]);
                }
            }
        } else if (tableDetails.stage === 'Instalación de planetarios') {
            if (tableDetails.taskName === 'Medir axial') {
                if (tableDetails.variableName === '1') {
                    generalsCalculations.standartMeasurements([1]);
                }
            }
        } else if (tableDetails.stage === instalacionTuboTorqueStageName) {
            if (tableDetails.taskName === 'Medir con filler entre porta sello y anillo flinger') {
                if (tableDetails.variableName === '1') {
                    generalsCalculations.standartMeasurements([1]);
                }
            } else if (tableDetails.taskName === registroABCTaskName) {
                if (tableDetails.variableName === registroABCVariableName) {
                    tableDetails.updateSharedValues({
                        stageName: instalacionTuboTorqueStageName,
                        taskName: registroABCTaskName,
                        tableName: registroABCVariableName,
                        columnName: 'Medición',
                        rowIndex: tableDetails.tableRowIndex,
                        value: tableDetails.table.rows[tableDetails.tableRowIndex][generalsCalculations.getPromedioOrColumnIndex('Medición')].content[0]
                    });
                } else if (tableDetails.variableName === 'Registro A-B') {
                    generalsCalculations.standartMeasurements([1], true, { minName: 'Medida C', maxName: 'Medida C' });
                }
            }
        } else if (tableDetails.stage === 'Medición de rodamiento cubo de rueda') {
            if (generalsCalculations.checkVariableTaskNameOrStageNameInNamesArray(['Realizar metrología a rodamiento superior', 'Realizar metrología a rodamiento inferior'], true)) {
                if (tableDetails.variableName === 'Medición') {
                    generalsCalculations.standartMeasurements([1], true);
                }
            }
        } else if (tableDetails.stage === 'Instalación de cubo de ruedas') {
            if (tableDetails.taskName === 'Medir axial cubo de rueda') {
                if (tableDetails.variableName === 'Cantidad de lainas') {
                    cantidadLainasTable();
                } else if (tableDetails.variableName === 'Mediciones axial cubo de rueda') {
                    generalsCalculations.standartMeasurements([1]);
                }
            } else if (generalsCalculations.checkVariableTaskNameOrStageNameInNamesArray(['Medir juego axial de planetarios', medirJuegoBacklashPlanetarioTaskName, medicionBacklashTaskName], true)) {
                if (tableDetails.variableName !== backlashTableName) {
                    generalsCalculations.standartMeasurements([1]);
                } else {
                    tableDetails.updateSharedValues({
                        stageName: tableDetails.isReparacion ? instalacionTapaPlanetarios : desarmeInicialStageName,
                        taskName: tableDetails.isReparacion ? medicionBacklashTaskName : medirJuegoBacklashPlanetarioTaskName,
                        tableName: tableDetails.variableName,
                        columnName: 'Valor (" pulgadas)',
                        rowIndex: tableDetails.tableRowIndex,
                        value: tableDetails.table.rows[tableDetails.tableRowIndex][1].content[0]
                    });
                }
            }
        } else if (tableDetails.stage === instalacionTapaPlanetarios) {
            if (tableDetails.taskName === medicionBacklashTaskName) {
                if (tableDetails.variableName === backlashTableName) {
                    tableDetails.updateSharedValues({
                        stageName: instalacionTapaPlanetarios,
                        taskName: medicionBacklashTaskName,
                        tableName: tableDetails.variableName,
                        columnName: 'Valor (" pulgadas)',
                        rowIndex: tableDetails.tableRowIndex,
                        value: tableDetails.table.rows[tableDetails.tableRowIndex][1].content[0]
                    });
                } else if (tableDetails.variableName === 'Diferencias de backlash') {
                    generalsCalculations.standartMeasurements([1]);
                }
            }
        }
    }
}

export const motorTraccion5GEB25InterferenciaCalculations = (sharedValues: SharedValue[], updateTable: (tableRowIndex: number, tableRowElementIndex: number, value?: string) => void) => {
    let anilloFlingerPromedio = '';
    let tuboTorquePromedio = '';
    sharedValues.forEach((sharedValue) => {
        if (
            sharedValue.value &&
            [metrologiaStageName, armadoTuboTorqueStageName].includes(sharedValue.stageName) &&
            [metrologiaTuboTorqueAnilloFlingerTaskName, interferenciasTuboTorqueAnilloFlingerTaskName].includes(sharedValue.taskName) &&
            sharedValue.columnName === 'Promedio' &&
            sharedValue.rowIndex === 0
        ) {
            if (sharedValue.tableName === metrologiaTuboTorqueTableName) {
                tuboTorquePromedio = sharedValue.value;
            } else if (sharedValue.tableName === metrologiaAnilloFlingerTableName) {
                anilloFlingerPromedio = sharedValue.value;
            }
        }
    });
    if (anilloFlingerPromedio && tuboTorquePromedio) {
        const anilloFlingerPromedioNumber = parseFloat(anilloFlingerPromedio.replace(',', '.'));
        const tuboTorquePromedioNumber = parseFloat(tuboTorquePromedio.replace(',', '.'));
        updateTable(0, 1, `${roundNumber((anilloFlingerPromedioNumber > tuboTorquePromedioNumber) ? (anilloFlingerPromedioNumber - tuboTorquePromedioNumber) : (tuboTorquePromedioNumber - anilloFlingerPromedioNumber))}`);
    } else {
        updateTable(0, 1, '');

    }
};

export const motorTraccion5GEB25DiferenciaBacklashCalculations = (sharedValues: SharedValue[], updateTable: (tableRowIndex: number, tableRowElementIndex: number, value?: string) => void) => {
    const backlashABC: string[] = ['', '', ''];
    const backlashABCNumbers: (number | undefined)[] = [undefined, undefined, undefined];
    sharedValues.forEach((sharedValue) => {
        if (
            sharedValue.value &&
            [desarmeInicialStageName, instalacionTapaPlanetarios].includes(sharedValue.stageName) &&
            [medirJuegoBacklashPlanetarioTaskName, medicionBacklashTaskName].includes(sharedValue.taskName) &&
            sharedValue.tableName === backlashTableName &&
            sharedValue.columnName === 'Valor (" pulgadas)' &&
            ([0, 1, 2].includes(sharedValue.rowIndex))
        ) {
            backlashABC[sharedValue.rowIndex] = sharedValue.value || '';
        }
    });
    if (((): boolean => {
        let medidasValidasCounter = 0;
        backlashABC.forEach((backlash, backlashIndex) => {
            const backlashString = backlash;
            if (backlashString && typeof backlashString === 'string') {
                medidasValidasCounter = medidasValidasCounter + 1;
                backlashABCNumbers[backlashIndex] = parseFloat(backlashString.replace(',', '.'));
            }
        });
        return (medidasValidasCounter > 1);
    })()) {
        if (backlashABCNumbers[0] !== undefined && backlashABCNumbers[1] !== undefined) {
            updateTable(0, 1, `${roundNumber((backlashABCNumbers[0] > backlashABCNumbers[1]) ? (backlashABCNumbers[0] - backlashABCNumbers[1]) : (backlashABCNumbers[1] - backlashABCNumbers[0]))}`);
        } else {
            updateTable(0, 1, '');
        }
        if (backlashABCNumbers[1] !== undefined && backlashABCNumbers[2] !== undefined) {
            updateTable(1, 1, `${roundNumber((backlashABCNumbers[1] > backlashABCNumbers[2]) ? (backlashABCNumbers[1] - backlashABCNumbers[2]) : (backlashABCNumbers[2] - backlashABCNumbers[1]))}`);
        } else {
            updateTable(1, 1, '');
        }
        if (backlashABCNumbers[2] !== undefined && backlashABCNumbers[0] !== undefined) {
            updateTable(2, 1, `${roundNumber((backlashABCNumbers[2] > backlashABCNumbers[0]) ? (backlashABCNumbers[2] - backlashABCNumbers[0]) : (backlashABCNumbers[0] - backlashABCNumbers[2]))}`);
        } else {
            updateTable(2, 1, '');
        }
    } else {
        updateTable(0, 1, '');
        updateTable(1, 1, '');
        updateTable(2, 1, '');
    }
};

export const motorTraccion5GEB25RegistroABCalculations = (sharedValues: SharedValue[], updateTable: (tableRowIndex: number, tableRowElementIndex: number, value?: string) => void) => {
    const ABCValues: string[] = ['', '', ''];
    sharedValues.forEach((sharedValue) => {
        if (
            sharedValue.value &&
            sharedValue.stageName === instalacionTuboTorqueStageName &&
            sharedValue.taskName === registroABCTaskName &&
            sharedValue.tableName === registroABCVariableName &&
            sharedValue.columnName === 'Medición' &&
            ([0, 1, 2].includes(sharedValue.rowIndex))
        ) {
            ABCValues[sharedValue.rowIndex] = sharedValue.value || '';
        }
    });
    updateTable(0, 2, ABCValues[2]);
    if (ABCValues[0] && ABCValues[1]) {
        const result = stringToNumber(ABCValues[0]) - stringToNumber(ABCValues[1]);
        updateTable(0, 1, `${(result < 0 ? result * -1 : result)}`);
    } else {
        updateTable(0, 1, '');
    }
}