import { Reparacion, ReparacionForm, ReparacionRequestBody } from "../../models/Reparaciones";

export const getEmptyReparacionForm = (): ReparacionForm => {
    return {
        fechaInicial: '',
        tot: { label: 'NO', value: { tot: 'NO' } }
    }
}

export const formatReparacionRequestBody = (reparacionForm: ReparacionForm): ReparacionRequestBody => {
    return {
        fechaInicial: reparacionForm.fechaInicial,
        tot: reparacionForm.tot.value.tot,
        fechaFinal: reparacionForm.fechaFinal
    }
}

export const formatReparacionForm = (reparacion: Reparacion): ReparacionForm => {
    return {
        fechaInicial: reparacion.fechaInicial,
        fechaFinal: reparacion.fechaFinal || '',
        tot: { label: reparacion.tot, value: { tot: reparacion.tot } }
    }
}