import TipoMotivo from "../../models/desviaciones/TipoMotivo";
import { SelectOption } from "../../models/optionsFilters/Select";

const useMotivoProcedimientos = (): SelectOption<TipoMotivo>[] => {
  return [
    { label: "SIN PROCEDIMIENTO", value: { motivo: "SIN PROCEDIMIENTO" } },
    { label: "SIN ACTUALIZAR", value: { motivo: "SIN ACTUALIZAR" } },
  ];
};

export default useMotivoProcedimientos;
