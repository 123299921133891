import { ReactElement, ReactText } from "react";
import DatePicker from "react-datepicker";
import { format, parse } from "date-fns";
import classNames from "classnames";

interface DateSelectProps {
    title: string,
    name: string,
    name2?: string,
    onChangeField: ((name: string, value: unknown) => void) | ((name: string, value: ReactText) => void),
    selected?: Date | string,
    selected2?: Date | string,
    disabled?: boolean,
    minDate?: Date | string,
    maxDate?: Date | string,
    startDate?: Date | string,
    endDate?: Date | string,
    isClearable?: boolean,
    invalid?: boolean,
    placeholder?: string,
    isInterval?: boolean
}

const DateSelect = ({
    title, name, name2, onChangeField, selected, selected2, disabled, minDate, maxDate, isClearable, startDate, endDate, invalid, placeholder, isInterval
}: DateSelectProps): ReactElement => {

    const getDate = (date?: string | Date): Date | undefined => {
        if (date) {
            if (typeof date === 'string') {
                return parse(date, 'dd/MM/yyyy', new Date());
            } else {
                return date;
            }
        } else {
            return undefined;
        }
    }

    const classNameString = classNames('form-control', { 'is-invalid': invalid });

    return (
        <>
            <div className = "form-group">{title}</div>
            {!isInterval ?
                <DatePicker
                    className = {classNameString}
                    dateFormat = "dd/MM/yyyy"
                    selected = {getDate(selected)}
                    onChange = {(date): void => {
                        let changedFilter;
                        if (date) {
                            changedFilter = format((date as Date), "dd/MM/yyyy");
                        } else {
                            changedFilter = '';
                        }
                        onChangeField(name, changedFilter);
                    }}
                    disabled = {disabled}
                    isClearable = {isClearable}
                    maxDate = {getDate(maxDate)}
                    minDate = {getDate(minDate)}
                    placeholderText = {placeholder}
                    locale = {"es"}
                /> :
                name2 &&
                    (<>
                        <DatePicker
                            className = "form-control"
                            disabled = {disabled}
                            dateFormat = "dd/MM/yyyy"
                            selected = {getDate(selected)}
                            onChange = {(date): void => {
                                let changedFilter;
                                if (date) {
                                    changedFilter = format((date as Date), "dd/MM/yyyy");
                                } else {
                                    changedFilter = '';
                                }
                                onChangeField(name, changedFilter);
                            }}
                            selectsStart
                            startDate = {getDate(startDate)}
                            endDate = {getDate(endDate)}
                            isClearable
                            placeholderText = {placeholder ? placeholder : 'Desde'}
                            locale = {"es"}
                        />
                        <DatePicker
                            className = "form-control"
                            disabled = {disabled}
                            dateFormat = "dd/MM/yyyy"
                            selected = {getDate(selected2)}
                            onChange = {(date): void => {
                                let changedFilter;
                                if (date) {
                                    changedFilter = format((date as Date), "dd/MM/yyyy");
                                } else {
                                    changedFilter = '';
                                }
                                onChangeField(name2, changedFilter);
                            }}
                            selectsEnd
                            startDate = {getDate(startDate)}
                            endDate = {getDate(endDate)}
                            isClearable
                            placeholderText = { placeholder ? placeholder : 'Hasta'}
                            locale = {"es"}
                        />
                    </>)
            }
        </>
    );
}

export default DateSelect;