import { ReactElement, useEffect, useState } from "react";
import { Card, Col, Row } from "react-bootstrap";
import MetaPlanificacion from "../../components/planificaciones/MetaPlanificacion";
import { IPlanificacionDetails } from "../../models/planificacionesERP/PlanificacionERP";
import { getPlanificacionesMeta } from "../../api/PlanificacionesERPApi";
import MesFilterComponent from "../../components/planificaciones/MesFilterComponent";
import useMesPlanificacion from "../../hooks/planificaciones/useMesPlanificacion";

const IndicadoresMetaPage = (): ReactElement => {
  const [planificaciones, setPlanificaciones] = useState<IPlanificacionDetails[]>([]);
  const [mes, setMes] = useState<string>();
  const mesesOptions = useMesPlanificacion();

  const metaMT: number = 5;
  const metaAlternador: number = 4;
  const metaBlower: number = 8;

  const alternadoresTerminadosEv = planificaciones.filter(
    (p) =>
      p.estado === "TERMINADO" &&
      p.tipo === "ALTERNADOR PRINCIPAL" &&
      p.proceso === "EVALUACIÓN"
  ).length;
  const alternadoresTerminadosRep = planificaciones.filter(
    (p) =>
      p.estado === "TERMINADO" &&
      p.tipo === "ALTERNADOR PRINCIPAL" &&
      p.proceso === "REPARACIÓN"
  ).length;

  const blowerTerminadosEv = planificaciones.filter(
    (p) =>
      p.estado === "TERMINADO" &&
      p.tipo === "BLOWER" &&
      p.proceso === "EVALUACIÓN"
  ).length;
  const blowerTerminadosRep = planificaciones.filter(
    (p) =>
      p.estado === "TERMINADO" &&
      p.tipo === "BLOWER" &&
      p.proceso === "REPARACIÓN"
  ).length;

  const calcularMTCompletos = (proceso: "EVALUACIÓN" | "REPARACIÓN") => {
    const planFiltradas = planificaciones.filter(
      (p) => p.proceso === proceso && p.estado === "TERMINADO"
    );
    const countMotorTraccion = planFiltradas.filter(
      (p) => p.tipo === "MOTOR DE TRACCION"
    ).length;
    const countMotorElectrico = planFiltradas.filter(
      (p) => p.tipo === "MOTOR ELECTRICO"
    ).length;
    const countFrenos = planFiltradas.filter(
      (p) => p.tipo === "CONJUNTO DE FRENOS"
    ).length;

    return Math.min(countMotorTraccion, countMotorElectrico, countFrenos);
  };

  const mtCompletosEvaluacion = calcularMTCompletos("EVALUACIÓN");
  const mtCompletosReparacion = calcularMTCompletos("REPARACIÓN");

  useEffect(() => {
    if (!mes) {
      setPlanificaciones([]);
      return;
    }

    getPlanificacionesMeta(mes)
      .then((response) => {
        if (response.data) {
          setPlanificaciones(response.data)
        } else {
          console.log("No fue posible obtener las planificaciones");
        }
      })
      .catch(() => {
        console.log("No fue posible obtener las planificaciones");
      });
  }, [mes]);

  return (
    <>
      <Card className="cards-container mb-3">
        <Card.Body className="cards-body-groups">
          <div className="cards-header">
            <MesFilterComponent mesesOptions={mesesOptions} onMesChange={setMes}/>
          </div>
        </Card.Body>
      </Card>
      <Row>
        <Col>
          <MetaPlanificacion
            meta={metaMT}
            completosEvaluacion={mtCompletosEvaluacion}
            completosReparacion={mtCompletosReparacion}
            componente="MT"
          />
        </Col>
        <Col>
          <MetaPlanificacion
            meta={metaAlternador}
            completosEvaluacion={alternadoresTerminadosEv}
            completosReparacion={alternadoresTerminadosRep}
            componente="Alternador"
          />
        </Col>
        <Col>
          <MetaPlanificacion
            meta={metaBlower}
            completosEvaluacion={blowerTerminadosEv}
            completosReparacion={blowerTerminadosRep}
            componente="Blower"
          />
        </Col>
      </Row>
    </>
  )
}

export default IndicadoresMetaPage;