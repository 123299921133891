import { SelectOption } from "../../models/optionsFilters/Select";
import TipoEstado from "../../models/planificacionesERP/TipoEstado";

const useEstadosPlanificacion = (): SelectOption<TipoEstado>[] => {
  return [
    { label: "TERMINADO", value: { estado: "TERMINADO" } },
    { label: "EN PROCESO", value: { estado: "EN PROCESO" } },
    { label: "DETENIDO", value: { estado: "DETENIDO" } },
  ];
};

export default useEstadosPlanificacion;
