import { ReactElement, useState } from "react";
import { Card } from "react-bootstrap";
import useGalponPlanificacion from "../../hooks/planificaciones/useGalponPlanificacion";
import useDimensionDesviacion from "../../hooks/desviaciones/useDimensionDesviacion";
import DesviacionesFiltersComponent from "../../components/desviaciones/DesviacionesFiltersComponent";
import { DesviacionesFilters } from "../../models/desviaciones/Desviaciones";
import { getEmptyDesviacionesFilters } from "../../utils/desviaciones";
import DesviacionesList from "../../components/desviaciones/DesviacionesList";
import useEstadoDesviacion from "../../hooks/desviaciones/useEstadoDesviacion";

const DesviacionesPage = (): ReactElement => {
  const [desviacionesFilters, setDesviacionesFilters] =
    useState<DesviacionesFilters>(getEmptyDesviacionesFilters());
  const galponDesviacionOptions = useGalponPlanificacion();
  const dimensionDesviacionOptions = useDimensionDesviacion();
  const estadoDesviacionOptions = useEstadoDesviacion();

  const desviacionesChange = (desviacionesFilters: DesviacionesFilters) => {
    setDesviacionesFilters(desviacionesFilters);
  };

  return (
    <Card className="cards-container">
      <Card.Body className="cards-body-groups">
        <div className="cards-header">
          <DesviacionesFiltersComponent
            desviacionesFilters={desviacionesFilters}
            galponPlanificacionOptions={galponDesviacionOptions}
            dimensionDesviacionOptions={dimensionDesviacionOptions}
            estadoDesviacionOptions={estadoDesviacionOptions}
            onFiltersChange={setDesviacionesFilters}
          />
        </div>
        <DesviacionesList
          desviacionesFilters={desviacionesFilters}
          setDesviacionesFilters={desviacionesChange}
        />
      </Card.Body>
    </Card>
  );
};

export default DesviacionesPage;
