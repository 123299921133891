import cogoToast from "cogo-toast";
import { ReactElement, useEffect, useState } from "react";
import { Button, Card, Col, Modal, Row, Spinner } from "react-bootstrap";
import { useHistory, useLocation } from "react-router-dom";
import { deleteComponenteReparado, putComponenteReparado } from "../../api/ComponentesApi";
import useFormState from "../../hooks/useFormState";
import { ComponenteReparadoDetails, ComponenteReparadoForm } from "../../models/componentes/Componente";
import { datosComponenteReparadoArray, datosReparacionComponenteReparadoArray, formatComponenteReparadoForm, formatComponenteReparadoRequestBody } from "../../utils/componentes";
import ComponenteReparadoFormComponent from "./ComponenteReparadoFormComponent";
import QRCode from 'qrcode';
import DocumentosReparacion from "../files/DocumentosReparacion";
import CertificadoCalibracionEquiposHerramientas from "../files/CertificadoCalibracionEquiposHerramientas";
import CertificadoPersonal from "../files/CertificadoPersonal";
import GuiaDespachoRecepcion from "../files/GuiaDespachoRecepcion";
import RegistroRecepcion from "../files/RegistroRecepcion";
import InformeFallasRecepcion from "../files/InformeFallasRecepcion";
import SubComponenteReparadoComponent from "./SubComponenteReparadoComponent";
import { Tab, Tabs, TabList, TabPanel } from 'react-tabs';
import GuiaDespachoEntrega from "../files/GuiaDespachoEntrega";
import { bodyCardsFields } from "../../utils/bodyCards";
import { OrdenProceso } from "../../models/estatusGeneral/OrdenProceso";
import InformeEvaluacionReparacionQR from "../evaluaciones/InformeEvaluacionReparacionQR";
import { getOrdenProceso } from "../../api/OrdenesProcesoApi";
import DocumentosEvaluacion from "../files/DocumentosEvaluacion";

interface ComponenteReparadoDetailsComponentProps {
    componenteReparado: ComponenteReparadoDetails,
    onComponenteReparadoChange: (componenteReparado: ComponenteReparadoDetails) => void,
    userPermission: boolean,
    isRolCliente: boolean
}

const ComponenteReparadoDetailsComponent = ({
    componenteReparado, onComponenteReparadoChange, userPermission, isRolCliente
}: ComponenteReparadoDetailsComponentProps): ReactElement => {

    const [ isBusy, setIsBusy ] = useState<boolean>(false);
    const [ ordenProceso, setOrdenProceso ] = useState<OrdenProceso>();
    const [ qrText, setQrText ] = useState<string>();
    const formState = useFormState(formatComponenteReparadoForm(componenteReparado));
    const history = useHistory();
    const location = useLocation();
    const datosComponenteReparadoHTML = bodyCardsFields(datosComponenteReparadoArray(componenteReparado));
    const datosReparacionComponenteReparadoHTML = bodyCardsFields(datosReparacionComponenteReparadoArray(componenteReparado));

    const changeIsBusy = (state: boolean) => setIsBusy(state);
    const [show, setShow] = useState(false);
    const handleShow = () => setShow(true);
    const handleClose = () => {
        formState.reset();
        setShow(false);
    };

    const [showQR, setShowQR] = useState(false);
    const handleShowQR = async () => {
        await currentPathToQR().then((response) => {
            setQrText(response);
        });
        setShowQR(true);
    };
    const handleCloseQR = () => {
        setShowQR(false);
    };

    const currentPathToQR = async (): Promise<string> => {
        // return await QRCode.toDataURL(`http://127.0.0.1:3002${location.pathname}`);
        //return await QRCode.toDataURL(`http://192.168.1.165${location.pathname}`);
        //return await QRCode.toDataURL(`http://192.168.0.99${location.pathname}`);
        return await QRCode.toDataURL(`http://ec2-15-228-240-5.sa-east-1.compute.amazonaws.com${location.pathname}`);
    };

    const submitForm = (componenteReparadoForm: ComponenteReparadoForm) => {
        putComponenteReparado(formatComponenteReparadoRequestBody(
            componenteReparado.codigoOP, componenteReparadoForm, (componenteReparado.subComponentesReparados ? componenteReparado.subComponentesReparados.map((subOP) => subOP.codigoOP) : undefined)
            ), componenteReparado._id)
            .then((response) => {
                if (response.data.codigoOP) {
                    onComponenteReparadoChange(response.data);
                    formState.setSubmitting(false);
                    setShow(false);
                    cogoToast.success('Componente reparado editado!');
                } else {
                    // cogoToast.error(response.data.message);
                    cogoToast.error('No fue posible editar el componente reparado');
                }
            })
            .catch(() => {
                // cogoToast.error(error);
                formState.setSubmitting(false);
            })
    };

    const deleteCurrentComponenteReparado = (): void => {
        formState.setSubmitting(true);
        deleteComponenteReparado(componenteReparado._id)
            .then(() => {
                cogoToast.success('Componente Reparado Eliminado!');
                history.push('/componentesQR');
            })
            .catch(() => {
                cogoToast.error('No fue posible eliminar el componente reparado');
                formState.setSubmitting(false);
            });
    };

    const postSubComponenteReparado = (codigoOP: string, handleClose: () => void) => {
        let subcomponentes: string[] = [];
        if (componenteReparado.subComponentesReparados) {
            subcomponentes = [...componenteReparado.subComponentesReparados.map((subOP) => subOP.codigoOP), codigoOP];
        } else {
            subcomponentes = [codigoOP];
        }
        putComponenteReparado(formatComponenteReparadoRequestBody(componenteReparado.codigoOP, formState.value, subcomponentes), componenteReparado._id)
            .then((response) => {
                if (response.data.codigoOP) {
                    onComponenteReparadoChange(response.data);
                    cogoToast.success('Sub-componente agregado!');
                } else {
                    cogoToast.error('No fue posible crear el subcomponente, código OP inválido');
                }
                handleClose();
            })
            .catch(() => {
                // cogoToast.error(error);
                handleClose();
            })
    };

    const deleteSubComponenteReparado = (codigoOP: string, handleClose: () => void) => {
        let subComponentesReparadosList: string[] = [];
        componenteReparado.subComponentesReparados && componenteReparado.subComponentesReparados.map((componente) => {
            if (componente.codigoOP !== codigoOP) {
                subComponentesReparadosList.push(componente.codigoOP);
            }
            return null;
        });
        putComponenteReparado(formatComponenteReparadoRequestBody(componenteReparado.codigoOP, formState.value, subComponentesReparadosList), componenteReparado._id)
        .then((response) => {
            if (response.data.codigoOP) {
                onComponenteReparadoChange(response.data);
                cogoToast.success('Sub-componente eliminado!');
            } else {
                // cogoToast.error(response.data.message);
                cogoToast.error('No fue posible eliminar el sub-componente');
            }
            handleClose();
        }).catch(() => {
            // cogoToast.error(error);
            cogoToast.error('No fue posible eliminar el sub componente');
            handleClose();
        });
    };

    useEffect(() => {
        getOrdenProceso(componenteReparado.codigoOP)
            .then((ordenProcesoResponse) => {
                if (ordenProcesoResponse.data) {
                    setOrdenProceso(ordenProcesoResponse.data);
                }
            }).catch(() => {
                cogoToast.error('No fue posible obtener la orden de proceso');
            })
    }, [componenteReparado]);

    /*
    const editSubComponenteReparado = (subComponenteReparadoForm: SubComponenteReparadoForm, setShowAndSubmittingToFalse: () => void) => {
        putComponenteReparado(
            formatComponenteReparadoRequestBody(
                formState.value,
                componenteReparado.subComponentesReparados.map((componente) => {
                    if (componente.codigoOP === subComponenteReparadoForm.codigoOP) {
                        componente.descripcionComponente = subComponenteReparadoForm.descripcionComponente;
                        componente.numeroSerieComponente = subComponenteReparadoForm.numeroSerieComponente;
                        componente.modeloComponente = subComponenteReparadoForm.modeloComponente;
                        componente.codigoMaterial = subComponenteReparadoForm.codigoMaterial;
                        componente.fechaRecepcion = subComponenteReparadoForm.fechaRecepcion;
                        componente.codigoSolped = subComponenteReparadoForm.codigoSolped;
                        componente.numeroGuiaDespacho = subComponenteReparadoForm.numeroGuiaDespacho;
                        componente.fechaEvaluacion = subComponenteReparadoForm.fechaEvaluacion;
                        componente.fechaReparacion = subComponenteReparadoForm.fechaReparacion;
                        componente.ordenCompra = subComponenteReparadoForm.ordenCompra;
                    }
                    return componente;
                })
            ),
            componenteReparado._id
        ).then((response) => {
            if (response.data.codigoOP) {
                onComponenteReparadoChange(response.data);
                cogoToast.success('Sub-componente editado!');
            } else {
                cogoToast.error('No fue posible editar el sub-componente, código OP inválido');
            }
            setShowAndSubmittingToFalse();
        }).catch(() => {
            cogoToast.error('No fue posible editar el sub-componente');
            setShowAndSubmittingToFalse();
        });
    };
    */

    return(
        <>
            <Tabs>
                <TabList>
                    <Tab><h5>{componenteReparado.descripcionComponente}</h5></Tab>
                    {componenteReparado.subComponentesReparados && (
                        componenteReparado.subComponentesReparados.map((componente) => {
                            return (
                                <Tab>
                                    <h5>{componente.descripcionComponente ? componente.descripcionComponente : componente.codigoOP}</h5>
                                </Tab>
                            );
                        })
                    )}
                    {userPermission && !isRolCliente && (
                        <Tab>
                            <div className = "react-tabs-new-tab">Agregar Sub-componente</div>
                        </Tab>
                    )}
                </TabList>
                <TabPanel>
                    <Card className = "cards-container">
                        <Card.Header className = "cards-header">
                            <div className = "body-title">
                                {componenteReparado.descripcionComponente}
                            </div>
                            <div className = "buttons-group">
                                {!isRolCliente &&
                                    <Button onClick = {handleShow} disabled = {!userPermission || isBusy}>
                                        {isBusy ? <Spinner animation = "border" size = "sm"/> : 'Editar'}
                                    </Button>
                                }
                                <Button onClick = {handleShowQR} disabled = {isBusy}>
                                    {isBusy ? <Spinner animation = "border" size = "sm"/> : 'Mostrar QR'}
                                </Button>
                            </div>
                        </Card.Header>
                        <Card.Body className = "cards-body-groups">
                            {datosComponenteReparadoHTML}
                        </Card.Body>
                    </Card>
                    <Card className = "cards-container">
                        <Card.Header className = "cards-header">
                            <div className = "body-subtitle">
                                Recepción
                            </div>
                        </Card.Header>
                        <Card.Body>
                            <Row>
                                <Col>
                                    <u>Fecha</u>
                                    <h3>{componenteReparado.fechaRecepcion}</h3>
                                </Col>
                                {componenteReparado.codigoSolped && (
                                    <Col>
                                        <u>SOLPED</u>
                                        <h3>{componenteReparado.codigoSolped}</h3>
                                    </Col>
                                )}
                                {componenteReparado.numeroGuiaDespachoRecepcion && (
                                    <Col>
                                        <u>N° Guía Despacho</u>
                                        <h3>{componenteReparado.numeroGuiaDespachoRecepcion}</h3>
                                    </Col>
                                )}
                            </Row>
                        </Card.Body>
                        <GuiaDespachoRecepcion
                            isComponenteQR
                            codigoOP = {componenteReparado.codigoOP}
                            userPermission = {userPermission}
                            isBusy = {isBusy}
                            changeBusyState = {(state: boolean) => setIsBusy(state)}
                            guiaDespachoDoc = {componenteReparado.guiaDespachoRecepcionDoc}
                        />
                        <RegistroRecepcion
                            isComponenteQR
                            codigoOP = {componenteReparado.codigoOP}
                            userPermission = {userPermission}
                            isBusy = {isBusy}
                            changeBusyState = {(state: boolean) => setIsBusy(state)}
                            registroDoc = {componenteReparado.registroDoc}
                        />
                        <InformeFallasRecepcion
                            isComponenteQR
                            codigoOP = {componenteReparado.codigoOP}
                            userPermission = {userPermission}
                            isBusy = {isBusy}
                            changeBusyState = {(state: boolean) => setIsBusy(state)}
                            informeFallasRecepcionDoc = {componenteReparado.informeFallasRecepcionDoc}
                        />
                    </Card>
                    <Card className = "cards-container">
                        <Card.Header className = "cards-body">
                            <div className = "body-subtitle">
                                Evaluación
                            </div>
                        </Card.Header>
                        <Card.Body>
                            <Row>
                                <Col>
                                    <u>Fecha</u>
                                    <h3>{componenteReparado.fechaEvaluacion}</h3>
                                </Col>
                            </Row>
                        </Card.Body>
                        {ordenProceso?.desarmeEvaluacionComponente ?
                            <InformeEvaluacionReparacionQR
                                ordenProceso = {ordenProceso}
                            />
                            :
                            <DocumentosEvaluacion
                                isComponenteQR
                                codigoOP = {componenteReparado.codigoOP}
                                userPermission = {userPermission}
                                isBusy = {isBusy}
                                changeBusyState = {changeIsBusy}
                                documentosListado = {componenteReparado.evaluacionDocs}
                            />
                        }
                    </Card>
                    <Card className = "cards-container">
                        <Card.Header className = "cards-body">
                            <div className = "body-subtitle">
                                Reparación
                            </div>
                        </Card.Header>
                        <Card.Body>
                            {datosReparacionComponenteReparadoHTML}
                        </Card.Body>
                        {ordenProceso?.armadoReparacionComponente ?
                            <InformeEvaluacionReparacionQR
                                ordenProceso = {ordenProceso}
                                isReparacion
                            />
                            :
                            <DocumentosReparacion
                                isComponenteQR
                                codigoOP = {componenteReparado.codigoOP}
                                userPermission = {userPermission}
                                isBusy = {isBusy}
                                changeBusyState = {changeIsBusy}
                                documentosListado = {componenteReparado.reparacionDocs}
                            />
                        }
                    </Card>
                    {componenteReparado.numeroGuiaDespachoEntrega &&
                        <Card className = "cards-container">
                            <Card.Header className = "cards-body">
                                <div className = "body-subtitle">
                                    Entrega
                                </div>
                            </Card.Header>
                            <Card.Body>
                                <Row>
                                    <Col>
                                        <u>Fecha</u>
                                        <h3>{componenteReparado.fechaEntrega}</h3>
                                    </Col>
                                    <Col>
                                        <u>N° guía despacho</u>
                                        <h3>{componenteReparado.numeroGuiaDespachoEntrega}</h3>
                                    </Col>
                                </Row>
                            </Card.Body>
                            <GuiaDespachoEntrega
                                isComponenteQR
                                codigoOP = {componenteReparado.codigoOP}
                                userPermission = {userPermission}
                                isBusy = {isBusy}
                                changeBusyState = {(state: boolean) => setIsBusy(state)}
                                guiaDespachoDoc = {componenteReparado.guiaDespachoEntregaDoc}
                            />
                        </Card>
                    }
                    <CertificadoCalibracionEquiposHerramientas
                        codigoOP = {componenteReparado.codigoOP}
                        userPermission = {userPermission}
                        isRolCliente = {isRolCliente}
                        isBusy = {isBusy}
                        changeBusyState = {changeIsBusy}
                        certificadoCalibracionEquiposHerramientasDoc = {componenteReparado.certificadoCalibracionEquiposHerramientasDoc}
                    />
                    <CertificadoPersonal
                        codigoOP = {componenteReparado.codigoOP}
                        userPermission = {userPermission}
                        isRolCliente = {isRolCliente}
                        isBusy = {isBusy}
                        changeBusyState = {changeIsBusy}
                        certificadoPersonalDoc = {componenteReparado.certificadoPersonalDoc}
                    />
                    {showQR && (
                        <Modal show = {showQR} onHide = {handleCloseQR}>
                            <Modal.Header>
                                <Col className = "text-center">
                                    <Modal.Title>QR Componente Reparado</Modal.Title>
                                </Col>
                            </Modal.Header>
                            <Modal.Body className = 'text-center'>
                                {
                                    qrText ?
                                    <img src = {qrText} alt = "componenteQrCode"/> :
                                    <Spinner animation = "border" />
                                }
                            </Modal.Body>
                        </Modal>
                    )}
                    {show && (
                        <Modal show = {show} onHide = {handleClose}>
                            <Modal.Header>
                                <Col className = "text-center">
                                    <Modal.Title>Editar Componente Reparado</Modal.Title>
                                </Col>
                            </Modal.Header>
                            <Modal.Body>
                                <ComponenteReparadoFormComponent
                                    formState = {formState}
                                    submitForm = {submitForm}
                                    onCancel = {handleClose}
                                    onDelete = {deleteCurrentComponenteReparado}
                                />
                            </Modal.Body>
                        </Modal>
                    )}
                </TabPanel>
                {componenteReparado.subComponentesReparados && (
                    componenteReparado.subComponentesReparados.map((componente) => {
                        return (
                            <TabPanel>
                                <SubComponenteReparadoComponent
                                    cliente = {componenteReparado.cliente}
                                    subComponenteReparado = {componente}
                                    userPermission = {userPermission}
                                    isRolCliente = {isRolCliente}
                                    deleteSubComponenteReparado = {deleteSubComponenteReparado}
                                />
                            </TabPanel>
                        );
                    }
                ))}
                {userPermission && !isRolCliente && (
                    <TabPanel>
                        <SubComponenteReparadoComponent
                            userPermission = {userPermission}
                            isRolCliente = {isRolCliente}
                            postSubComponenteReparado = {postSubComponenteReparado}
                        />
                    </TabPanel>
                )}
            </Tabs>
        </>
    )
}

export default ComponenteReparadoDetailsComponent;