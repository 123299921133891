import jwt from 'jsonwebtoken';
import { ReactElement, ReactNode, useCallback, useEffect, useState } from "react";
import { useHistory, useLocation } from "react-router-dom";
import { getAxiosBaseURL, requestInterceptor, responseInterceptor } from '../api/Interceptors';
import AuthContext from '../contexts/AuthContext';
import { AuthContextValue } from "../models/users/AuthContext";
import JWT from '../models/users/JWT';
import { checkUrlByRoles } from '../utils/urls';
import axios from 'axios';

interface AuthStoreProps {
    children?: ReactNode;
}

const AuthStore = ({ children }: AuthStoreProps): ReactElement => {
    const history = useHistory();
    const location = useLocation();
    const [ jwtState, setJwtState ] = useState<JWT>();
    const [ previuosLocation, setPreviuosLocation ] = useState<string>();
    const [ currentAxiosBaseURL, setCurrentAxiosBaseURL ] = useState<string>();

    const setToken = useCallback(
        (jwtString: string): void => {
            localStorage.setItem('jwt', jwtString);
            setJwtState(jwt.decode(jwtString) as JWT);
        }, []
    );

    const logout = useCallback((): void => {
        setPreviuosLocation('no guardar');
        localStorage.removeItem('jwt');
        setJwtState(undefined);
        history.push('/login');
        }, []
    );

    const onChangePreviuosLocation = (previuosLocation: string): void => {
        setPreviuosLocation(previuosLocation);
    }

    const savePreviousLocationAndRedirectToLogin = (): void => {
        if (location.pathname !== '/' && location.pathname !== '/login' && previuosLocation !== 'no guardar') {
            onChangePreviuosLocation(location.pathname);
        }
        history.replace('/login');
    }

    getAxiosBaseURL(setCurrentAxiosBaseURL, currentAxiosBaseURL);
    requestInterceptor(savePreviousLocationAndRedirectToLogin);
    responseInterceptor();

    useEffect(() => {
        getAxiosBaseURL(setCurrentAxiosBaseURL, currentAxiosBaseURL);
        if (location.pathname !== '/login') {
            const localJwt = localStorage.getItem('jwt');
            if (localJwt) {
                const decodedLocalJWT = jwt.decode(localJwt) as JWT;
                if (checkUrlByRoles(decodedLocalJWT.roles, location.pathname)) {
                    if (!jwtState) {
                        setJwtState(decodedLocalJWT);
                    }
                    if (location.pathname === '/' && !jwtState?.roles.includes("CLIENTE")) {
                        history.replace('/ordenesProceso')
                    }
                } else {
                    savePreviousLocationAndRedirectToLogin();
                }
            } else {
                savePreviousLocationAndRedirectToLogin();
            }
        }
    }, [location]);

    const authContextValue: AuthContextValue = {
        setJWT: setToken,
        //hasJWT: authState.hasJWT,
        userName: jwtState?.sub || '',
        userEmail: jwtState?.email || '',
        userRut: jwtState?.rut || '',
        userRoles: jwtState?.roles || [],
        logout: logout,
        previuosLocation: previuosLocation || '',
        setPreviuosLocation: onChangePreviuosLocation
    };

    return (<AuthContext.Provider value = {authContextValue}>{children}</AuthContext.Provider>);
}

export default AuthStore;