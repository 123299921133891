import { useEffect, useState } from "react";
import { SelectOption } from "../../models/optionsFilters/Select";
import TipoProceso from "../../models/planificacionesERP/TipoProceso";
import { IPlanificacionDetails } from "../../models/planificacionesERP/PlanificacionERP";
import { getPlanificacionesOP } from "../../api/PlanificacionesERPApi";
import { getOrdenProceso } from "../../api/OrdenesProcesoApi";
import { OrdenProceso } from "../../models/estatusGeneral/OrdenProceso";

const useProcesoPlanificacion = (codigoOP?: string): SelectOption<TipoProceso>[] => {
  const opcionesIniciales: SelectOption<TipoProceso>[] = [
    { label: "EVALUACIÓN", value: { proceso: "EVALUACIÓN" } },
    { label: "REPARACIÓN", value: { proceso: "REPARACIÓN" } },
    { label: "PRUEBA", value: { proceso: "PRUEBA" } },
    { label: "VPI", value: { proceso: "VPI" } },
    { label: "TORNO", value: { proceso: "TORNO" } },
    { label: "BALANCEO", value: { proceso: "BALANCEO" } },
    { label: "DINAMÓMETRO", value: { proceso: "DINAMÓMETRO" } },
  ];

  const [planificaciones, setPlanificaciones] = useState<IPlanificacionDetails[]>([]);
  const [ordenProceso, setOrdenProceso] = useState<OrdenProceso>();

  useEffect(() => {
    if (codigoOP) {
      getPlanificacionesOP(codigoOP)
        .then((response) => {
          if (response.data) {
            setPlanificaciones(response.data);
          } else {
            console.log("No fue posible obtener las planificaciones");
          }
        })
        .catch(() => {
          console.log("No fue posible obtener las planificaciones");
        });
    } else {
      setPlanificaciones([]);
    }
  }, [codigoOP]);

  useEffect(() => {
    if (codigoOP) {
      getOrdenProceso(codigoOP)
        .then((response) => {
          if (response.data && response.data.codigoOP) {
            setOrdenProceso(response.data);
          } else {
            console.log("No fue posible obtener la orden de proceso");
          }
        })
        .catch(() => {
          console.log("No fue posible obtener la orden de proceso");
        });
    } else {
      setOrdenProceso(undefined);
    }
  }, [codigoOP]);

  if (!codigoOP) {
    return opcionesIniciales;
  }

  const procesosPlanificados = planificaciones.map((plan) => plan.proceso);

  const opcionesFiltradas = opcionesIniciales.filter((opcion) => {
    const proceso = opcion.value.proceso;

    if (proceso === "EVALUACIÓN") {
      return !!ordenProceso?.evaluacion && !procesosPlanificados.includes("EVALUACIÓN");
    }

    if (proceso === "REPARACIÓN") {
      return !!ordenProceso?.reparacion && !procesosPlanificados.includes("REPARACIÓN");
    }
    
    return !procesosPlanificados.includes(proceso);
  });

  return opcionesFiltradas;
};

export default useProcesoPlanificacion;
