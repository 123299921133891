import axios, { AxiosPromise } from "axios";
import {
  DesviacionIdResponse,
  DesviacionDetails,
  DesviacionesFilters,
  DesviacionRequestBody,
  DesviacionTablaResponse,
} from "../models/desviaciones/Desviaciones";

export function getDesviaciones(
  requestBody: DesviacionesFilters
): AxiosPromise<DesviacionTablaResponse> {
  return axios.get("api/desviaciones", { params: requestBody });
}

export function getDesviacionesList(): AxiosPromise<DesviacionDetails[]> {
  return axios.get("api/desviaciones/list");
}

export function getDesviacionesPlanificacion(
  requestBody: string,
  requestBody2: DesviacionesFilters
): AxiosPromise<DesviacionTablaResponse> {
  return axios.get(`api/desviaciones/${requestBody}/planificacion`, {
    params: requestBody2,
  });
}

export function postDesviacion(
  requestBody: DesviacionRequestBody
): AxiosPromise<DesviacionIdResponse> {
  return axios.post("api/desviaciones", requestBody);
}

export function getDesviacion(
  requestBody: string
): AxiosPromise<DesviacionDetails> {
  return axios.get(`api/desviaciones/${requestBody}`);
}

export function putDesviacion(
  requestBody: DesviacionRequestBody
): AxiosPromise<DesviacionDetails> {
  return axios.put(`api/desviaciones/${requestBody._id}`, requestBody);
}

export function deleteDesviacion(requestBody: string): AxiosPromise<void> {
  return axios.delete(`api/desviaciones/${requestBody}`);
}
