import cogoToast from "cogo-toast";
import { ReactElement, useState } from "react";
import { Button, Card, Col, Modal, Row } from "react-bootstrap";
import { useHistory } from "react-router-dom";
import { deleteEntrega, postEntrega } from "../../api/EntregasApi";
import useFormState from "../../hooks/useFormState";
import { EntregaForm } from "../../models/Entregas";
import { OrdenProceso } from "../../models/estatusGeneral/OrdenProceso";
import { AcceptedRolesElements, AcceptedUsersElements } from "../../models/users/Users";
import { formatEntregaRequestBody, formatGetEntregaRequest, getEmptyEntregaForm } from "../../utils/entregas";
import ConfirmDeletionModal from "../form/ConfirmDeletionModal";
import EntregaFormComponent from "./EntregaFormComponent";

interface EntregaOPComponentProps {
    ordenProceso: OrdenProceso,
    onOrdenProcesoChange: (ordenProceso: OrdenProceso) => void,
    userPermissionFunction: (acceptedRolesElements?: AcceptedRolesElements, acceptedUsersElements?: AcceptedUsersElements) => boolean
}

const EntregaOPComponent = ({ ordenProceso, onOrdenProcesoChange, userPermissionFunction }: EntregaOPComponentProps): ReactElement => {

    const editPermission = userPermissionFunction({ roles: ['PLANIFICACION', 'ADMINISTRADOR'] });
    const viewPermission = userPermissionFunction({ roles: ['PLANIFICACION', 'ADMINISTRADOR', 'JEFETALLER', 'CALIDAD', 'SUPERVISOR'] });

    const formState = useFormState(getEmptyEntregaForm());
    const history = useHistory();

    const [show, setShow] = useState(false);
    const handleShow = () => setShow(true);
    const handleClose = () => {
        formState.reset();
        setShow(false);
    };

    const submitForm = (entregaForm: EntregaForm) => {
        postEntrega(formatEntregaRequestBody(entregaForm, ordenProceso.codigoOP, ordenProceso.cliente))
            .then((response) => {
                if (response.data.entrega) {
                    setShow(false);
                    onOrdenProcesoChange(response.data);
                    formState.setSubmitting(false);
                    cogoToast.success('Entrega de componente añadida');
                    history.push(`/ordenesProceso/${response.data.codigoOP}/entrega`);
                } else {
                    formState.setSubmitting(false);
                    // cogoToast.error(response.data);
                    cogoToast.error('No fue posible añadir la entrega');
                }
            })
            .catch(() => {
                // cogoToast.error(error);
                cogoToast.error('No fue posible añadir la entrega');
            })
    }

    const showEntrega = () => {
        history.push(`/ordenesProceso/${ordenProceso.codigoOP}/entrega`)
    }

    const deleteCurrentEntrega = (setIsDeleting: () => void) => {
        deleteEntrega(formatGetEntregaRequest(ordenProceso))
            .then((response) => {
                if (response.data.codigoOP) {
                    setShow(false);
                    onOrdenProcesoChange(response.data);
                    formState.setSubmitting(false);
                    setIsDeleting();
                    cogoToast.success('Entrega de componente eliminada');
                } else {
                    formState.setSubmitting(false);
                    setIsDeleting();
                    // cogoToast.error(response.data);
                    cogoToast.error('No fue posible eliminar la entrega');
                }
            })
            .catch(() => {
                formState.setSubmitting(false);
                setIsDeleting();
                // cogoToast.error(error);
                cogoToast.error('No fue posible eliminar la entrega');
            })
    }

    return (
        <>
            <Card className = "cards-container">
                <Card.Header className = "cards-header">
                    <div className = "body-subtitle">
                        {ordenProceso.entrega ? 'Entrega' : 'Sin entrega'}
                    </div>
                    {ordenProceso.entrega ?
                        <div className = "buttons-group">
                            {editPermission &&
                                // ordenProceso.estado === 'REPARADA' &&
                                ordenProceso.estado !== 'DESPACHADA' &&
                                ordenProceso.estado !== 'CERRADA' &&
                                (<ConfirmDeletionModal
                                    text = 'No se eliminará la guía de despacho si contiene más órdenes de proceso'
                                    onDelete = {deleteCurrentEntrega}
                                />)
                            }
                            {viewPermission &&
                                <Button onClick = {showEntrega} variant = "success">
                                    Detalles
                                </Button>
                            }
                        </div>
                    :
                        editPermission &&
                            // ordenProceso.estado !== 'EVALUADA' &&
                            // ordenProceso.estado !== 'PRESUPUESTADA' &&
                            // ordenProceso.estado !== 'REPARADA' &&
                            // ordenProceso.estado !== 'APROBADA' &&
                            <div className = "buttons-group">
                                <Button
                                    onClick = {handleShow}
                                    // disabled = {(ordenProceso.estado === 'DESPACHADA' || ordenProceso.estado === 'REPARADA')}
                                    // disabled = {(ordenProceso.estado === 'NUEVA' || ordenProceso.estado === 'CERRADA')}
                                    disabled = {['NUEVA', 'EVALUADA', 'PRESUPUESTADA', 'APROBADA', 'REPARADA', 'CERRADA'].includes(ordenProceso.estado || '')}
                                >
                                    Agregar Entrega
                                </Button>
                            </div>
                    }
                </Card.Header>
                {ordenProceso.entrega && (
                    <Card.Body>
                        <Row>
                            <Col>
                                <u>Número guía de despacho</u>
                                <h3>{ordenProceso.entrega.numeroGuiaDespacho}</h3>
                            </Col>
                            <Col>
                                <u>Fecha de entrega</u>
                                <h3>{ordenProceso.entrega.fechaEntrega}</h3>
                            </Col>
                        </Row>
                    </Card.Body>
                )}
            </Card>
            {show &&
                // ordenProceso.reparacion?.fechaInicial &&
                ordenProceso.recepcion?.fechaRecepcion &&
                (<Modal show = {show} onHide = {handleClose}>
                    <Modal.Header>
                        <Col className = "text-center">
                            <Modal.Title>Entrega de Componente</Modal.Title>
                        </Col>
                    </Modal.Header>
                    <Modal.Body>
                        <EntregaFormComponent
                            formState = {formState}
                            submitForm = {submitForm}
                            onCancel = {handleClose}
                            minDate = {ordenProceso.reparacion?.fechaFinal || ordenProceso.evaluacion?.fechaFinal || ordenProceso.recepcion?.fechaRecepcion}
                            isCreating
                        />
                    </Modal.Body>
                </Modal>)
            }
        </>
    )
}

export default EntregaOPComponent;