import { SelectOption } from "../../models/optionsFilters/Select";
import TipoMes from "../../models/planificacionesERP/TipoMes";

const useMesPlanificacion = (): SelectOption<TipoMes>[] => {
  return [
    { label: "ENERO", value: { mes: "ENERO" } },
    { label: "FEBRERO", value: { mes: "FEBRERO" } },
    { label: "MARZO", value: { mes: "MARZO" } },
    { label: "ABRIL", value: { mes: "ABRIL" } },
    { label: "MAYO", value: { mes: "MAYO" } },
    { label: "JUNIO", value: { mes: "JUNIO" } },
    { label: "JULIO", value: { mes: "JULIO" } },
    { label: "AGOSTO", value: { mes: "AGOSTO" } },
    { label: "SEPTIEMBRE", value: { mes: "SEPTIEMBRE" } },
    { label: "OCTUBRE", value: { mes: "OCTUBRE" } },
    { label: "NOVIEMBRE", value: { mes: "NOVIEMBRE" } },
    { label: "DICIEMBRE", value: { mes: "DICIEMBRE" } },
  ];
};

export default useMesPlanificacion;
