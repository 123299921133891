import { ReactElement } from "react";
import { FormState } from "../../hooks/useFormState";
import { SelectOption } from "../../models/optionsFilters/Select";
import TipoGalpon from "../../models/planificacionesERP/TipoGalpon";
import { ServicioExternoForm } from "../../models/serviciosExternos/ServicioExterno";
import { Form, Row } from "react-bootstrap";
import Select from "../form/select/Select";
import { Input } from "../form/input/Input";
import FormButtons from "../form/buttons/FormButtons";

interface ServicioExternoFormComponentProps {
  formState: FormState<ServicioExternoForm>;
  submitForm: (servicioExternoForm: ServicioExternoForm) => void;
  onDelete?: () => void;
  onCancel: () => void;
  galponServicioOptions: SelectOption<TipoGalpon>[];
  isCreating?: boolean;
}

const ServicioExternoFormComponent = ({
  formState,
  submitForm,
  onDelete,
  onCancel,
  galponServicioOptions,
  isCreating,
}: ServicioExternoFormComponentProps): ReactElement => {
  const {
    value: servicioExternoForm,
    handleFieldChange,
    areValidationsEnabled,
    setAreValidationsEnabled,
    isSubmitting,
    setSubmitting,
    isConfirmingDelete,
    setConfirmingDelete,
  } = formState;

  const isFormInvalid =
    !servicioExternoForm.galpon || !servicioExternoForm.motivo;

  const handleSubmitClick = (
    event: React.MouseEvent<HTMLElement, MouseEvent>
  ) => {
    event.preventDefault();
    setAreValidationsEnabled(true);
    if (isFormInvalid) return;
    setSubmitting(true);
    setAreValidationsEnabled(false);
    submitForm(servicioExternoForm);
  };

  const handleDeleteClick = () => setConfirmingDelete(true);
  const cancelDelete = () => setConfirmingDelete(false);

  return (
    <>
      <Form>
        <Row>
          <Select
            title="Galpón"
            name="galpon"
            value={servicioExternoForm.galpon}
            options={galponServicioOptions}
            onSelectWithName={handleFieldChange}
          />
        </Row>
        <Row>
          <Input
            title="Motivo"
            name="motivo"
            value={servicioExternoForm.motivo}
            type="textarea"
            onChangeWithName={handleFieldChange}
            invalid={areValidationsEnabled && !servicioExternoForm.motivo}
            invalidText="Ingrese motivo"
            placeholder="Obligatorio"
          />
        </Row>
        <FormButtons
          submitButtonContent="Guardar"
          onSubmitClick={handleSubmitClick}
          onCancelClick={onCancel}
          isConfirmingDelete={isConfirmingDelete}
          onDeleteClick={onDelete ? handleDeleteClick : undefined}
          onDeleteConfirm={onDelete}
          onCancelDelete={cancelDelete}
          deleteButtonContent="Eliminar"
          isDisabled={isSubmitting}
          isLoading={isSubmitting}
          isFormInvalid={isFormInvalid}
          areValidationsEnabled={areValidationsEnabled}
        />
      </Form>
    </>
  );
};

export default ServicioExternoFormComponent;
