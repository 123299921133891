import { ReactElement, useState } from "react";
import useFormState from "../../hooks/useFormState";
import TipoGalpon from "../../models/planificacionesERP/TipoGalpon";
import { SelectOption } from "../../models/optionsFilters/Select";
import {
  formatServicioExternoRequestBody,
  getEmptyServicioExternoForm,
} from "../../utils/serviciosExternos";
import { Button, Col, Modal } from "react-bootstrap";
import { ServicioExternoForm } from "../../models/serviciosExternos/ServicioExterno";
import { postServicioExterno } from "../../api/ServiciosExternosApi";
import cogoToast from "cogo-toast";
import ServicioExternoFormComponent from "./ServicioExternoFormComponent";
import UsersCheckList from "../users/UsersCheckList";
import { getEmptyUsersFilters } from "../../utils/users";
import { UsersFilters, UserTabla } from "../../models/users/Users";
import useRolUsuarioOptions from "../../hooks/users/useRolUsuario";

interface NuevoServicioExternoProps {
  userPermission: boolean;
  galponServiciosOptions: SelectOption<TipoGalpon>[];
  onServicioExternoCreated: () => void;
}

const NuevoServicioExterno = ({
  userPermission,
  galponServiciosOptions,
  onServicioExternoCreated,
}: NuevoServicioExternoProps): ReactElement => {
  const formState = useFormState(getEmptyServicioExternoForm());

  const [showForm, setShowForm] = useState(false);
  const handleCloseForm = () => {
    setShowForm(false);
    formState.reset();
  };
  const handleShowForm = () => setShowForm(true);

  const [show, setShow] = useState(false);
  const handleClose = () => setShow(false);
  const handleShow = () => setShow(true);

  const [usersFilters, setUsersFilters] = useState<UsersFilters>(
    getEmptyUsersFilters({
      roles: useRolUsuarioOptions('evaluacionReparacion'),
      // existingUsers: existingPersonas,
      isEvaluacionDesarmeReparacionArmado: true
    })
  );
  const [usersSelected, setUsersSelected] = useState<UserTabla[]>([]);
  const changeUsersSelected = (newUsersSelected: UserTabla[]) => {
    setUsersSelected(newUsersSelected);
  };

  const handleSiguiente = () => {
    handleClose();
    handleShowForm();
  };

  const usersRut = usersSelected.map((u) => u.rut);

  const submitForm = (servicioExternoForm: ServicioExternoForm) => {
    postServicioExterno(
      formatServicioExternoRequestBody(usersRut, servicioExternoForm)
    )
      .then((response) => {
        if (response.data._id) {
          cogoToast.success("Servicio externo creado");
          onServicioExternoCreated();
          handleCloseForm();
          setUsersSelected([]);
        } else {
          cogoToast.error("No fue posible crear el servicio externo");
        }
        formState.setSubmitting(false);
      })
      .catch(() => {
        formState.setSubmitting(false);
        cogoToast.error("No fue posible crear el servicio externo");
      });
  };

  return (
    <>
      {userPermission && (
        <Button onClick={handleShow}>Nuevo Servicio Externo</Button>
      )}
      {show && (
        <Modal show={show} onHide={handleClose}>
          <Modal.Header>
            <Col className="text-center">
              <Modal.Title>Seleccionar personal</Modal.Title>
            </Col>
          </Modal.Header>
          <Modal.Body>
            <UsersCheckList
              usersFilters={usersFilters}
              changeUsersFilters={(filters: UsersFilters) =>
                setUsersFilters(filters)
              }
              usersSelected={usersSelected}
              changeUsersSelected={changeUsersSelected}
            />
            <div className="modal-buttons">
              <Button variant="success" onClick={handleSiguiente}>
                Siguiente
              </Button>
            </div>
          </Modal.Body>
        </Modal>
      )}
      {showForm && (
        <Modal show={showForm} onHide={handleCloseForm}>
          <Modal.Header>
            <Col className="text-center">
              <Modal.Title>Nuevo Servicio Externo</Modal.Title>
            </Col>
          </Modal.Header>
          <Modal.Body>
            <ServicioExternoFormComponent
              formState={formState}
              submitForm={submitForm}
              onCancel={handleCloseForm}
              galponServicioOptions={galponServiciosOptions}
              isCreating
            />
          </Modal.Body>
        </Modal>
      )}
    </>
  );
};

export default NuevoServicioExterno;
