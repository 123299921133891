import { ReactElement } from "react";
import { Card, ProgressBar } from "react-bootstrap";

interface MetaPlanificacionProps {
  meta: number;
  completosEvaluacion: number;
  completosReparacion: number;
  componente: string;
}

const MetaPlanificacion = ({
  meta,
  completosEvaluacion,
  completosReparacion,
  componente,
}: MetaPlanificacionProps): ReactElement => {
  const evaluacionWidth = meta > 0 ? (completosEvaluacion / meta) * 100 : 0;
  const reparacionWidth = meta > 0 ? (completosReparacion / meta) * 100 : 0;

  return (
    <Card>
      <Card.Body>
        <div className="d-flex justify-content-between align-items-center mb-3">
          <Card.Title className="mb-0">
            Meta Evaluación y Reparación {componente}
          </Card.Title>
          <h1 className="text-primary mb-0">{meta}</h1>
        </div>
        <div className="mb-4">
          <div className="d-flex justify-content-between align-items-center mb-1">
            <small className="text-muted">{componente} Evaluación</small>
            <small className="text-muted">
              {completosEvaluacion} de {meta}
            </small>
          </div>
          <ProgressBar 
            now={evaluacionWidth} 
            variant="primary" 
            aria-valuenow={completosEvaluacion} 
            aria-valuemin={0} 
            aria-valuemax={meta}
          />
        </div>
        <div>
          <div className="d-flex justify-content-between align-items-center mb-1">
            <small className="text-muted">{componente} Reparación</small>
            <small className="text-muted">
              {completosReparacion} de {meta}
            </small>
          </div>
          <ProgressBar 
            now={reparacionWidth} 
            variant="success" 
            aria-valuenow={completosReparacion} 
            aria-valuemin={0} 
            aria-valuemax={meta}
          />
        </div>
      </Card.Body>
    </Card>
  );
};

export default MetaPlanificacion;
