import cogoToast from "cogo-toast";
import { ReactElement, useState } from "react";
import { Button, Card, Spinner } from "react-bootstrap";
import DetallesEvaluacionReparacionApi from "../../api/EvaluacionesReparacionesApi";
import ProcesoImageComponent from "../files/ProcesoImageComponent";
import EvaluacionComponenteDetallesEmails from "../evaluaciones/EvaluacionComponenteDetallesEmails";
import EvaluacionReparacionComponenteDetallesInputs from "./EvaluacionReparacionComponenteDetallesInputs";
import EvaluacionReparacionComponenteDetallesTables from "./EvaluacionReparacionComponenteDetallesTables";
import EvaluacionReparacionComponenteEtapaParticipantes from "./EvaluacionReparacionComponenteEtapaParticipantes";
import EvaluacionReparacionComponenteResumen from "./EvaluacionReparacionComponenteResumen";
import EvaluacionReparacionComponenteDetallesChecks from "./EvaluacionReparacionComponenteDetallesChecks";
import EvaluacionReparacionComponenteDetallesCheckLists from "./EvaluacionReparacionComponenteDetallesCheckLists";
import EvaluacionReparacionComponenteDetallesObs from "./EvaluacionReparacionComponenteDetallesObs";
import EvaluacionReparacionComponenteDetallesGenerarInforme from "./EvaluacionReparacionComponenteDetallesGenerarInforme";
import { AcceptedRolesElements, AcceptedUsersElements, Persona } from "../../models/users/Users";
import EvaluacionReparacionComponenteFinalCheckList from "./EvaluacionReparacionComponenteFinalCheckList";
import EvaluacionComponenteEditFinalCheckList from "./EvaluacionReparacionComponenteEditFinalCheckList";
import EvaluacionReparacionComponenteInsumosList from "./EvaluacionReparacionComponenteInsumosList";
import ImageToShowId from "./ImageToShowId";
import { EvaluacionOrReparacionComponenteEtapa, EvaluacionOrReparacionComponenteEtapaTarea, EvaluacionOrReparacionComponenteResumenEtapa, SharedValue } from "../../models/EvaluacionesReparaciones";
import { checkFinalEtapa, getParticipantesEmailList, isAprobacionTarea, titleWithoutIndex } from "../../utils/evaluacionesReparaciones/evaluacionesReparaciones";

interface Props {
    codigoOP: string,
    etapa: string,
    userData: { userName: string, userEmail: string, userRoles: string[] },
    evaluacionReparacionComponenteEtapa: EvaluacionOrReparacionComponenteEtapa,
    changeEvaluacionReparacionComponenteEtapa: (newEvaluacionReparacionComponenteEtapa: EvaluacionOrReparacionComponenteEtapa) => void,
    userPermissionFunction: (acceptedRolesElements?: AcceptedRolesElements, acceptedUsersElements?: AcceptedUsersElements) => boolean,
    detallesApi: DetallesEvaluacionReparacionApi,
    isReparacion: boolean,
    participantesGenerales: Persona[],
}

const EvaluacionReparacionComponenteDetallesEtapa = ({
    codigoOP, etapa, userPermissionFunction, userData, evaluacionReparacionComponenteEtapa, changeEvaluacionReparacionComponenteEtapa, detallesApi, isReparacion, participantesGenerales
}: Props): ReactElement => {
    const [ isBusy, setIsBusy ] = useState<boolean>(false);
    const isFinalEtapa = checkFinalEtapa(etapa);
    const applyAprobadaState = evaluacionReparacionComponenteEtapa.isAlreadyAprobada && !isFinalEtapa;
    const changeIsBusy = (state: boolean) => setIsBusy(state);
    const [ sharedValues, setSharedValues ] = useState<SharedValue[]>();

    const changeSharedValues = (newSharedValue: SharedValue) => {
        const newSharedValues = sharedValues || [];
        let doesNewSharedValueExists = false;
        for (let i = 0; i < newSharedValues.length; i++) {
            if (newSharedValues[i].stageName === newSharedValue.stageName &&
                newSharedValues[i].taskName === newSharedValue.taskName &&
                newSharedValues[i].tableName === newSharedValue.tableName &&
                newSharedValues[i].columnName === newSharedValue.columnName &&
                newSharedValues[i].rowIndex === newSharedValue.rowIndex
            ) {
                newSharedValues[i].value = newSharedValue.value;
                doesNewSharedValueExists = true;
                break;
            }
        }
        if (!doesNewSharedValueExists) {
            newSharedValues.push(newSharedValue);
        }
        setSharedValues([...newSharedValues]);
    };

    const updateEvaluacionReparacionComponenteEtapaTarea = (nombreTarea: string, hideModal?: () => void) => {
        let tarea = evaluacionReparacionComponenteEtapa.tareas.find((tarea) => tarea.nombre === nombreTarea);
        if (tarea) {
            setIsBusy(true);
            const requireRefresh = (isAprobacionTarea(nombreTarea) ||
                !!tarea.tables?.some((table) => table.columnsSettings.some((columnSetting) => !!columnSetting.parentColumns?.some((parentColumn) => {
                    let parentColumnResponse = false;
                    const parentColumnSettings = table.columnsSettings.find((columnSetting2) => columnSetting2.columnTitle === parentColumn.name);
                    if (parentColumnSettings?.isConst || parentColumnSettings?.usersRegister || !!parentColumnSettings?.isTimeStampForColumn) {
                        parentColumnResponse = true;
                    }
                    return parentColumnResponse;
                })))
            )
            let endDate;
            if (evaluacionReparacionComponenteEtapa.etapaResumen.isEndDate) {
                endDate = 'NUEVO';
                if (isFinalEtapa && isAprobacionTarea(tarea.nombre) && !tarea.check?.check) {
                    endDate = 'ELIMINAR';
                }
            }
            detallesApi.putEvaluacionOrReparacionComponenteEtapaTarea({ ...tarea, etapa: etapa, realizadoPor: userData.userName, endDate: endDate })
                .then((response) => {
                    if (response.data) {
                        let update = evaluacionReparacionComponenteEtapa;
                        for (let i = 0; i < update.tareas.length; i++) {
                            if (update.tareas[i].nombre === nombreTarea) {
                                update.tareas[i] = response.data;
                                break;
                            }
                        }
                        for (let i = 0; i < update.etapaResumen.tareas.length; i++) {
                            if (update.etapaResumen.tareas[i].nombreTarea === nombreTarea) {
                                for (let e = 0; e < update.etapaResumen.tareas[i].valores.length; e++) {
                                    if (update.etapaResumen.tareas[i].valores[e].columna === 'Realizado por') {
                                        update.etapaResumen.tareas[i].valores[e].valor = response.data.realizadoPor;
                                        // break;
                                    } else if (update.etapaResumen.tareas[i].valores[e].columna === 'Fecha') {
                                        update.etapaResumen.tareas[i].valores[e].valor = response.data.endDate;
                                    }
                                }
                                break;
                            }
                        }
                        if (requireRefresh) {
                            window.location.reload();
                        } else {
                            changeEvaluacionReparacionComponenteEtapa({ ...update });
                        }
                        cogoToast.success('Tarea actualizada');
                    } else {
                        cogoToast.error('No fue posible actualizar la tarea');
                    }
                    setIsBusy(false);
                    if (hideModal) {
                        hideModal();
                    }
                })
                .catch(() => {
                    setIsBusy(false);
                    cogoToast.error('No fue posible actualizar la tarea');
                    if (hideModal) {
                        hideModal();
                    }
                })
        }
    }

    const changeEvaluacionReparacionComponenteEtapaResumen = (evaulacionComponenteResumen: EvaluacionOrReparacionComponenteResumenEtapa) => {
        let isReloadingPage = false;
        for (let i = 0; i < evaulacionComponenteResumen.tareas.length; i++) {
            if (evaulacionComponenteResumen.tareas[i].isDeleting || 
                ['Trabajo por realizar', 'TOT por realizar', 'Listado de insumos', 'Listado de conclusiones generales'].includes(titleWithoutIndex(evaulacionComponenteResumen.tareas[i].nombreTarea))
            ) {
                isReloadingPage = true;
                break;
            }
        }
        if (!isReloadingPage) {
            changeEvaluacionReparacionComponenteEtapa({ ...evaluacionReparacionComponenteEtapa, etapaResumen: evaulacionComponenteResumen });
        } else {
            window.location.reload();
        }
    }

    const isTareaNotEditable = (task: EvaluacionOrReparacionComponenteEtapaTarea): { isTareaNotEditable: boolean, isObsNotEditable: boolean, isSaveButtonNotEnabled: boolean } => {
        let response = { isTareaNotEditable: isBusy, isObsNotEditable: isBusy, isSaveButtonNotEnabled: isBusy };
        if (!evaluacionReparacionComponenteEtapa.doesFechaInicialExists || applyAprobadaState || !userPermissionFunction({ roles: ['ADMINISTRADOR', 'JEFETALLER', 'CALIDAD', 'SUPERVISOR'] },
            !task?.onlyForRoles ?
                {
                    acceptedUsers: getParticipantesEmailList(participantesGenerales, evaluacionReparacionComponenteEtapa.etapaResumen.participantes),
                    acceptedRolesElements: { roles: ['OPERARIO'], isExcludingRoles: false, notExactRol: true }
                }
            :
                undefined
        )) {
            response.isTareaNotEditable = true;
            response.isObsNotEditable = true;
        } else {
            const resumenTarea = evaluacionReparacionComponenteEtapa.etapaResumen.tareas.find((tarea) => tarea.nombreTarea === task.nombre);
            if (resumenTarea) {
                const columnaInicio = resumenTarea.valores.find((valor) => valor.columna === 'Inicio');
                const columnaFin = resumenTarea.valores.find((valor) => valor.columna === 'Fin');
                const columnaSiNo = resumenTarea.valores.find((valor) => valor.columna === 'SI/NO');
                if (columnaSiNo) {
                    if (columnaSiNo.valor === 'SI') {
                        if ((columnaInicio && !columnaInicio.valor) || (columnaFin && columnaFin.valor)) {
                            response.isTareaNotEditable = true;
                            response.isObsNotEditable = true;
                        }
                    } else {
                        response.isTareaNotEditable = true;
                        if (!columnaSiNo.valor) {
                            response.isObsNotEditable = true;
                        }
                    }
                    // if (columnaSiNo.valor !== 'SI' || (columnaInicio && !columnaInicio.valor) || (columnaFin && columnaFin.valor)) {
                    //     response.isTareaNotEditable = true;
                    // }
                }
            }
        }
        if (task.observations) {
            if (response.isTareaNotEditable && response.isObsNotEditable) {
                response.isSaveButtonNotEnabled = true;
            }
        } else {
            response.isSaveButtonNotEnabled = response.isTareaNotEditable;
        }
        return response;
    }

    return (
        <>
            {evaluacionReparacionComponenteEtapa.etapaResumen && (
                <>
                    {/* <EvaluacionReparacionComponenteEtapaParticipantes
                        evaulacionReparacionComponenteResumenEtapa = {evaluacionReparacionComponenteEtapa.etapaResumen}
                        userPermission = {userPermissionFunction({ roles: ['ADMINISTRADOR', 'JEFETALLER'] })}
                        changeEvaulacionReparacionComponenteResumenEtapa = {changeEvaluacionReparacionComponenteEtapaResumen}
                        isUpdating = {isBusy}
                        isButtonDisabled = {!evaluacionReparacionComponenteEtapa.doesFechaInicialExists || applyAprobadaState}
                        detallesApi = {detallesApi}
                    /> */}
                    <EvaluacionReparacionComponenteResumen
                        doesFechaInicialExists = {!!evaluacionReparacionComponenteEtapa.doesFechaInicialExists}
                        userName = {userData.userName}
                        detallesApi = {detallesApi}
                        evaulacionReparacionComponenteResumenEtapas = {[evaluacionReparacionComponenteEtapa.etapaResumen]}
                        userPermissionFunction = {userPermissionFunction}
                        codigoOP = {codigoOP}
                        changeEvaulacionReparacionComponenteResumenEtapa = {changeEvaluacionReparacionComponenteEtapaResumen}
                        userRoles = {userData.userRoles}
                        isBusy = {isBusy}
                        isAlreadyAprobada = {applyAprobadaState}
                        participantesGenerales = {participantesGenerales}
                        isEvaluacionReparacionNotComplete = {evaluacionReparacionComponenteEtapa.evaluacionReparacionEtapasStatus && evaluacionReparacionComponenteEtapa.evaluacionReparacionEtapasStatus.some((status) => status.isComplete === false)}
                        evaluacionReparacionComponenteEtapaTareasApplyConst = {evaluacionReparacionComponenteEtapa.tareas.map((tarea) => ({
                            nombreTarea: tarea.nombre,
                            isConst: tarea.applyElements ? tarea.applyElements.isConst : false
                        }))}
                        isTareaComplete = {(nombreTarea: string): boolean => {
                            const isComplete = evaluacionReparacionComponenteEtapa.tareas.find((tarea) => tarea.nombre === nombreTarea)?.isComplete;
                            if (isComplete !== undefined) {
                                return isComplete;
                            } else {
                                return true;
                            }
                        }}
                    />
                </>
            )}
            {evaluacionReparacionComponenteEtapa.tareas.map((tarea) => {
                const isNotEditable = isTareaNotEditable(tarea);
                const isGenerarInformeTarea = isFinalEtapa && isAprobacionTarea(tarea.nombre);
                const requireElementTitle = tarea.insumosLists ? false : 1 < (
                    (tarea.checkLists?.length || 0) +
                    (tarea.inputs?.length || 0) +
                    (tarea.tables?.length || 0) +
                    (tarea.imagesToShowId?.length || 0) +
                    (tarea.check ? 1 : 0) +
                    (tarea.finalCheckList ? 1 : 0) +
                    (tarea.observations ? 1 : 0) +
                    (tarea.photos ? 1 : 0)
                );
                return (
                    <Card className = "cards-container">
                        <Card.Header className = "cards-header">
                            <div className = "body-subtitle">
                                {tarea.nombre}
                            </div>
                                {!(tarea.photos && !(tarea.inputs || tarea.check || tarea.tables || tarea.checkLists || tarea.observations || tarea.finalCheckList || tarea.insumosLists)) &&
                                    <div className = "buttons-group">
                                        {(isGenerarInformeTarea && evaluacionReparacionComponenteEtapa.evaluacionReparacionEtapasStatus && tarea.check) ?
                                            <EvaluacionReparacionComponenteDetallesGenerarInforme
                                                isNotEditable = {isNotEditable.isTareaNotEditable}
                                                changeEvaluacionReparacionComponenteEtapa = {changeEvaluacionReparacionComponenteEtapa}
                                                evaluacionReparacionComponenteEtapa = {evaluacionReparacionComponenteEtapa}
                                                nombreTarea = {tarea.nombre}
                                                isBusy = {isBusy}
                                                updateEvaluacionReparacionComponenteEtapaTarea = {(hideModal: () => void) => updateEvaluacionReparacionComponenteEtapaTarea(tarea.nombre, hideModal)}
                                                changeIsBusy = {changeIsBusy}
                                                codigoOP = {codigoOP}
                                                detallesApi = {detallesApi}
                                            />
                                        :
                                            isFinalEtapa && tarea.finalCheckList ?
                                                <EvaluacionComponenteEditFinalCheckList
                                                    finalCheckList = {tarea.finalCheckList}
                                                    isBusy = {isBusy}
                                                    isNotEditable = {isNotEditable.isTareaNotEditable}
                                                    updateEvaluacionReparacionComponenteEtapaTarea = {(hideModal: () => void) => updateEvaluacionReparacionComponenteEtapaTarea(tarea.nombre, hideModal)}
                                                    changeEvaluacionReparacionComponenteEtapa = {changeEvaluacionReparacionComponenteEtapa}
                                                    evaluacionReparacionComponenteEtapa = {evaluacionReparacionComponenteEtapa}
                                                    nombreTarea = {tarea.nombre}
                                                />
                                            :
                                                isFinalEtapa && !(!tarea.insumosLists || tarea.insumosLists.length < 1) ?
                                                    undefined
                                                :
                                                    <Button onClick = {() => updateEvaluacionReparacionComponenteEtapaTarea(tarea.nombre)} variant = "success" disabled = {isNotEditable.isSaveButtonNotEnabled}>
                                                        {!isBusy ? 'Guardar' : <Spinner animation = "border" size = "sm"/>}
                                                    </Button>
                                        }
                                    </div>
                                }
                        </Card.Header>
                        <Card.Body className = "cards-body-groups">
                            {tarea.text && <div className = "centerTextCell"><h4>{tarea.text}</h4></div>}
                            {tarea.imagesToShowId?.map((imageToShowId) => 
                                <ImageToShowId
                                    name = {imageToShowId.nombre}
                                    pathInImages = {imageToShowId.pathInImages}
                                    requireElementTitle = {requireElementTitle}
                                />
                            )}
                            {tarea.check && (
                                <EvaluacionReparacionComponenteDetallesChecks
                                    tareaCheck = {tarea.check}
                                    isNotEditable = {isNotEditable.isTareaNotEditable}
                                    changeEvaluacionReparacionComponenteEtapa = {changeEvaluacionReparacionComponenteEtapa}
                                    evaluacionReparacionComponenteEtapa = {evaluacionReparacionComponenteEtapa}
                                    nombreTarea = {tarea.nombre}
                                    isGenerarInformeTarea = {isGenerarInformeTarea}
                                />
                            )}
                            {tarea.tables && (
                                tarea.tables.map((table) => 
                                    <EvaluacionReparacionComponenteDetallesTables
                                        etapa = {etapa}
                                        table = {table}
                                        changeEvaluacionReparacionComponenteEtapa = {changeEvaluacionReparacionComponenteEtapa}
                                        evaluacionReparacionComponenteEtapa = {evaluacionReparacionComponenteEtapa}
                                        nombreTarea = {tarea.nombre}
                                        isNotEditable = {isNotEditable.isTareaNotEditable}
                                        requireElementTitle = {requireElementTitle}
                                        changeSharedValues = {changeSharedValues}
                                        sharedValues = {sharedValues}
                                        userRoles = {userData.userRoles}
                                        isReparacion = {isReparacion}
                                    />
                                )
                            )}
                            {tarea.inputs && (
                                tarea.inputs.map((tareaInput) => 
                                    <EvaluacionReparacionComponenteDetallesInputs
                                        nombreTarea = {tarea.nombre}
                                        tareaInput = {tareaInput}
                                        codigoOP = {codigoOP}
                                        changeEvaluacionReparacionComponenteEtapa = {changeEvaluacionReparacionComponenteEtapa}
                                        evaluacionReparacionComponenteEtapa = {evaluacionReparacionComponenteEtapa}
                                        isNotEditable = {isNotEditable.isTareaNotEditable}
                                        requireElementTitle = {requireElementTitle}
                                    />
                                )
                            )}
                            {tarea.checkLists && (
                                tarea.checkLists.map((checkList) => 
                                    <EvaluacionReparacionComponenteDetallesCheckLists
                                        tareaCheckList = {checkList}
                                        isNotEditable = {isNotEditable.isTareaNotEditable}
                                        changeEvaluacionReparacionComponenteEtapa = {changeEvaluacionReparacionComponenteEtapa}
                                        evaluacionReparacionComponenteEtapa = {evaluacionReparacionComponenteEtapa}
                                        nombreTarea = {tarea.nombre}
                                        requireElementTitle = {requireElementTitle}
                                    />
                                )
                            )}
                            {tarea.observations && (
                                <EvaluacionReparacionComponenteDetallesObs
                                    tareaObs = {tarea.observations}
                                    isNotEditable = {isNotEditable.isObsNotEditable}
                                    changeEvaluacionReparacionComponenteEtapa = {changeEvaluacionReparacionComponenteEtapa}
                                    evaluacionReparacionComponenteEtapa = {evaluacionReparacionComponenteEtapa}
                                    nombreTarea = {tarea.nombre}
                                    requireElementTitle = {requireElementTitle}
                                />
                            )}
                            {tarea.finalCheckList && 
                                <EvaluacionReparacionComponenteFinalCheckList
                                    finalCheckList = {tarea.finalCheckList}
                                />
                            }
                            {tarea.insumosLists &&
                                tarea.insumosLists.map((insumoList) => 
                                    <EvaluacionReparacionComponenteInsumosList
                                        insumosList = {insumoList}
                                        nombreTarea = {tarea.nombre}
                                        isBusy = {isBusy}
                                        updateEvaluacionReparacionComponenteEtapaTarea = {(hideModal: () => void) => updateEvaluacionReparacionComponenteEtapaTarea(tarea.nombre, hideModal)}
                                        isNotEditable = {isNotEditable.isTareaNotEditable}
                                        changeEvaluacionReparacionComponenteEtapa = {changeEvaluacionReparacionComponenteEtapa}
                                        evaluacionReparacionComponenteEtapa = {evaluacionReparacionComponenteEtapa}
                                        showAmounts = {tarea.applyElements?.apply}
                                    />
                                )
                            }
                            {tarea.photos && (
                                <>
                                    {requireElementTitle && <u><h5>Registro fotográfico</h5></u>}
                                    <div className = "cards-body-elements">
                                        {tarea.photos.map((photo) => 
                                            <ProcesoImageComponent
                                                variable = {photo.title}
                                                etapa = {etapa}
                                                tarea = {tarea.nombre}
                                                isNotEditable = {isNotEditable.isTareaNotEditable}
                                                userName = {userData.userName}
                                                changeIsUpdating = {changeIsBusy}
                                                evaulacionReparacionComponenteResumen = {evaluacionReparacionComponenteEtapa.etapaResumen}
                                                changeEvaluacionReparacionComponenteEtapaResumen = {changeEvaluacionReparacionComponenteEtapaResumen}
                                                detallesApi = {detallesApi}
                                            />
                                        )}
                                    </div>
                                </>
                            )}
                        </Card.Body>
                    </Card>
                );
            })}
            {evaluacionReparacionComponenteEtapa.emailReport?.name === 'Reportar diferencias de OP y/o N/S' &&
                <Card className = "cards-container">
                    <Card.Header className = "cards-header">
                        <div className = "body-subtitle">
                            Reportar diferencias de OP y/o N/S
                        </div>
                    </Card.Header>
                    <Card.Body className = "cards-body-groups">
                        <EvaluacionComponenteDetallesEmails
                            emailReport = {evaluacionReparacionComponenteEtapa.emailReport}
                            userData = {userData}
                            codigoOP = {codigoOP}
                            evaluacionComponenteEtapa = {evaluacionReparacionComponenteEtapa}
                            isNotEditable = {((): boolean => {
                                const tareaNS = evaluacionReparacionComponenteEtapa.tareas.find((etapa) => titleWithoutIndex(etapa.nombre) === 'Confirmar N/S');
                                const tareaOP = evaluacionReparacionComponenteEtapa.tareas.find((etapa) => titleWithoutIndex(etapa.nombre) === 'Confirmar OP');
                                if (!tareaNS && !tareaOP) {
                                    return false;
                                } else {
                                    return (
                                        (tareaNS ? isTareaNotEditable(tareaNS).isTareaNotEditable : true) &&
                                        (tareaOP ? isTareaNotEditable(tareaOP).isTareaNotEditable : true)
                                    )
                                }
                            })()}
                        />
                    </Card.Body>
                </Card>
            }
        </>
    );
}

export default EvaluacionReparacionComponenteDetallesEtapa;