import { Dispatch, ReactElement, ReactText, SetStateAction } from "react";
import { PlanificacionesFilters } from "../../models/planificacionesERP/PlanificacionERP";
import { Col, Row } from "react-bootstrap";
import { Input } from "../form/input/Input";
import DateSelect from "../form/DateSelect";
import { SelectOption } from "../../models/optionsFilters/Select";
import TipoComponente from "../../models/componentes/TipoComponente";
import Select from "../form/select/Select";
import TipoEstado from "../../models/planificacionesERP/TipoEstado";
import TipoPrioridad from "../../models/planificacionesERP/TipoPrioridad";
import TipoGalpon from "../../models/planificacionesERP/TipoGalpon";
import TipoProceso from "../../models/planificacionesERP/TipoProceso";
import TipoMeta from "../../models/planificacionesERP/TipoMeta";

interface PlanificacionesFiltersProps {
  planificacionesFilters: PlanificacionesFilters;
  tiposComponentesOptions: SelectOption<TipoComponente>[];
  estadosPlanificacionOptions: SelectOption<TipoEstado>[];
  prioridadPlanificacionOptions: SelectOption<TipoPrioridad>[];
  galponPlanificacionOptions: SelectOption<TipoGalpon>[];
  procesoPlanificacionOptions: SelectOption<TipoProceso>[];
  metaOptions?: SelectOption<TipoMeta>[];
  onFiltersChange: Dispatch<SetStateAction<PlanificacionesFilters>>;
}

const PlanificacionesFiltersComponent = ({
  planificacionesFilters,
  tiposComponentesOptions,
  estadosPlanificacionOptions,
  prioridadPlanificacionOptions,
  galponPlanificacionOptions,
  procesoPlanificacionOptions,
  metaOptions,
  onFiltersChange,
}: PlanificacionesFiltersProps): ReactElement => {
  const handleFilterChange = (name: string, value?: ReactText): void => {
    onFiltersChange((prevFilters) => ({
      ...prevFilters,
      page: 1,
      [name]: value,
    }));
  };

  return (
    <div className="body-filters">
      <Row>
        <Col lg={2}>
          <Input
            value={planificacionesFilters.codigoOP}
            name="codigoOP"
            onChangeWithName={handleFilterChange}
            type="text"
            title="Código OP"
          />
        </Col>
        <Col lg={2}>
          <Input
            value={planificacionesFilters.cliente}
            name="cliente"
            onChangeWithName={handleFilterChange}
            type="text"
            title="Cliente"
          />
        </Col>
        <Col lg={3}>
          <Select
            title="Tipo"
            name="tipo"
            options={tiposComponentesOptions}
            onSelectWithName={(name, tipo): void => {
              handleFilterChange(name, tipo.value.tipo.toString());
            }}
            onClearWithName={(name): void => handleFilterChange(name, "")}
          />
        </Col>
        <Col lg={3}>
          <Input
            value={planificacionesFilters.modeloComponente}
            name="modeloComponente"
            onChangeWithName={handleFilterChange}
            type="text"
            title="Modelo componente"
          />
        </Col>
        {metaOptions && (
          <Col lg={2}>
            <Select
              title="Meta"
              name="isMeta"
              options={metaOptions}
              onSelectWithName={(name, isMeta): void => {
                handleFilterChange(name, isMeta.value.meta.toString());
              }}
              onClearWithName={(name): void => handleFilterChange(name, "")}
            />
          </Col>
        )}
      </Row>
      <Row>
        <Col lg={2}>
          <Select
            title="Estado"
            name="estado"
            options={estadosPlanificacionOptions}
            onSelectWithName={(name, estado): void => {
              handleFilterChange(name, estado.value.estado.toString());
            }}
            onClearWithName={(name): void => handleFilterChange(name, "")}
          />
        </Col>
        <Col lg={2}>
          <Select
            title="Prioridad"
            name="prioridad"
            options={prioridadPlanificacionOptions}
            onSelectWithName={(name, prioridad): void => {
              handleFilterChange(name, prioridad.value.prioridad.toString());
            }}
            onClearWithName={(name): void => handleFilterChange(name, "")}
          />
        </Col>
        <Col lg={2}>
          <Select
            title="Proceso"
            name="proceso"
            options={procesoPlanificacionOptions}
            onSelectWithName={(name, proceso): void => {
              handleFilterChange(name, proceso.value.proceso.toString());
            }}
            onClearWithName={(name): void => handleFilterChange(name, "")}
          />
        </Col>
        <Col lg={2}>
          <Select
            title="Galpón"
            name="galpon"
            options={galponPlanificacionOptions}
            onSelectWithName={(name, galpon): void => {
              handleFilterChange(name, galpon.value.galpon.toString());
            }}
            onClearWithName={(name): void => handleFilterChange(name, "")}
          />
        </Col>
        <Col lg={2}>
          <DateSelect
            title="Fecha ideal"
            name="fechaInicio"
            name2="fechaTermino"
            selected={planificacionesFilters.fechaInicio}
            selected2={planificacionesFilters.fechaTermino}
            onChangeField={handleFilterChange}
            startDate={planificacionesFilters.fechaInicio}
            endDate={planificacionesFilters.fechaTermino}
            isClearable
            isInterval
          />
        </Col>
        <Col lg={2}>
          <DateSelect
            title="Fecha real"
            name="fechaInicioReal"
            name2="fechaTerminoReal"
            selected={planificacionesFilters.fechaInicioReal}
            selected2={planificacionesFilters.fechaTerminoReal}
            onChangeField={handleFilterChange}
            startDate={planificacionesFilters.fechaInicioReal}
            endDate={planificacionesFilters.fechaTerminoReal}
            isClearable
            isInterval
          />
        </Col>
      </Row>
    </div>
  );
};

export default PlanificacionesFiltersComponent;
