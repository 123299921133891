import { ReactElement, useContext, useEffect, useState } from "react";
import { Accordion, Card } from "react-bootstrap";
import cogoToast from "cogo-toast";
import { GalponDetails } from "../../models/galpones/Galpon";
import { getGalpones } from "../../api/GalponesApi";
import NuevaArea from "./NuevaArea";
import NuevoPuesto from "./NuevoPuesto";
import UpdatePuesto from "./UpdatePuesto";
import { getDesviacionesList } from "../../api/DesviacionesApi";
import { DesviacionDetails } from "../../models/desviaciones/Desviaciones";
import AuthContext from "../../contexts/AuthContext";
import { getUserPermissionFunction } from "../../utils/users";

interface GalponesListProps {
  refresh: boolean;
  userPermission: boolean;
  onDataLoaded?: (galpones: GalponDetails[]) => void;
}

const GalponesList = ({
  refresh,
  userPermission,
  onDataLoaded,
}: GalponesListProps): ReactElement => {
  const [galpones, setGalpones] = useState<GalponDetails[]>([]);
  const [localRefresh, setLocalRefresh] = useState<boolean>(false);
  const [desviaciones, setDesviaciones] = useState<DesviacionDetails[]>([]);
  const { userRoles } = useContext(AuthContext);

  const handleCreated = () => {
    setLocalRefresh((prev) => !prev);
  };

  useEffect(() => {
    const fetchData = async () => {
      try {
        const response = await getGalpones();
        setGalpones(response.data);
        if (onDataLoaded) onDataLoaded(response.data);
      } catch (error) {
        console.error(error);
        cogoToast.error("Error al cargar los galpones");
      }
    };

    fetchData();
  }, [refresh, localRefresh]);

  useEffect(() => {
    const fetchData = async () => {
      try {
        const response = await getDesviacionesList();
        setDesviaciones(response.data);
      } catch (error) {
        console.error(error);
        cogoToast.error("Error al cargar las desviaciones");
      }
    };
    
    fetchData();
  }, [refresh, localRefresh])

  return (
    <>
      {galpones.length === 0 ? (
        <p>No se encontraron galpones.</p>
      ) : (
        <Accordion>
          {galpones.map((galpon, index) => (
            <Accordion.Item key={galpon._id} eventKey={index.toString()}>
              <Accordion.Header>{galpon.nombre}</Accordion.Header>
              <Accordion.Body>
                <div className="cards-header">
                  <h4>Áreas</h4>
                  <NuevaArea
                    userPermission={getUserPermissionFunction(userRoles)({
                      roles: ["ADMINISTRADOR"],
                    })}
                    id={galpon._id}
                    onCreated={handleCreated}
                  />
                </div>
                {galpon.areasTrabajo && galpon.areasTrabajo.length > 0 ? (
                  <Accordion>
                    {galpon.areasTrabajo.map((area, areaIndex) => (
                      <Accordion.Item
                        key={areaIndex}
                        eventKey={areaIndex.toString()}
                      >
                        <Accordion.Header>{area.nombre}</Accordion.Header>
                        <Accordion.Body>
                          <div className="cards-header">
                            <h4>Puestos de trabajo</h4>
                            <NuevoPuesto
                              userPermission={getUserPermissionFunction(userRoles)({
                                roles: ["ADMINISTRADOR"],
                              })}
                              id={galpon._id}
                              onCreated={handleCreated}
                              areaIndex={areaIndex}
                            />
                          </div>
                          {area.puestosTrabajo &&
                          area.puestosTrabajo.length > 0 ? (
                            <div
                              className="d-flex"
                              style={{ overflowX: "auto" }}
                            >
                              {area.puestosTrabajo.map(
                                (puesto, puestoIndex) => {
                                  
                                  const currentPlanificacionId = puesto.planificacionId ? puesto.planificacionId._id : "";
                                  const desviacionesPlanificacion = desviaciones.filter(
                                    (desviacion) => desviacion.planificacionId === currentPlanificacionId
                                  );

                                  return(
                                  <div
                                    key={puestoIndex}
                                    className="p-2"
                                    style={{ minWidth: "250px" }}
                                  >
                                    <Card>
                                      <Card.Body>
                                        <Card.Title>{puesto.codigo}</Card.Title>
                                        <Card.Text>
                                          <strong>ESTADO:</strong>{" "}
                                          {puesto.estado}
                                          {puesto.planificacionId && (
                                            <>
                                              <br />
                                              <strong>
                                                PLANIFICACIÓN:
                                              </strong>{" "}
                                              {puesto.planificacionId.codigoOP}
                                              <br />
                                              <strong>PRIORIDAD:</strong>{" "}
                                              {puesto.planificacionId.prioridad}
                                              <br />
                                              <strong>CLIENTE:</strong>{" "}
                                              {puesto.planificacionId.cliente}
                                              <br />
                                              <strong>PROCESO:</strong>{" "}
                                              {puesto.planificacionId.proceso}
                                              <br />
                                              <strong>TIPO:</strong>{" "}
                                              {puesto.planificacionId.tipo}
                                              <br />
                                              <strong>MODELO:</strong>{" "}
                                              {puesto.planificacionId.modeloComponente}
                                              <br />
                                              {[
                                                "PERSONAL",
                                                "INFRAESTRUCTURA",
                                                "EQUIPO APOYO",
                                                "REPUESTOS",
                                                "TOT",
                                                "HERRAMIENTAS",
                                                "PROCEDIMIENTOS",
                                                "OTROS",
                                              ].map((dimension) => {
                                                const desviacion = desviacionesPlanificacion.find(
                                                  (des) =>
                                                    des.dimension === dimension && des.estado !== "SOLUCIONADA"
                                                );
                                                return (
                                                  <div key={dimension}>
                                                    <strong>{dimension}:</strong>{" "}
                                                    {desviacion ? desviacion.motivo : "Sin desviación"}
                                                  </div>
                                                );
                                              })}
                                            </>
                                          )}
                                        </Card.Text>
                                        <UpdatePuesto
                                          userPermission={getUserPermissionFunction(userRoles)({
                                            roles: ["ADMINISTRADOR", "JEFETALLER", "SUPERVISOR"],
                                          })}
                                          id={galpon._id}
                                          onCreated={handleCreated}
                                          areaIndex={areaIndex}
                                          puestoIndex={puestoIndex}
                                          estado={puesto.estado}
                                          codigo={puesto.codigo}
                                        />
                                      </Card.Body>
                                    </Card>
                                  </div>
                                )}
                              )}
                            </div>
                          ) : (
                            <p>No hay puestos de trabajo en esta área.</p>
                          )}
                        </Accordion.Body>
                      </Accordion.Item>
                    ))}
                  </Accordion>
                ) : (
                  <>
                    <p>No hay áreas de trabajo en este galpón.</p>
                  </>
                )}
              </Accordion.Body>
            </Accordion.Item>
          ))}
        </Accordion>
      )}
    </>
  );
};

export default GalponesList;
