import { Destinatario } from "../models/Email";
import { SelectOption } from "../models/optionsFilters/Select";
import { AcceptedRolesElements, AcceptedUsersElements, Persona, User, UserForm, UsersFilters } from "../models/users/Users";

export const getEmptyUsersFilters = (forSelectionElements?: { roles?: SelectOption<string>[], existingUsers?: Persona[], isEvaluacionDesarmeReparacionArmado?: boolean, isEmailRequired?: boolean }): UsersFilters => {
    const response: UsersFilters = {
        rut: '',
        email: '',
        nombre: '',
        apellidos: '',
        estado: '',
        // rol: '',
        page: 1
    };
    if (forSelectionElements) {
        response.estado = 'HABILITADO';
        response.isEvaluacionDesarmeReparacionArmado = forSelectionElements.isEvaluacionDesarmeReparacionArmado;
        response.isEmailRequired = forSelectionElements.isEmailRequired;
        if (forSelectionElements.existingUsers && forSelectionElements.existingUsers.length > 0) {
            const existingPersonasString = ((): string[] => {
                const response: string[] = [];
                if (forSelectionElements.isEmailRequired) {
                    forSelectionElements.existingUsers?.forEach((persona) => {
                        if (persona.email) {
                            response.push(persona.email);
                        }
                    });
                } else {
                    forSelectionElements.existingUsers?.forEach((persona) => {
                        if (persona.rut) {
                            response.push(persona.rut);
                        }
                    });
                }
                return response;
            })();
            if (forSelectionElements.isEmailRequired) {
                response.existingUsersEmails = existingPersonasString;
            } else {
                response.existingUsersRut = existingPersonasString;
            }
        }
        if (forSelectionElements.roles && forSelectionElements.roles.length > 0) {
            response.roles = forSelectionElements.roles.map((rol) => rol.value);
        }
    }
    return response;
}

export const getEmptyUserForm = (): UserForm => {
    return {
        rut: '',
        email: '',
        nombre: '',
        apellidos: '',
        estado: { label: 'HABILITADO', value: 'HABILITADO' },
        //roles: [{ label: 'USUARIO', value: { rol: 'USUARIO' } }],
        password: ''
    }
}

export const formatUserForm = (
    user: User,
    estadoUsuarioOptions: SelectOption<string>[],
    rolUsuarioOptions: SelectOption<string>[]
    ):  UserForm => {
        const estadoUsuario = estadoUsuarioOptions.find((estado) =>
            estado.value === user.estado);
        let rolesUsuario: SelectOption<string>[] = [];
        user.roles?.map((rol) => {
            rolUsuarioOptions.map((rolOption) => {
                if (rolOption.value === rol) {
                    rolesUsuario.push(rolOption);
                }
                return null;
            });
            return null;
        });
        return {
            rut: user.rut,
            email: user.email,
            nombre: user.nombre,
            apellidos: user.apellidos,
            estado: estadoUsuario,
            roles: rolesUsuario,
            password: ''
        };
}

export const formatUser = (userForm: UserForm): User => ({
    rut: userForm.rut,
    email: userForm.email,
    nombre: userForm.nombre,
    apellidos: userForm.apellidos,
    estado: userForm.estado?.value,
    roles: userForm.roles?.map((rol) => rol.value),
    password: userForm.password,
    disponibilidad: userForm.disponibilidad?.value
})

export const formatDestinatarioCorreo = (personas: Persona[], type: string): Destinatario[] => {
    return personas.map((persona) => ({
        name: persona.name,
        email: persona.email,
        type: type
    }));
}

export const isUserEmailValid = (email: string): boolean => 
    (/^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/).test(email.toLowerCase())

export const getUserPermissionFunction = (userRoles: string[], userRut?: string): (acceptedRolesElements?: AcceptedRolesElements, acceptedUsersElements?: AcceptedUsersElements) => boolean =>
    (acceptedRolesElements?: AcceptedRolesElements, acceptedUsersElements?: AcceptedUsersElements): boolean => {
        const checkUserRolesInArray = (rolesArrayElements: AcceptedRolesElements): boolean => userRoles.some((rol) => {
            let response = false;
            if (!rolesArrayElements.notExactRol) {
                response = rolesArrayElements.roles.includes(rol);
            } else {
                response = rolesArrayElements.roles.some((arrayRol) => (new RegExp(arrayRol)).test(rol));
            }
            if (rolesArrayElements.isExcludingRoles) {
                response = !response;
            }
            return response;
        });
        let response = false;
        if (acceptedRolesElements) {
            response = checkUserRolesInArray(acceptedRolesElements);
        }
        if (!response && userRut && acceptedUsersElements) {
            const formatUserRut = ((rut: string): string => {
                if (rut === 'adminERPElectrans') {
                    return rut;
                } else {
                    const nonVerificationDigitsCounter = rut.length - 1;
                    let response = `${rut.substring(0, nonVerificationDigitsCounter)}-${rut[nonVerificationDigitsCounter]}`.split('');
                    let firstsDigitCounter = (nonVerificationDigitsCounter) % 3;
                    for (let i = firstsDigitCounter; i < response.length - 2; i = i + 4) {
                        response.splice(i, 0, '.');
                    }
                    if (firstsDigitCounter < 1) {
                        response[0] = '';
                    }
                    return (response.toString().replaceAll(',', '').toUpperCase());
                }
            })(userRut);
            if (acceptedUsersElements.acceptedRolesElements) {
                if (checkUserRolesInArray(acceptedUsersElements.acceptedRolesElements)) {
                    response = acceptedUsersElements.acceptedUsers.includes(formatUserRut);
                }
            } else {
                response = acceptedUsersElements.acceptedUsers.includes(formatUserRut);
            }
        }
        return response;
    }

export const operariosRoles = [
    'OPERARIO ELÉCTRICO POTENCIA',
    'OPERARIO MECÁNICO'
];