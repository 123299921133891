import cogoToast from "cogo-toast";
import { ReactElement, useCallback, useEffect, useState } from "react";
import { Spinner } from "react-bootstrap";
import {
  getDesviacionesPlanificacion,
  putDesviacion,
} from "../../api/DesviacionesApi";
import {
  DesviacionesFilters,
  DesviacionTabla,
} from "../../models/desviaciones/Desviaciones";
import TableDesviacion from "./TableDesviacion";
import { formatDesviacion } from "../../utils/desviaciones";
import { format } from "date-fns";

interface DesviacionesListProps {
  desviacionesFilters: DesviacionesFilters;
  setDesviacionesFilters: (desviacionesFilters: DesviacionesFilters) => void;
  id: string;
  refresh: boolean;
}

const DesviacionesPlanificacionList = ({
  desviacionesFilters,
  setDesviacionesFilters,
  id,
  refresh,
}: DesviacionesListProps): ReactElement => {
  const [desviaciones, setDesviaciones] = useState<DesviacionTabla[]>([]);
  const [totalPages, setTotalPages] = useState<number>();
  const [isLoading, setIsLoading] = useState<boolean>(true);

  const fetchDesviaciones = useCallback(() => {
    setIsLoading(true);
    getDesviacionesPlanificacion(id, desviacionesFilters)
      .then((response) => {
        if (response.data.desviaciones) {
          setDesviaciones(response.data.desviaciones);
          setTotalPages(response.data.totalPages);
        } else {
          cogoToast.error("No fue posible obtener las desviaciones");
        }
      })
      .catch(() => {
        cogoToast.error("No fue posible obtener las desviaciones");
      })
      .finally(() => {
        setIsLoading(false);
      });
  }, [id, desviacionesFilters]);

  const submitForm = (id: string) => {
    putDesviacion(
      formatDesviacion(id, {
        fechaTermino: format(new Date(), "dd/MM/yyyy"),
        estado: "SOLUCIONADA",
      })
    )
      .then((response) => {
        if (response.data._id) {
          cogoToast.success("Desviación solucionada");
          fetchDesviaciones();
        } else {
          cogoToast.error("No fue posible solucionar la desviación");
        }
      })
      .catch(() => {
        cogoToast.error("No fue posible solucionar la desviación");
      });
  };

  useEffect(() => {
    fetchDesviaciones();
  }, [desviacionesFilters, refresh]);

  if (isLoading) {
    return (
      <div className="text-center">
        <Spinner animation="border" />
      </div>
    );
  }

  if (!desviaciones.length) {
    return (
      <p className="text-center">No fue posible cargar las desviaciones</p>
    );
  }

  return (
    <TableDesviacion
      stripped
      hover
      pages={totalPages}
      activePage={desviacionesFilters.page}
      onPageSelect={(page) =>
        setDesviacionesFilters({ ...desviacionesFilters, page })
      }
    >
      <thead>
        <tr>
          <th>Código</th>
          <th>Galpón</th>
          <th>Proceso</th>
          <th>Dimensión</th>
          <th>Motivo</th>
          <th>Fecha inicio</th>
          <th>Fecha término</th>
          <th>Estado</th>
          <th>Acciones</th>
        </tr>
      </thead>
      <tbody>
        {desviaciones.map((desviacion, index) => (
          <tr key={index}>
            <td>{desviacion.codigoOP}</td>
            <td>{desviacion.galpon}</td>
            <td>{desviacion.proceso}</td>
            <td>{desviacion.dimension}</td>
            <td>{desviacion.motivo}</td>
            <td>{desviacion.fechaInicio}</td>
            <td>{desviacion.fechaTermino}</td>
            <td>{desviacion.estado}</td>
            <td>
              <button
                className="btn btn-primary"
                onClick={() => submitForm(desviacion._id)}
                disabled={desviacion.estado === "SOLUCIONADA"}
              >
                Solucionar
              </button>
            </td>
          </tr>
        ))}
      </tbody>
    </TableDesviacion>
  );
};

export default DesviacionesPlanificacionList;
