import { SelectOption } from "../../models/optionsFilters/Select";
import TipoGalpon from "../../models/planificacionesERP/TipoGalpon";

const useGalponPlanificacion = (): SelectOption<TipoGalpon>[] => {
  return [
    { label: "G1", value: { galpon: "G1" } },
    { label: "G2", value: { galpon: "G2" } },
    { label: "G3", value: { galpon: "G3" } },
    { label: "G4", value: { galpon: "G4" } },
  ];
};

export default useGalponPlanificacion;
