import cogoToast from "cogo-toast";
import { ReactElement, useState } from "react";
import { Button, Col, Modal } from "react-bootstrap";
import { useHistory } from "react-router-dom";
import { postUser } from "../../api/UsersApi";
import useFormState from "../../hooks/useFormState";
import useEstadoUsuarioOptions from "../../hooks/users/useEstadoUsuario";
import useRolUsuarioOptions from "../../hooks/users/useRolUsuario";
import { UserForm } from "../../models/users/Users";
import { formatUser, getEmptyUserForm } from "../../utils/users";
import UserFormComponent from "./UserFormComponent";

interface NewUserProps {
    userPermission: boolean
}

const NewUser = ({ userPermission }: NewUserProps): ReactElement => {

    const formState = useFormState(getEmptyUserForm());
    const estadoUsuarioOptions = useEstadoUsuarioOptions();
    const rolesUsuarioOptions = useRolUsuarioOptions();
    const history = useHistory();

    const [show, setShow] = useState(false);
    const handleClose = () => {
        formState.reset();
        setShow(false);
    }
    const handleShow = () => setShow(true);

    const submitForm = (userForm: UserForm) => {
        postUser(formatUser(userForm))
            .then((response) => {
                history.push(`/users/${response.data.rut}`);
                cogoToast.success('Usuario creado');
            })
            .catch(() => {
                cogoToast.error('No fue posible crear el usuario');
                formState.setSubmitting(false);
            });
    }

    return (
        <>
            {userPermission &&
                <div className = "filters-buttons">
                    <Button onClick = {handleShow}>
                        Nuevo Usuario
                    </Button>
                </div>
            }
            {show && 
                (<Modal show = {show} onHide = {handleClose}>
                    <Modal.Header>
                        <Col className = "text-center">
                            <Modal.Title>Nuevo Usuario</Modal.Title>
                        </Col>
                    </Modal.Header>
                    <Modal.Body>
                        <UserFormComponent
                            formState = {formState}
                            submitForm = {submitForm}
                            onCancel = {handleClose}
                            estadoUsuarioOptions = {estadoUsuarioOptions}
                            rolesUsuarioOptions = {rolesUsuarioOptions}
                        />
                    </Modal.Body>
                </Modal>)
            }
        </>
    );
}

export default NewUser;