import { ReactElement, useEffect, useState } from "react";
import useRolUsuarioOptions from "../../hooks/users/useRolUsuario";
import { Destinatario } from "../../models/Email";
import { Persona } from "../../models/users/Users";
import EditPersonasComponent from "./EditPersonasComponent";

interface Props {
    getDestinatarios: (changeIsLoadingToFalse: () => void, changeLoadFailedToTrue: () => void) => void,
    postDestinatarios: (personas: Destinatario[], hideModal: () => void, setStateToFalse: () => void) => void,
    deleteDestinatario: (personaEmail: string, hideModal: () => void, setStateToFalse: () => void) => void,
    userPermission: boolean,
    addresses?: Persona[],
    copiedAddresses?: Persona[],
    isNotCard?: boolean
}

const DestinatariosCorreo = ({ addresses, copiedAddresses, getDestinatarios, postDestinatarios, deleteDestinatario, isNotCard, userPermission }: Props): ReactElement => {

    const rolUsuarioOptions = useRolUsuarioOptions('emails');
    const paraTitle = "Para (to)";
    const copiaCarbonTitle = "Copia de carbon (cc)";
    const [ isLoadingAddresses, setIsLoadingAddresses ] = useState<boolean>(true);
    const [ addressLoadFailed, setAddressLoadFailed ] = useState<boolean>(false);
    const getHtmlContent = (title: string): ReactElement => (
        <EditPersonasComponent
            title = {title}
            subjectsName = "Destinatarios"
            isNotCard = {isNotCard}
            userPermission = {userPermission}
            rolUsuarioOptions = {rolUsuarioOptions}
            isLoadingAddresses = {isLoadingAddresses}
            addressLoadFailed = {addressLoadFailed}
            isEmailRequired
            // addPersonas = {((): (personas: Persona[], hideModal: () => void, setStateToFalse: () => void) => void => {
            //     let type = '';
            //     if (title ===  "Para (to):") {
            //         type = 'PARA';
            //     } else if (title === "Copia de carbon (cc):") {
            //         type = 'COPIACARBON';
            //     }
            //     return (personas: Persona[], hideModal: () => void, setStateToFalse: () => void) => postDestinatarios(
            //         personas.map((persona) => ({ name: persona.name, email: persona.email, type: type })),
            //         hideModal,
            //         setStateToFalse
            //     );
            // })()}
            addPersonas = {(personas: Persona[], hideModal: () => void, setStateToFalse: () => void) => postDestinatarios(
                personas.map((persona) => ({
                    name: persona.name,
                    email: persona.email,
                    type: title ===  paraTitle ? 'PARA' : title === copiaCarbonTitle ? 'COPIACARBON' : ''
                })),
                hideModal,
                setStateToFalse
            )}
            deletePersona = {(persona: Persona, hideModal: () => void, setStateToFalse: () => void) => deleteDestinatario(persona.email, hideModal, setStateToFalse)}
            // rolPermission = {}
            currentPersonas = {((): Persona[] => {
                    let response: Persona[] = []
                    if (title === paraTitle && addresses) {
                        response = addresses;
                    } else if (title === copiaCarbonTitle && copiedAddresses) {
                        response = copiedAddresses;
                    }
                    return response;
                })()
            }
            existingPersonas = {((): Persona[] => {
                    let response: Persona[] = [];
                    if (addresses) {
                        response = addresses;
                        if (copiedAddresses) {
                            response = response.concat(copiedAddresses);
                        }
                    }
                    return response;
                })()
            }
        />
    )
    
    useEffect(() => getDestinatarios(() => setIsLoadingAddresses(false), () => setAddressLoadFailed(true)), []);

    return (
        <>
            {getHtmlContent(paraTitle)}
            {getHtmlContent(copiaCarbonTitle)}
        </>
        // <DestinatariosCorreo
        //     getAddresses = {getDestinatariosApertura}
        //     deleteDestinatario = {deleteDestinatarioApertura}
        //     postDestinatario = {postDestinatarioApertura}
        //     addresses = {addresses}
        //     copiedAddresses = {copiedAddresses}
        //     defaultRolesElements = {{ roles: ['CLIENTE'], isOmitRoles: true }}
        // />
    );
}

export default DestinatariosCorreo;