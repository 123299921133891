import { ReactElement, ReactNode } from "react";
import { Table as BootstrapTable } from "react-bootstrap";
import "../form/table/table.scss";
import PaginationComponent from "../form/table/PaginationComponent";

interface TableProps {
  stripped?: boolean;
  hover?: boolean;
  pages?: number;
  activePage?: number;
  onPageSelect?: (page: number) => void;
  children: ReactNode;
}

const TableDesviacion = ({
  stripped,
  hover,
  pages,
  activePage,
  onPageSelect,
  children,
}: TableProps): ReactElement => {
  return (
    <>
      <div className="scrollable-table-container">
        <BootstrapTable striped={stripped} bordered hover={hover} responsive>
          {children}
        </BootstrapTable>
      </div>
      {!!pages && (
        <PaginationComponent
          pageCount={pages}
          activePage={activePage !== undefined ? activePage : 1}
          onChange={onPageSelect || (() => {})}
        />
      )}
    </>
  );
};

export default TableDesviacion;
