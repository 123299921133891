import { ReactElement, useContext, useEffect, useState } from "react";
import { Card } from "react-bootstrap";
import NewUser from "../../components/users/NewUser";
import UsersFiltersComponent from "../../components/users/UsersFiltersComponent";
import UsersList from "../../components/users/UsersList";
import AuthContext from "../../contexts/AuthContext";
import useBreadcrumb from "../../hooks/useBreadcrumb";
import useEstadoUsuarioOptions from "../../hooks/users/useEstadoUsuario";
import useRolUsuarioOptions from "../../hooks/users/useRolUsuario";
import { UsersFilters } from "../../models/users/Users";
import { getEmptyUsersFilters, getUserPermissionFunction } from "../../utils/users";
import useDisponibilidadOptions from "../../hooks/users/useDisponibilidad";

const UsersPage = (): ReactElement => {
    const { userRoles } = useContext(AuthContext);
    const [usersFilters, setUsersFilters] = useState<UsersFilters>(getEmptyUsersFilters());
    const breadcrumb = useBreadcrumb();
    const estadoUsuarioOptions = useEstadoUsuarioOptions();
    const rolUsuarioOptions = useRolUsuarioOptions();
    const disponibilidadOptions = useDisponibilidadOptions();

    const changeUsersFilters = (filters: UsersFilters) => {
        setUsersFilters(filters);
    }
    
    useEffect(() => {
        breadcrumb.setItems([
            {
                label: 'Usuarios',
                inactive: true,
            },
        ]);
    }, [breadcrumb]);

    return (
        <>
            <Card className = "cards-container">
                <Card.Body className = "cards-body-groups">
                    <div className = "cards-header">
                        <UsersFiltersComponent
                            usersFilters = {usersFilters}
                            onFiltersChange = {setUsersFilters}
                            estadoUsuariosOptions = {estadoUsuarioOptions}
                            rolUsuariosOptions = {rolUsuarioOptions}
                            disponibilidadOptions = {disponibilidadOptions}
                        />
                        <NewUser
                            userPermission = {getUserPermissionFunction(userRoles)({ roles: ['ADMINISTRADOR'] })}
                        />
                    </div>
                    <UsersList
                        usersFilters = {usersFilters}
                        setUsersFilters = {changeUsersFilters}
                    />
                </Card.Body>
            </Card>
        </>
    );
}

export default UsersPage;