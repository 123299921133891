import { ModeloComponenteEvaluacionOrReparacionElements } from "../../../models/EvaluacionesReparaciones";
import informeEvaluacion5GEB25 from "../../evaluaciones/motoresTraccion/informe5GEB25";
import informeReparacion5GEB25 from "../../reparaciones/motoresTraccion/informe5GEB25";
import { motorTraccion5GEB25DiferenciaBacklashCalculations, motorTraccion5GEB25InterferenciaCalculations, motorTraccion5GEB25RegistroABCalculations, motorTraccion5GEB25TableCalculations } from "../tablesCalculations/motoresTraccion";

const motoresTraccionElementsList: ModeloComponenteEvaluacionOrReparacionElements[] = [
    {
        modeloComponente: '5GEB25',
        getInformeEvaluacion: informeEvaluacion5GEB25,
        getInformeReparacion: informeReparacion5GEB25,
        getCalculations: motorTraccion5GEB25TableCalculations,
        tablesThatNeedSharedValues: [
            { stageName: 'Metrología', taskName: 'Metrología tubo de torque y anillo flinger', tableName: 'Interferencia', calculation: motorTraccion5GEB25InterferenciaCalculations },
            { stageName: 'Armado de tubo torque', taskName: 'Interferencias tubo de torque y anillo flinger', tableName: 'Interferencia', calculation: motorTraccion5GEB25InterferenciaCalculations },
            { stageName: 'Desarme inicial', taskName: 'Medir juego backlash planetarios', tableName: 'Diferencias de backlash', calculation: motorTraccion5GEB25DiferenciaBacklashCalculations },
            { stageName: 'Instalación tubo de torque', taskName: 'Registro medida A, B y C', tableName: 'Registro A-B', calculation: motorTraccion5GEB25RegistroABCalculations },
            { stageName: 'Instalación tapa de planetarios', taskName: 'Medición de backlash', tableName: 'Diferencias de backlash', calculation: motorTraccion5GEB25DiferenciaBacklashCalculations }
        ]
    },
];

export default motoresTraccionElementsList;