import {
  DesviacionesFilters,
  DesviacionForm,
  DesviacionRequestBody,
  DesviacionSolucionada,
} from "../models/desviaciones/Desviaciones";

export const getEmptyDesviacionesFilters = (): DesviacionesFilters => ({
  proceso: "",
  codigoOP: "",
  dimension: "",
  fechaInicio: "",
  fechaTermino: "",
  galpon: "",
  estado: "",
  page: 1,
});

export const getEmptyDesviacionForm = (): DesviacionForm => ({
  dimension: { label: "", value: { dimension: "" } },
  motivo: { label: "", value: { motivo: "" } },
});

export const formatDesviacionRequestBody = (
  desviacionForm: DesviacionForm,
  planificacionId?: string,
  codigo?: string,
  galpon?: string
): DesviacionRequestBody => ({
  planificacionId: planificacionId ? planificacionId : undefined,
  dimension: desviacionForm.dimension?.value.dimension,
  motivo: desviacionForm.motivo?.value.motivo,
  galpon: galpon,
  codigoOP: codigo,
});

export const formatDesviacion = (
  id: string,
  desviacionForm: DesviacionSolucionada
): DesviacionRequestBody => ({
  _id: id,
  dimension: desviacionForm.dimension?.value.dimension || "",
  motivo: desviacionForm.motivo?.value.motivo || "",
  fechaTermino: desviacionForm.fechaTermino,
  estado: desviacionForm.estado,
});
