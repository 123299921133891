// PlanificacionesIndicators.tsx
import React from "react";
import { Card, Row, Col } from "react-bootstrap";
import { PlanificacionTabla } from "../../models/planificacionesERP/PlanificacionERP";

interface PlanificacionesIndicatorsProps {
  planificaciones: PlanificacionTabla[];
}

const PlanificacionesIndicadores = ({
  planificaciones,
}: PlanificacionesIndicatorsProps) => {
  const estados = ["TERMINADO", "EN PROCESO", "DETENIDO"];

  const counts = estados.reduce((acc, estado) => {
    acc[estado] = planificaciones.filter(
      (planificacion) => planificacion.estado.toUpperCase() === estado
    ).length;
    return acc;
  }, {} as Record<string, number>);

  const totalPlanificaciones = planificaciones.length;

  return (
    <Row className="mb-4">
      <Col md={3} className="mb-3">
        <Card>
          <Card.Body>
            <Card.Title className="text-center">TOTAL</Card.Title>
            <Card.Text className="text-center display-6">
              {totalPlanificaciones}
            </Card.Text>
          </Card.Body>
        </Card>
      </Col>
      {estados.map((estado) => (
        <Col key={estado} md={3} className="mb-3">
          <Card>
            <Card.Body>
              <Card.Title className="text-center">{estado}</Card.Title>
              <Card.Text className="text-center display-6">
                {counts[estado]}
              </Card.Text>
            </Card.Body>
          </Card>
        </Col>
      ))}
    </Row>
  );
};

export default PlanificacionesIndicadores;
