import cogoToast from "cogo-toast"
import { ReactElement, useEffect, useState } from "react"
import { getOrdenProceso } from "../../api/OrdenesProcesoApi"
import { OrdenProceso } from "../../models/estatusGeneral/OrdenProceso"
import { EvaluacionOrReparacionComponenteEtapa, TareaInput } from "../../models/EvaluacionesReparaciones"
import DatePicker from "react-datepicker"
import { format, parse } from "date-fns"

interface Props {
    nombreTarea: string,
    tareaInput: TareaInput,
    codigoOP: string,
    changeEvaluacionReparacionComponenteEtapa: (changeEvaluacionReparacionComponenteEtapa: EvaluacionOrReparacionComponenteEtapa) => void,
    evaluacionReparacionComponenteEtapa: EvaluacionOrReparacionComponenteEtapa,
    isNotEditable: boolean,
    requireElementTitle: boolean
}

const EvaluacionReparacionComponenteDetallesInputs = ({ nombreTarea, tareaInput, codigoOP, changeEvaluacionReparacionComponenteEtapa, evaluacionReparacionComponenteEtapa, isNotEditable, requireElementTitle }: Props): ReactElement => {

    const [ ordenProceso, setOrdenProceso ] = useState<OrdenProceso>();
    const [ isValidInput, setIsValidInput ] = useState<boolean>();

    const getTareaMessageElement = (): string => {
        let response = ''
        if (nombreTarea === 'Confirmar N/S') {
            response = 'número de serie del componente';
        } else if (nombreTarea === 'Confirmar OP') {
            response = 'código de OP';
        }
        return response;
    }

    const checkValidInput = (value: string, op?: OrdenProceso) => {
        const checkValueInOP = (currentOP: OrdenProceso) => {
            if (value !== '') {
                if (nombreTarea === 'Confirmar N/S') {
                    if (currentOP.recepcion?.numeroSerieComponente && value.toUpperCase() === currentOP.recepcion?.numeroSerieComponente) {
                        setIsValidInput(true);
                    } else {
                        setIsValidInput(false);
                    }
                } else if (nombreTarea === 'Confirmar OP') {
                    if (value === currentOP.codigoOP) {
                        setIsValidInput(true);
                    } else {
                        setIsValidInput(false);
                    }
                }
            }
        }
        if (ordenProceso) {
            checkValueInOP(ordenProceso);
        } else if (op) {
            checkValueInOP(op);
        }
    }
    
    const inputOnChange = (value: string) => {
        const newEvaluacionComponenteEtapa = evaluacionReparacionComponenteEtapa;
        for (let i = 0; i < newEvaluacionComponenteEtapa.tareas.length; i++) {
            if (newEvaluacionComponenteEtapa.tareas[i].nombre === nombreTarea) {
                const input = newEvaluacionComponenteEtapa.tareas[i].inputs;
                if (input) {
                    for (let e = 0; e < input.length; e++) {
                        if (input[e].name === tareaInput.name) {
                            input[e].value = value;
                            break;
                        }
                    }
                }
                newEvaluacionComponenteEtapa.tareas[i].inputs = input;
                break;
            }
        }
        changeEvaluacionReparacionComponenteEtapa(newEvaluacionComponenteEtapa);
        checkValidInput(value);
    }

    useEffect(() => {
        if (nombreTarea === 'Confirmar N/S' || nombreTarea === 'Confirmar OP') {
            getOrdenProceso(codigoOP)
                .then((response) => {
                    if (response.data) {
                        setOrdenProceso(response.data);
                        checkValidInput(tareaInput.value, response.data);
                    }
                })
                .catch(() => {
                    cogoToast.error(
                        `No fue posible obtener la orden de proceso para validar el ${getTareaMessageElement()}`
                    );
                })
        }
    }, [codigoOP]);

    return (
        <>
            {tareaInput.name !== '1' && requireElementTitle && (<u><h5>{tareaInput.name}</h5></u>)}
            {!tareaInput.isDate ?
                <>
                    <input
                        className = "form-control"
                        defaultValue = {tareaInput.value}
                        onChange = {(value) => inputOnChange(value.target.value)}
                        disabled = {isNotEditable}
                    />
                    {isValidInput !== undefined && 
                    (<div>
                        <h5>{!isValidInput ? `El ${getTareaMessageElement()} no corresponde con la información anterior` : `El ${getTareaMessageElement()} es correcto`}</h5>
                    </div>)}
                </>
            :
                <DatePicker
                    className = {'form-control centerTextCell'}
                    selected = {tareaInput.value ? parse(tareaInput.value, 'dd/MM/yyyy', new Date()) : undefined}
                    onChange = {(date) => inputOnChange(date ? format(date, 'dd/MM/yyyy') : '')}
                    disabled = {isNotEditable}
                    isClearable = {true}
                    locale = {"es"}
                />
            }
        </>
    );
}

export default EvaluacionReparacionComponenteDetallesInputs;