import React from "react";
import ReactDOM from "react-dom";
import App from './App';
import dotenv from 'dotenv';
import './assets/styles/app.scss';
import 'font-awesome/css/font-awesome.min.css';
import 'bootstrap/dist/css/bootstrap.min.css';
import 'react-tabs/style/react-tabs.scss';

dotenv.config();

ReactDOM.render(
    <React.StrictMode>
      <App />
    </React.StrictMode>,
    document.getElementById('root'),
);