import { addTableRow, ColumnWithValue, getTableCellValueWithUnits, pdfElements } from "../../pdfElements";

export const pruebaLineasFrenosItem = (informeElements: pdfElements) => {
    const tarea = informeElements.etapas?.find((etapa) => etapa.nombre === (informeElements.isReparacion ? 'Pre armado general' : 'Prueba líneas de freno'))
        ?.tareas.find((tarea) => tarea.nombre === (informeElements.isReparacion ? 'Prueba de estanqueidad a 2500 PSI' : 'Prueba de estanqueidad a cañerías de frenos'));
    const pruebaEstanqueidadCañeríasFrenosValuesTable = tarea?.tables?.find((table) => table.name === '1');
    const getPruebaEstanqueidadCañeríasFrenosValues = (columnsNameWithValue: ColumnWithValue[], targetColumnName: string, withUnits?: boolean) =>
        getTableCellValueWithUnits(columnsNameWithValue, targetColumnName, tarea?.applyElements.apply, pruebaEstanqueidadCañeríasFrenosValuesTable, withUnits ? 'PSI' : undefined);
    const pruebaEstanqueidadCañeríasFrenosValuesPresion = ((): string => {
        let response = getPruebaEstanqueidadCañeríasFrenosValues([{ column: 'N° Línea', value: '1'}], 'Presión de prueba (PSI)', true);
        if (response === 'N/A') {
            response = '2500 PSI';
        }
        return response;
    })();
    const rows: addTableRow[][] = [
        [
            { text: 'N° Línea', color: informeElements.titleCellsFillColor, bold: true },
            { text: 'Presión de prueba', color: informeElements.titleCellsFillColor, bold: true },
            { text: 'Resultado', color: informeElements.titleCellsFillColor, bold: true },
        ],
        [
            { text: '1', bold: true },
            { text: pruebaEstanqueidadCañeríasFrenosValuesPresion },
            { text: getPruebaEstanqueidadCañeríasFrenosValues([{ column: 'N° Línea', value: '1' }], 'Resultado') },
        ],
        [
            { text: '2', bold: true },
            { text: pruebaEstanqueidadCañeríasFrenosValuesPresion },
            { text: getPruebaEstanqueidadCañeríasFrenosValues([{ column: 'N° Línea', value: '2' }], 'Resultado') },
        ],
        [
            { text: '3', bold: true },
            { text: pruebaEstanqueidadCañeríasFrenosValuesPresion },
            { text: getPruebaEstanqueidadCañeríasFrenosValues([{ column: 'N° Línea', value: '3' }], 'Resultado') },
        ],
        [
            { text: '4', bold: true },
            { text: pruebaEstanqueidadCañeríasFrenosValuesPresion },
            { text: getPruebaEstanqueidadCañeríasFrenosValues([{ column: 'N° Línea', value: '4' }], 'Resultado') },
        ],
    ];
    if (informeElements.isReparacion) {
        rows[0].push({ text: 'Falla', color: informeElements.titleCellsFillColor, bold: true });
        rows[1].push({ text: getPruebaEstanqueidadCañeríasFrenosValues([{ column: 'N° Línea', value: '1' }], 'Daños') });
        rows[2].push({ text: getPruebaEstanqueidadCañeríasFrenosValues([{ column: 'N° Línea', value: '2' }], 'Daños') });
        rows[3].push({ text: getPruebaEstanqueidadCañeríasFrenosValues([{ column: 'N° Línea', value: '3' }], 'Daños') });
        rows[4].push({ text: getPruebaEstanqueidadCañeríasFrenosValues([{ column: 'N° Línea', value: '4' }], 'Daños') });
    }
    informeElements.docSubtitleRectangle('PRUEBAS DE LÍNEAS DE FRENOS');
    informeElements.addParagraph(`Se revisa líneas de freno aplicando ${pruebaEstanqueidadCañeríasFrenosValuesPresion} de presión hidráulica por 1 min para detectar fisuras. Los resultados son:`);
    informeElements.addTable([{ rows: rows }]);
    informeElements.rectangleObs(
        'Observaciones pruebas de líneas de frenos',
        tarea?.observations ? [tarea?.observations] : undefined,
        false,
        informeElements.cellsHeight * 6
    );
}