import { ReactElement } from "react";
import { FormState } from "../../hooks/useFormState";
import { AreaForm } from "../../models/galpones/Galpon";
import { Form, Row } from "react-bootstrap";
import { Input } from "../form/input/Input";
import FormButtons from "../form/buttons/FormButtons";

interface GalponFormComponentProps {
  formState: FormState<AreaForm>;
  submitForm: (areaForm: AreaForm) => void;
  onDelete?: () => void;
  onCancel: () => void;
  isCreating?: boolean;
}

const AreaFormComponent = ({
  formState,
  submitForm,
  onDelete,
  onCancel,
  isCreating,
}: GalponFormComponentProps): ReactElement => {
  const {
    value: areaForm,
    handleFieldChange,
    areValidationsEnabled,
    setAreValidationsEnabled,
    isSubmitting,
    setSubmitting,
    isConfirmingDelete,
    setConfirmingDelete,
  } = formState;

  const isFormInvalid = !areaForm.nombre;

  const handleSubmitClick = (
    event: React.MouseEvent<HTMLElement, MouseEvent>
  ) => {
    event.preventDefault();
    setAreValidationsEnabled(true);
    if (isFormInvalid) return;
    setSubmitting(true);
    setAreValidationsEnabled(false);
    submitForm(areaForm);
  };

  const handleDeleteClick = () => setConfirmingDelete(true);
  const cancelDelete = () => setConfirmingDelete(false);

  // const isEditting = (): boolean => {
  //   if (isCreating) {
  //     return isSubmitting;
  //   } else {
  //     return true;
  //   }
  // };

  return (
    <>
      <Form>
        <Row>
          <Input
            title="Nombre"
            name="nombre"
            value={areaForm.nombre}
            type="text"
            onChangeWithName={handleFieldChange}
            invalid={areValidationsEnabled && !areaForm.nombre}
            invalidText="Ingrese nombre"
            placeholder="Obligatorio"
          />
        </Row>
        <FormButtons
          submitButtonContent="Guardar"
          onSubmitClick={handleSubmitClick}
          onCancelClick={onCancel}
          isConfirmingDelete={isConfirmingDelete}
          onDeleteClick={onDelete ? handleDeleteClick : undefined}
          onDeleteConfirm={onDelete}
          onCancelDelete={cancelDelete}
          deleteButtonContent="Eliminar"
          isDisabled={isSubmitting}
          isLoading={isSubmitting}
          isFormInvalid={isFormInvalid}
          areValidationsEnabled={areValidationsEnabled}
        />
      </Form>
    </>
  );
};

export default AreaFormComponent;
