import axios, { AxiosPromise } from "axios";
import { EvaluacionOrReparacionComponenteEtapaInforme, EvaluacionOrReparacionComponenteEtapa, EvaluacionOrReparacionComponenteResumenEtapa, EvaluacionOrReparacionComponenteTarea } from "../models/EvaluacionesReparaciones";
import { Archivo, ProcesoPhotoRequest } from "../models/Archivos";

export default class DetallesEvaluacionReparacionApi {

    codigoOP: string;
    etapaProceso: string;

    constructor(codigoOP: string, isReparacion?: boolean) {
        this.codigoOP = codigoOP;
        this.etapaProceso = isReparacion ? 'reparacion' : 'evaluacion';
    }

    getEtapaProceso = (): string => this.etapaProceso;

    getEvaluacionOrReparacionComponenteResumen(): AxiosPromise<EvaluacionOrReparacionComponenteResumenEtapa[] | void> {
        return axios.get(`api/evaluacionesReparaciones/ordenProceso/${this.etapaProceso}/resumen/${this.codigoOP}`);
    }
    
    getEvaluacionOrReparacionComponenteEtapa(etapa: string): AxiosPromise<EvaluacionOrReparacionComponenteEtapa> {
        return axios.get(`api/evaluacionesReparaciones/ordenProceso/${this.etapaProceso}/etapa/${this.codigoOP}`, { params: { nombreEtapa: etapa } });
    }
    
    putEvaluacionOrReparacionComponenteEtapaTarea(tarea: EvaluacionOrReparacionComponenteTarea): AxiosPromise<EvaluacionOrReparacionComponenteTarea> {
        return axios.put(`api/evaluacionesReparaciones/ordenProceso/${this.etapaProceso}/etapa/${this.codigoOP}`, tarea);
    }
    
    putEvaluacionOrReparacionComponenteResumen(resumen: EvaluacionOrReparacionComponenteResumenEtapa): AxiosPromise<EvaluacionOrReparacionComponenteResumenEtapa | void> {
        return axios.put(`api/evaluacionesReparaciones/ordenProceso/${this.etapaProceso}/resumen/${this.codigoOP}`, resumen)
    }
    
    getEvaluacionOrReparacionComponenteEtapas(): AxiosPromise<EvaluacionOrReparacionComponenteEtapaInforme[]> {
        return axios.get(`api/evaluacionesReparaciones/ordenProceso/${this.etapaProceso}/etapas/${this.codigoOP}`);
    }

    postImagenProceso(requestBody: ProcesoPhotoRequest): AxiosPromise<Archivo> {
        return axios.post(`api/documentos/fotos/evaluacionReparacion/ordenProceso/${this.codigoOP}`, { ...requestBody, codigoOP: this.codigoOP, nombreProceso: this.etapaProceso });
    }
    
    deleteImagenProceso(requestBody: ProcesoPhotoRequest): AxiosPromise<void> {
        return axios.delete(`api/documentos/fotos/evaluacionReparacion/ordenProceso/${this.codigoOP}`, { params: { ...requestBody, codigoOP: this.codigoOP, nombreProceso: this.etapaProceso } });
    }
    
    getImagenProceso(requestBody: ProcesoPhotoRequest): AxiosPromise<Archivo> {
        return axios.get(`api/documentos/fotos/evaluacionReparacion/ordenProceso/${this.codigoOP}`, { params: { ...requestBody, codigoOP: this.codigoOP, nombreProceso: this.etapaProceso } });
    }

    getEvaluacionOrReparacionComponenteParticipantesGenerales(): AxiosPromise<EvaluacionOrReparacionComponenteResumenEtapa | void> {
        return axios.get(`api/evaluacionesReparaciones/ordenProceso/${this.etapaProceso}/resumen/participantesGenerales/${this.codigoOP}`);
    }

    putEvaluacionOrReparacionComponenteParticipantesGenerales(resumen: EvaluacionOrReparacionComponenteResumenEtapa): AxiosPromise<EvaluacionOrReparacionComponenteResumenEtapa> {
        return axios.put(`api/evaluacionesReparaciones/ordenProceso/${this.etapaProceso}/resumen/participantesGenerales/${this.codigoOP}`, resumen)
    }

}