import cogoToast from "cogo-toast";
import { ReactElement, useState } from "react";
import { Button, Card, Col, Modal, Row, Spinner } from "react-bootstrap";
// import { cambioEstadoOPs } from "../../api/OrdenesProcesoApi";
import { putReparacion, putReparacionRealizadoLiberadoPor } from "../../api/ReparacionesApi";
import useFormState from "../../hooks/useFormState";
import useTOTOptions from "../../hooks/useTOTOptions";
import { OrdenProceso } from "../../models/estatusGeneral/OrdenProceso";
import { Reparacion, ReparacionForm } from "../../models/Reparaciones";
// import { formatUpdateOrdenesProcesoEstado } from "../../utils/estatusGeneral";
import { formatReparacionForm, formatReparacionRequestBody } from "../../utils/reparaciones/reparaciones";
import DocumentosReparacion from "../files/DocumentosReparacion";
import ReparacionFormComponent from "./ReparacionFormComponent";
// import { AcceptedRolesElements, AcceptedUsersElements } from "../../models/users/Users";
import CorreoEntregaEvaluacionReparacion from "../evaluacionesReparaciones/CorreoEntregaEvaluacionReparacion";
import { EvaluacionOrReparacionComponenteResumenEtapa } from "../../models/EvaluacionesReparaciones";
import { AcceptedRolesElements, AcceptedUsersElements, Persona } from "../../models/users/Users";
import DetallesEvaluacionReparacionApi from "../../api/EvaluacionesReparacionesApi";
import EvaluacionReparacionComponenteResumen from "../evaluacionesReparaciones/EvaluacionReparacionComponenteResumen";
import { putEvaluacionRealizadoPor } from "../../api/EvaluacionesApi";
import { canGenerateInforme, getRequestsAndGenerateInforme, isAprobacionTarea, titleWithoutIndex } from "../../utils/evaluacionesReparaciones/evaluacionesReparaciones";
import { useHistory } from "react-router-dom";

interface Props {
    reparacion: Reparacion,
    changeReparacion: (reparacion: Reparacion) => void,
    ordenProceso: OrdenProceso,
    userPermissionFunction: (acceptedRolesElements?: AcceptedRolesElements, acceptedUsersElements?: AcceptedUsersElements) => boolean,
    userData: { userName: string, userEmail: string, userRoles: string[] },
    evaulacionReparacionComponenteResumenEtapas?: EvaluacionOrReparacionComponenteResumenEtapa[],
    detallesApi: DetallesEvaluacionReparacionApi,
    participantesGenerales: Persona[],
    changeParticipantesGenerales: (resumen: EvaluacionOrReparacionComponenteResumenEtapa) => void
}

const ReparacionDetails = ({
    reparacion, changeReparacion, userPermissionFunction, ordenProceso, userData, evaulacionReparacionComponenteResumenEtapas, detallesApi, participantesGenerales, changeParticipantesGenerales
}: Props): ReactElement => {

    const history = useHistory();
    const [ isDownloading, setIsDownloading ] = useState<boolean>(false);
    const editPermission = userPermissionFunction({ roles: ['ADMINISTRADOR', 'JEFETALLER', 'CALIDAD', 'SUPERVISOR'] }, reparacion.participantesRut ? { acceptedUsers: reparacion.participantesRut } : undefined);
    const formState = useFormState(formatReparacionForm(reparacion));
    const totOptions = useTOTOptions();
    const [ isBusy, setIsBusy ] = useState<boolean>(false);
    const [ show, setShow ] = useState(false);
    const isNotReparacionAprobada = !!evaulacionReparacionComponenteResumenEtapas &&
        !evaulacionReparacionComponenteResumenEtapas.find((resumenEtapa) => titleWithoutIndex(resumenEtapa.nombreEtapa) === 'Informe de reparación')
            ?.tareas?.find((resumenEtapaTarea) => isAprobacionTarea(resumenEtapaTarea.nombreTarea))
                ?.valores.find((resumenEtapaTareaValor) => resumenEtapaTareaValor.columna === 'Fecha')?.valor;
    const handleShowEdit = () => setShow(true);
    const handleCloseEdit = () => {
        formState.reset();
        setShow(false);
    };
    const changeStates = (state: boolean) => {
        setIsDownloading(state);
        setIsBusy(state);
    };

    const submitForm = (reparacionForm: ReparacionForm) => {
        putReparacion(formatReparacionRequestBody(reparacionForm), ordenProceso.codigoOP)
            .then((response) => {
                if (response.data.fechaInicial) {
                    formState.setSubmitting(false);
                    formState.setForm(reparacionForm);
                    setShow(false);
                    changeReparacion(response.data);
                    cogoToast.success('Reparación Editada');
                } else {
                    // cogoToast.error(response.data);
                    cogoToast.error('No fue posible editar la reparación del componente');
                    formState.setSubmitting(false);
                }
            })
            .catch(() => {
                // cogoToast.error(error);
                cogoToast.error('No fue posible editar la reparación');
            })
    }

    /*
    const changeOPState = () => {
        if (ordenProceso.estado === 'EVALUADA') {
            cambioEstadoOPs(formatUpdateOrdenesProcesoEstado([ordenProceso], 'REPARADA'))
            .then(() =>
                cogoToast.success('La orden de proceso cambió su estado a "REPARADA"')
            ).catch(() => {
                // cogoToast.error(error);
                cogoToast.error('No fue posible cambiar el estado de las órdenes de proceso');
            })
        }
    }
    */

    return (
        <>
            <Card className = "cards-container">
                <Card.Header className = "cards-header">
                    <div className = "body-title">
                        Reparación de componente
                    </div>
                    {isDownloading ?
                        <div>Generando informe ... <Spinner animation="border" size="sm" /></div>
                    :
                        <div className = "buttons-group">
                            {editPermission &&
                                <Button onClick = {handleShowEdit} disabled = {isBusy}>{isBusy ? <Spinner animation="border" size="sm" /> : 'Editar'}</Button>
                            }
                            {userPermissionFunction({ roles: ['ADMINISTRADOR', 'JEFETALLER', 'CALIDAD', 'SUPERVISOR'] }) &&
                                <Button onClick = {() => history.push(`/planificacionesERP/${reparacion.planificacionId}`)} disabled = {!reparacion.planificacionId || isBusy}>{isBusy ? <Spinner animation="border" size="sm" /> : 'Ver planificación'}</Button>
                            }
                            {editPermission &&
                                <CorreoEntregaEvaluacionReparacion
                                    userData = {userData}
                                    ordenProceso = {ordenProceso}
                                    isBusy = {isBusy}
                                    setIsBusy = {(state: boolean) => setIsBusy(state)}
                                    // doesGenerateFile = {doesGenerateFile}
                                    // generarInforme = {generarInforme}
                                    // isProcesoEtapaFinished = {doesGenerateFile ? isResumenComplete() : !!reparacion.fechaFinal}
                                    fechaFinal = {reparacion.fechaFinal}
                                    nombreEtapaProceso = "reparación"
                                    // getFiles = {async (handleDocumentosResponse: (responseData?: Archivo[]) => void) => await getDocumentosReparacion(ordenProceso.codigoOP)
                                    //     .then((response) => handleDocumentosResponse(response.data))
                                    //     .catch(() => cogoToast.error('No fue posible obtener algunos documentos de evaluación'))
                                    // }
                                    userPermission = {userPermissionFunction({ roles: ['ADMINISTRADOR', 'JEFETALLER', 'CALIDAD', 'SUPERVISOR'] })}
                                    evaluacionUpdate = {async (realizadoPor?: string): Promise<void> => {
                                        await putEvaluacionRealizadoPor(ordenProceso.codigoOP, realizadoPor, undefined, undefined, true)
                                            .then().catch(() => cogoToast.error('No fue posible actualizar la evaluación'))
                                    }}
                                    reparacionUpdate = {async (fechaLiberacion: string, realizadoPor?: string, liberadoPor?: string, notas?: string): Promise<void> => {
                                        await putReparacionRealizadoLiberadoPor(ordenProceso.codigoOP, fechaLiberacion, realizadoPor, liberadoPor, notas)
                                            .then().catch(() => cogoToast.error('No fue posible actualizar la reparación'))
                                    }}
                                />
                            }
                            {
                                canGenerateInforme(ordenProceso, evaulacionReparacionComponenteResumenEtapas, true) &&
                                <>
                                    <Button
                                        disabled = {isNotReparacionAprobada || isBusy}
                                        onClick = {async () => await getRequestsAndGenerateInforme(ordenProceso, detallesApi, changeStates)}
                                        variant = "success"
                                    >
                                        {isBusy ? <Spinner animation="border" size="sm" /> : 'Generar informe'}
                                    </Button>
                                </>
                            }
                        </div>
                    }
                </Card.Header>
                <Card.Body>
                    <Row>
                    <Col>
                            Fecha inicial
                            <h3>{reparacion.fechaInicial}</h3>
                        </Col>
                        <Col>
                            Fecha final
                            <h3>{reparacion.fechaFinal}</h3>
                        </Col>
                        <Col>
                            T.O.T.
                            <h3>{reparacion.tot}</h3>
                        </Col>
                    </Row>
                </Card.Body>
            </Card>
            <DocumentosReparacion
                codigoOP = {ordenProceso.codigoOP}
                isBusy = {isBusy}
                changeBusyState = {(state: boolean) => setIsBusy(state)}
                userPermission = {editPermission}
                documentosListado = {reparacion.documentosListado}
            />
            {evaulacionReparacionComponenteResumenEtapas && 
                <EvaluacionReparacionComponenteResumen
                    codigoOP = {ordenProceso.codigoOP}
                    evaulacionReparacionComponenteResumenEtapas = {evaulacionReparacionComponenteResumenEtapas}
                    userPermissionFunction = {userPermissionFunction}
                    userRoles = {userData.userRoles}
                    isBusy = {isBusy}
                    detallesApi = {detallesApi}
                    userName = {userData.userName}
                    isReparacion
                    participantesGenerales = {participantesGenerales}
                    changeParticipantesGenerales = {changeParticipantesGenerales}
                    doesFechaInicialExists = {!!reparacion.fechaInicial}
                />
            }
            {show && ordenProceso.evaluacion?.fechaInicial && (
                <Modal show = {show} onHide = {handleCloseEdit}>
                    <Modal.Header>
                        <Col className = 'text-center'>
                            <Modal.Title>Editar Reparación</Modal.Title>
                        </Col>
                    </Modal.Header>
                    <Modal.Body>
                        <ReparacionFormComponent
                            formState = {formState}
                            submitForm = {submitForm}
                            onCancel = {handleCloseEdit}
                            totOptions = {totOptions}
                            minDateReparacion = {ordenProceso.evaluacion.fechaInicial}
                            withArmadoReparacion = {ordenProceso.armadoReparacionComponente}
                        />
                    </Modal.Body>
                </Modal>
            )}
        </>
    )
}

export default ReparacionDetails;