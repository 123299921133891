import cogoToast from "cogo-toast";
import { ReactElement, useEffect, useState } from "react";
import { Spinner } from "react-bootstrap";
import { useHistory } from "react-router-dom";
import {
  PlanificacionesFilters,
  PlanificacionTabla,
} from "../../models/planificacionesERP/PlanificacionERP";
import { getPlanificaciones } from "../../api/PlanificacionesERPApi";
import Table, { TableColumn } from "../form/table/Table";
import { differenceInDays, parse } from "date-fns";
import PlanificacionesIndicadores from "./PlanificacionIndicadores";

interface PlanificacionesListProps {
  planificacionesFilters: PlanificacionesFilters;
  setPlanificacionesFilters: (
    planificacionesFilters: PlanificacionesFilters
  ) => void;
  addPlanificacionToPuesto?: (planificacion: PlanificacionTabla) => void;
  isModal?: boolean
}

const PlanificacionesList = ({
  planificacionesFilters,
  setPlanificacionesFilters,
  addPlanificacionToPuesto,
  isModal
}: PlanificacionesListProps): ReactElement => {
  const [planificaciones, setPlanificaciones] = useState<PlanificacionTabla[]>(
    []
  );
  const [totalPages, setTotalPages] = useState<number>();
  const [isLoading, setIsLoading] = useState<boolean>(true);
  const history = useHistory();

  const calcularDiasTotales = (fechaInicio: string, estado: string) => {
    if (fechaInicio && estado !== "TERMINADO") {
      const startDate = parse(fechaInicio, "dd/MM/yyyy", new Date());
      const today = new Date();
      return differenceInDays(today, startDate);
    }
    if (estado === "TERMINADO") return "DESPACHADO";
    return 0;
  };

  const newPlanificaciones = planificaciones.map((planificacion) => {
    return {
      ...planificacion,
      diasTotales: calcularDiasTotales(
        planificacion.fechaInicio,
        planificacion.estado
      ),
      isMeta: planificacion.meta.isMeta ? "SI" : "NO"
    };
  });

  useEffect(() => {
    getPlanificaciones(planificacionesFilters)
      .then((response) => {
        if (response.data.planificaciones) {
          setPlanificaciones(response.data.planificaciones);
          setTotalPages(response.data.totalPages);
        } else {
          cogoToast.error("No fue posible obtener las planificaciones");
        }
        setIsLoading(false);
      })
      .catch(() => {
        cogoToast.error("No fue posible obtener las planificaciones");
        setIsLoading(false);
      });
  }, [planificacionesFilters]);

  const handlePlanificacionClick = (
    planificacion: PlanificacionTabla
  ): void => {
    if (addPlanificacionToPuesto) {
      addPlanificacionToPuesto(planificacion);
    } else {
      history.push(`/planificacionesERP/${planificacion._id}`);
    }
  };

  const tableColumns: TableColumn<PlanificacionTabla>[] = [
    {
      title: "Meta",
      accessor: "isMeta",
    },
    {
      title: "Prioridad",
      accessor: "prioridad",
    },
    {
      title: "Orden proceso",
      accessor: "codigoOP",
    },
    {
      title: "Tipo",
      accessor: "tipo",
    },
    {
      title: "Modelo componente",
      accessor: "modeloComponente",
    },
    {
      title: "Galpón",
      accessor: "galpon",
    },
    {
      title: "Cliente",
      accessor: "cliente",
    },
    {
      title: "Proceso",
      accessor: "proceso",
    },
    {
      title: "% Avance",
      accessor: "porcentaje",
    },
    {
      title: "Estado",
      accessor: "estado",
    },
    {
      title: "Fecha inicio ideal",
      accessor: "fechaInicio",
    },
    {
      title: "Fecha término ideal",
      accessor: "fechaTermino",
    },
    {
      title: "Fecha inicio real",
      accessor: "fechaInicioReal",
    },
    {
      title: "Fecha término real",
      accessor: "fechaTerminoReal",
    },
    {
      title: "Días totales",
      accessor: "diasTotales",
    },
  ];

  if (!planificaciones) {
    if (isLoading) {
      return (
        <div className="text-center">
          <Spinner animation="border" />
        </div>
      );
    } else {
      return (
        <p className="text-center">No fue posible cargar las planificaciones</p>
      );
    }
  } else {
    return (
      <>
        {!isModal ? (
          <>
            <PlanificacionesIndicadores planificaciones={planificaciones} />
            <Table
              hover
              items={newPlanificaciones}
              columns={tableColumns}
              onItemClick={handlePlanificacionClick}
              pages={totalPages}
              activePage={planificacionesFilters.page}
              onPageSelect={(page) =>
                setPlanificacionesFilters({ ...planificacionesFilters, page: page })
              }
            />
          </>
        ): (
          <>
            <Table
              hover
              items={newPlanificaciones}
              columns={tableColumns}
              onItemClick={handlePlanificacionClick}
              pages={totalPages}
              activePage={planificacionesFilters.page}
              onPageSelect={(page) =>
                setPlanificacionesFilters({ ...planificacionesFilters, page: page })
              }
            />
          </>
        )}
      </>
    );
  }
};

export default PlanificacionesList;
