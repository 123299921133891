import { Card, Badge, Row, Col } from "react-bootstrap";
import { GalponDetails } from "../../models/galpones/Galpon";
import { ReactElement } from "react";

interface GalponIndicadoresProps {
  galpon: GalponDetails;
}

const GalponIndicadores = ({
  galpon,
}: GalponIndicadoresProps): ReactElement => {
  if (!galpon.areasTrabajo || galpon.areasTrabajo.length === 0) {
    return (
      <Card className="mb-3">
        <Card.Body>
          <Card.Title>{galpon.nombre}</Card.Title>
          <Card.Text>No hay áreas de trabajo en este galpón.</Card.Text>
        </Card.Body>
      </Card>
    );
  }

  return (
    <Card className="mb-3">
      <Card.Body>
        <Card.Title>{galpon.nombre}</Card.Title>
        <Row className="mt-3">
          {galpon.areasTrabajo.map((area, index) => {
            if (!area.puestosTrabajo || area.puestosTrabajo.length === 0) {
              return (
                <Col key={index} xs={12} sm={6} md={4} lg={3} className="mb-3">
                  <Card>
                    <Card.Body>
                      <Card.Subtitle>{area.nombre}</Card.Subtitle>
                      <Card.Text>No hay puestos en esta área.</Card.Text>
                    </Card.Body>
                  </Card>
                </Col>
              );
            }

            const countDisponible = area.puestosTrabajo.filter(
              (p) => p.estado === "DISPONIBLE"
            ).length;
            const countOcupado = area.puestosTrabajo.filter(
              (p) => p.estado === "OCUPADO"
            ).length;
            const countFuera = area.puestosTrabajo.filter(
              (p) => p.estado === "FUERA DE SERVICIO"
            ).length;
            const countTotal = area.puestosTrabajo.length;

            return (
              <Col key={index} xs={12} sm={6} md={4} lg={3} className="mb-3">
                <Card>
                  <Card.Body>
                    <Card.Subtitle>{area.nombre}</Card.Subtitle>
                    <div className="mt-2">
                      <div>
                        <strong>Disponible: </strong>
                        <Badge bg="success">{countDisponible}</Badge>
                      </div>
                      <div>
                        <strong>Ocupado: </strong>
                        <Badge bg="warning">{countOcupado}</Badge>
                      </div>
                      <div>
                        <strong>Fuera de Servicio: </strong>
                        <Badge bg="danger">{countFuera}</Badge>
                      </div>
                      <div>
                        <strong>Total: </strong>
                        <Badge bg="primary">{countTotal}</Badge>
                      </div>
                    </div>
                  </Card.Body>
                </Card>
              </Col>
            );
          })}
        </Row>
      </Card.Body>
    </Card>
  );
};

export default GalponIndicadores;
