import { SelectOption } from "../../models/optionsFilters/Select";
import TipoMotivo from "../../models/planificacionesERP/TipoMotivo";

const useMotivoReparacion = (): SelectOption<TipoMotivo>[] => {
  return [
    { label: "ORDEN COMPRA", value: { motivo: "ORDEN COMPRA" } },
    { label: "PLANIFICACIÓN", value: { motivo: "PLANIFICACIÓN" } },
    { label: "ÁREA COMERCIAL", value: { motivo: "ÁREA COMERCIAL" } },
  ];
};

export default useMotivoReparacion;
