import { SelectOption } from "../../models/optionsFilters/Select";
import TipoMeta from "../../models/planificacionesERP/TipoMeta";

const useMetaPlanificacion = (): SelectOption<TipoMeta>[] => {
  return [
    { label: "SI", value: { meta: "true" } },
    { label: "NO", value: { meta: "false" } },
  ];
};

export default useMetaPlanificacion;
