import { Card, Row, Col } from "react-bootstrap";
import { DesviacionTabla } from "../../models/desviaciones/Desviaciones";
import { ReactElement } from "react";

interface DesviacionIndicadoresProps {
  desviaciones: DesviacionTabla[];
}

const DesviacionIndicadores = ({
  desviaciones,
}: DesviacionIndicadoresProps): ReactElement => {
  const dimensions = [
    "INFRAESTRUCTURA",
    "PERSONAL",
    "EQUIPO APOYO",
    "HERRAMIENTAS",
    "TOT",
    "REPUESTOS",
    "PROCEDIMIENTOS",
    "OTROS",
  ];

  const counts = dimensions.reduce((acc, dimension) => {
    acc[dimension] = desviaciones.filter(
      (d) => d.dimension.toUpperCase() === dimension
    ).length;
    return acc;
  }, {} as Record<string, number>);

  return (
    <Row>
      {dimensions.map((dimension) => (
        <Col key={dimension} md={3} className="mb-3">
          <Card>
            <Card.Body>
              <Card.Title className="text-center">{dimension}</Card.Title>
              <Card.Text className="text-center display-6">
                {counts[dimension]}
              </Card.Text>
            </Card.Body>
          </Card>
        </Col>
      ))}
    </Row>
  );
};

export default DesviacionIndicadores;
