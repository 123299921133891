import cogoToast from "cogo-toast";
import { ReactElement } from "react";
import useRolUsuarioOptions from "../../hooks/users/useRolUsuario";
import { Persona } from "../../models/users/Users";
import EditPersonasComponent from "../users/EditPersonasComponent";
import { EvaluacionOrReparacionComponenteResumenEtapa } from "../../models/EvaluacionesReparaciones";
import DetallesEvaluacionReparacionApi from "../../api/EvaluacionesReparacionesApi";
import useDisponibilidadOptions from "../../hooks/users/useDisponibilidad";

interface Props {
    evaulacionReparacionComponenteResumenEtapa: EvaluacionOrReparacionComponenteResumenEtapa,
    userPermission: boolean,
    changeEvaulacionReparacionComponenteResumenEtapa: (evaulacionReparacionComponenteResumen: EvaluacionOrReparacionComponenteResumenEtapa) => void
    isUpdating: boolean,
    isButtonDisabled?: boolean,
    detallesApi: DetallesEvaluacionReparacionApi,
    isParticipantesGenerales?: boolean
}

const EvaluacionReparacionComponenteEtapaParticipantes = ({
    evaulacionReparacionComponenteResumenEtapa, changeEvaulacionReparacionComponenteResumenEtapa, userPermission, isUpdating, isButtonDisabled, detallesApi, isParticipantesGenerales
}: Props): ReactElement => {

    const textComplement = isParticipantesGenerales ? ' generales' : '';
    const titleSubjectText = `Participantes${textComplement}`;
    const updateText = `Participantes${textComplement} actualizados`;
    const updateTextError = `No fue posible actualizar a los participantes${textComplement}`;
    const putRequest = (requestParameter: EvaluacionOrReparacionComponenteResumenEtapa, thenFunction: (response: any) => void, catchFunction: () => void) => (
        isParticipantesGenerales ?
            detallesApi.putEvaluacionOrReparacionComponenteParticipantesGenerales(requestParameter).then(thenFunction).catch(catchFunction)
        :
            detallesApi.putEvaluacionOrReparacionComponenteResumen(requestParameter).then(thenFunction).catch(catchFunction)
    );

    return (
        <EditPersonasComponent
            title = {titleSubjectText}
            userPermission = {userPermission}
            isUpdatingTarea = {isUpdating}
            existingPersonas = {evaulacionReparacionComponenteResumenEtapa.participantes || []}
            rolUsuarioOptions = {useRolUsuarioOptions('evaluacionReparacion')}
            // disponibilidadOptions = {useDisponibilidadOptions()}
            disponibilidadOptions
            isButtonDisabled = {isButtonDisabled}
            addPersonas = {(personas: Persona[], hideModal: () => void, setStateToFalse: () => void) => putRequest(
                { ...evaulacionReparacionComponenteResumenEtapa, participantes: personas.concat(evaulacionReparacionComponenteResumenEtapa.participantes || []) },
                (response: any) => {
                    if (response.data) {
                        changeEvaulacionReparacionComponenteResumenEtapa(response.data);
                        cogoToast.success(updateText);
                        hideModal();
                    } else {
                        cogoToast.success(updateTextError);
                    }
                    setStateToFalse();
                },
                () => {
                    cogoToast.success(updateTextError);
                    setStateToFalse();
                }
            )}
            deletePersona = {(persona: Persona, hideModal: () => void, setStateToFalse: () => void) => putRequest(
                { ...evaulacionReparacionComponenteResumenEtapa, participantes: ((): Persona[] => {
                    const newParticipantes: Persona[] = [];
                    evaulacionReparacionComponenteResumenEtapa.participantes?.forEach((participante) => {
                        if (participante.rut !== persona.rut) {
                            newParticipantes.push(participante);
                        }
                    });
                    return newParticipantes;
                })()},
                (response: any) => {
                    if (response.data) {
                        changeEvaulacionReparacionComponenteResumenEtapa(response.data);
                        cogoToast.success(updateText);
                        hideModal();
                    } else {
                        cogoToast.success(updateTextError);
                    }
                    setStateToFalse();
                },
                () => {
                    cogoToast.success(updateTextError);
                    setStateToFalse();
                }
            )}
        />
    )
};

export default EvaluacionReparacionComponenteEtapaParticipantes;