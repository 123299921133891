import { ReactElement, useState } from "react";
import useFormState from "../../hooks/useFormState";
import {
  formatPlanificacionRequestBody,
  getEmptyPlanificacionForm,
} from "../../utils/planificaciones";
import { useHistory } from "react-router-dom";
import { Button, Col, Modal } from "react-bootstrap";
import PlanificacionFormComponent from "./PlanificacionFormComponent";
import { PlanificacionForm } from "../../models/planificacionesERP/PlanificacionERP";
import { postPlanificacion } from "../../api/PlanificacionesERPApi";
import cogoToast from "cogo-toast";
import {
  OrdenesProcesoFilters,
  OrdenProcesoTabla,
} from "../../models/estatusGeneral/OrdenProceso";
import OrdenesProcesoList from "../estatusGeneral/OrdenesProcesoList";
import OrdenesProcesosFiltersComponent from "../estatusGeneral/OrdenesProcesoFiltersComponent";
import { getEmptyOrdenesProcesoFilters } from "../../utils/estatusGeneral";
import useEstadosOrdenProceso from "../../hooks/estatusGeneral/useEstadosOrdenProceso";
import useCentroCostoProceso from "../../hooks/estatusGeneral/useCentroCostoProceso";
import useTipoOrdenProceso from "../../hooks/estatusGeneral/useTipoOrdenProceso";
import useTipoProceso from "../../hooks/estatusGeneral/useTipoProceso";
import { SelectOption } from "../../models/optionsFilters/Select";
import TipoGalpon from "../../models/planificacionesERP/TipoGalpon";
import TipoMotivo from "../../models/planificacionesERP/TipoMotivo";
import useProcesoPlanificacion from "../../hooks/planificaciones/useProcesoPlanificacion";
import useMesPlanificacion from "../../hooks/planificaciones/useMesPlanificacion";

interface NuevaPlanificacionProps {
  userPermission: boolean;
  galponPlanificacionOptions: SelectOption<TipoGalpon>[];
  motivoReparacionOptions: SelectOption<TipoMotivo>[];
}

const NuevaPlanificacion = ({
  userPermission,
  galponPlanificacionOptions,
  motivoReparacionOptions,
}: NuevaPlanificacionProps): ReactElement => {
  const [op, setOP] = useState<OrdenProcesoTabla>();
  const [ordenesProcesoFilters, setOrdenesProcesoFilters] =
    useState<OrdenesProcesoFilters>(getEmptyOrdenesProcesoFilters("filtros"));

  const estadosOrdenProcesoOptions = useEstadosOrdenProceso("filtros");
  const centroCostoOptions = useCentroCostoProceso();
  const tipoOrdenProcesoOptions = useTipoOrdenProceso();
  const tipoProcesoOptions = useTipoProceso(true);
  const mesPlanificacionOptions = useMesPlanificacion();

  const procesoPlanificacionOptions = useProcesoPlanificacion(op?.codigoOP);

  const formState = useFormState(getEmptyPlanificacionForm());

  const history = useHistory();

  const [show, setShow] = useState(false);
  const handleClose = () => setShow(false);
  const handleShow = () => setShow(true);

  const [showForm, setShowForm] = useState(false);
  const handleCloseForm = () => {
    setShowForm(false);
    formState.reset();
  };
  const handleShowForm = () => setShowForm(true);

  const changeOrdenesProcesoFilters = (
    ordenesProcesoFilters: OrdenesProcesoFilters
  ): void => {
    setOrdenesProcesoFilters(ordenesProcesoFilters);
  };

  const submitForm = (planificacionForm: PlanificacionForm) => {
    if (op) {
      postPlanificacion(
        formatPlanificacionRequestBody(op.codigoOP, planificacionForm)
      )
        .then((response) => {
          if (response.data._id) {
            history.push(`/planificacionesERP/${response.data._id}`);
            cogoToast.success("Planificación creada");
          } else {
            cogoToast.error("No fue posible crear la planificación");
          }
          formState.setSubmitting(false);
        })
        .catch(() => {
          formState.setSubmitting(false);
          cogoToast.error("No fue posible crear la planificación");
        });
    }
  };

  const addOrdenProcesoToPlanificacion = (ordenProceso: OrdenProcesoTabla) => {
    setOP(ordenProceso);
    handleClose();
    handleShowForm();
  };

  return (
    <>
      {userPermission && (
        <Button onClick={handleShow}>Nueva Planificación</Button>
      )}
      {show && estadosOrdenProcesoOptions && (
        <Modal show={show} onHide={handleClose}>
          <Modal.Header>
            <Col className="text-center">
              <Modal.Title>Seleccione una OP</Modal.Title>
            </Col>
          </Modal.Header>
          <Modal.Body>
            <div>
              <OrdenesProcesosFiltersComponent
                ordenesProcesoFilters={ordenesProcesoFilters}
                estadosOrdenProcesoOptions={estadosOrdenProcesoOptions}
                centroCostoOptions={centroCostoOptions}
                tipoOrdenProcesoOptions={tipoOrdenProcesoOptions}
                tipoProcesoOptions={tipoProcesoOptions}
                onFiltersChange={setOrdenesProcesoFilters}
                componenteQR
              />
            </div>
            <OrdenesProcesoList
              ordenesProcesoFilters={ordenesProcesoFilters}
              setOrdenesProcesoFilters={changeOrdenesProcesoFilters}
              addOrdenProcesoToPlanificacion={addOrdenProcesoToPlanificacion}
            />
          </Modal.Body>
        </Modal>
      )}
      {showForm && (
        <Modal show={showForm} onHide={handleCloseForm}>
          <Modal.Header>
            <Col className="text-center">
              <Modal.Title>Nueva Planificación</Modal.Title>
            </Col>
          </Modal.Header>
          <Modal.Body>
            {op && <h5>{`OP seleccionada: ${op?.codigoOP}`}</h5>}
            <h6>Es necesario completar los siguientes campos</h6>
            <PlanificacionFormComponent
              formState={formState}
              submitForm={submitForm}
              onCancel={handleCloseForm}
              procesoPlanificacionOptions={procesoPlanificacionOptions}
              galponPlanificacionOptions={galponPlanificacionOptions}
              motivoReparacionOptions={motivoReparacionOptions}
              mesPlanificacionOptions={mesPlanificacionOptions}
              isCreating
            />
          </Modal.Body>
        </Modal>
      )}
    </>
  );
};

export default NuevaPlanificacion;
