import { Dispatch, ReactElement, ReactText, SetStateAction } from "react";
import { DesviacionesFilters } from "../../models/desviaciones/Desviaciones";
import TipoDimension from "../../models/desviaciones/TipoDimension";
import { SelectOption } from "../../models/optionsFilters/Select";
import TipoGalpon from "../../models/planificacionesERP/TipoGalpon";
import { Col, Row } from "react-bootstrap";
import Select from "../form/select/Select";
import DateSelect from "../form/DateSelect";
import { Input } from "../form/input/Input";
import TipoEstado from "../../models/planificacionesERP/TipoEstado";

interface DesviacionesFiltersProps {
  desviacionesFilters: DesviacionesFilters;
  galponPlanificacionOptions: SelectOption<TipoGalpon>[];
  dimensionDesviacionOptions: SelectOption<TipoDimension>[];
  estadoDesviacionOptions: SelectOption<TipoEstado>[];
  onFiltersChange: Dispatch<SetStateAction<DesviacionesFilters>>;
}

const DesviacionesFiltersComponent = ({
  desviacionesFilters,
  galponPlanificacionOptions,
  dimensionDesviacionOptions,
  estadoDesviacionOptions,
  onFiltersChange,
}: DesviacionesFiltersProps): ReactElement => {
  const handleFilterChange = (name: string, value?: ReactText): void => {
    onFiltersChange((prevFilters) => ({
      ...prevFilters,
      page: 1,
      [name]: value,
    }));
  };

  return (
    <div className="body-filters">
      <Row>
        <Col lg={3}>
          <Input
            value={desviacionesFilters.codigoOP}
            name="codigoOP"
            onChangeWithName={handleFilterChange}
            type="text"
            title="Código"
          />
        </Col>
        <Col lg={3}>
          <Select
            title="Dimensión"
            name="dimension"
            options={dimensionDesviacionOptions}
            onSelectWithName={(name, dimension): void => {
              handleFilterChange(name, dimension.value.dimension.toString());
            }}
            onClearWithName={(name): void => handleFilterChange(name, "")}
          />
        </Col>
        <Col lg={3}>
          <Select
            title="Galpón"
            name="galpon"
            options={galponPlanificacionOptions}
            onSelectWithName={(name, galpon): void => {
              handleFilterChange(name, galpon.value.galpon.toString());
            }}
            onClearWithName={(name): void => handleFilterChange(name, "")}
          />
        </Col>
        <Col lg={3}>
          <Select
            title="Estado"
            name="estado"
            options={estadoDesviacionOptions}
            onSelectWithName={(name, estado): void => {
              handleFilterChange(name, estado.value.estado.toString());
            }}
            onClearWithName={(name): void => handleFilterChange(name, "")}
          />
        </Col>
        <Col lg={3}>
          <DateSelect
            title="Fecha"
            name="fechaInicio"
            name2="fechaTermino"
            selected={desviacionesFilters.fechaInicio}
            selected2={desviacionesFilters.fechaTermino}
            onChangeField={handleFilterChange}
            startDate={desviacionesFilters.fechaInicio}
            endDate={desviacionesFilters.fechaTermino}
            isClearable
            isInterval
          />
        </Col>
      </Row>
    </div>
  );
};

export default DesviacionesFiltersComponent;
