import { ReactElement, useContext, useState } from "react";
import AuthContext from "../../contexts/AuthContext";
import { Card } from "react-bootstrap";
import GalponesList from "../../components/galpones/GalponesList";
import NuevoGalpon from "../../components/galpones/NuevoGalpon";
import { getUserPermissionFunction } from "../../utils/users";
import { GalponDetails } from "../../models/galpones/Galpon";
import GalponIndicadores from "../../components/galpones/GalponIndicadores";
import { Tab, TabList, TabPanel, Tabs } from "react-tabs";
import ServiciosExternosPage from "../serviciosExternos/ServiciosExternosPage";

const GalponesPage = (): ReactElement => {
  const { userRoles } = useContext(AuthContext);
  const [refresh, setRefresh] = useState(false);
  const [galpones, setGalpones] = useState<GalponDetails[]>([]);

  const handleRefresh = () => {
    setRefresh((prev) => !prev);
  };

  return (
    <Tabs>
      <TabList>
        <Tab>
          <h6>Galpones</h6>
        </Tab>
        <Tab>
          <h6>Indicadores</h6>
        </Tab>
        <Tab>
          <h6>Servicio externo</h6>
        </Tab>
      </TabList>

      <TabPanel>
        <Card className="cards-container">
          <Card.Body className="cards-body-groups">
            <div className="cards-header">
              <h3>Galpones</h3>
              <div className="filters-buttons">
                <NuevoGalpon
                  userPermission={getUserPermissionFunction(userRoles)({
                    roles: ["ADMINISTRADOR"],
                  })}
                  onGalponCreated={handleRefresh}
                />
              </div>
            </div>
            <GalponesList
              refresh={refresh}
              userPermission={getUserPermissionFunction(userRoles)({
                roles: ["ADMINISTRADOR", "PLANIFICACION", "JEFETALLER", "SUPERVISOR"],
              })}
              onDataLoaded={(data) => setGalpones(data)}
            />
          </Card.Body>
        </Card>
      </TabPanel>

      <TabPanel>
        {galpones.length === 0 ? (
          <p>No se encontraron galpones.</p>
        ) : (
          galpones.map((galpon) => (
            <GalponIndicadores key={galpon._id} galpon={galpon} />
          ))
        )}
      </TabPanel>

      <TabPanel>
        <ServiciosExternosPage />
      </TabPanel>
    </Tabs>
  );
};

export default GalponesPage;
