import { ReactElement, useState } from "react";
import useFormState from "../../hooks/useFormState";
import {
  formatGalponRequestBody,
  getEmptyGalponForm,
} from "../../utils/galpones";
import { GalponForm } from "../../models/galpones/Galpon";
import { Button, Col, Modal } from "react-bootstrap";
import GalponFormComponent from "./GalponFormComponent";
import { postGalpon } from "../../api/GalponesApi";
import cogoToast from "cogo-toast";

interface NuevoGalponProps {
  userPermission: boolean;
  onGalponCreated: () => void;
}

const NuevoGalpon = ({
  userPermission,
  onGalponCreated,
}: NuevoGalponProps): ReactElement => {
  const formState = useFormState(getEmptyGalponForm());

  const [show, setShow] = useState(false);
  const handleClose = () => {
    setShow(false);
    formState.reset();
  };
  const handleShow = () => setShow(true);

  const submitForm = (galponForm: GalponForm) => {
    postGalpon(formatGalponRequestBody(galponForm))
      .then((response) => {
        if (response.data) {
          cogoToast.success("Galpón creado");
          onGalponCreated();
          handleClose();
        } else {
          cogoToast.error("No fue posible crear el galpón");
        }
        formState.setSubmitting(false);
      })
      .catch(() => {
        formState.setSubmitting(false);
        cogoToast.error("No fue posible crear el galpón");
      });
  };

  return (
    <>
      {userPermission && <Button onClick={handleShow}>Nuevo Galpón</Button>}
      {show && (
        <Modal show={show} onHide={handleClose}>
          <Modal.Header>
            <Col className="text-center">
              <Modal.Title>Nuevo Galpón</Modal.Title>
            </Col>
          </Modal.Header>
          <Modal.Body>
            <GalponFormComponent
              formState={formState}
              submitForm={submitForm}
              onCancel={handleClose}
              isCreating
            />
          </Modal.Body>
        </Modal>
      )}
    </>
  );
};

export default NuevoGalpon;
