import { ReactElement, useContext, useState } from "react";
import AuthContext from "../../contexts/AuthContext";
import { Card } from "react-bootstrap";
import useGalponPlanificacion from "../../hooks/planificaciones/useGalponPlanificacion";
import useDimensionDesviacion from "../../hooks/desviaciones/useDimensionDesviacion";
import { DesviacionesFilters } from "../../models/desviaciones/Desviaciones";
import { getEmptyDesviacionesFilters } from "../../utils/desviaciones";
import NuevaDesviacion from "../../components/desviaciones/NuevaDesviacion";
import { getUserPermissionFunction } from "../../utils/users";
import useMotivoPersonal from "../../hooks/desviaciones/useMotivoPersonal";
import useMotivoHerramientas from "../../hooks/desviaciones/useMotivoHerramientas";
import useMotivoTOT from "../../hooks/desviaciones/useMotivoTOT";
import useMotivoEquipoApoyo from "../../hooks/desviaciones/useMotivoEquipoApoyo";
import useMotivoInfraestructura from "../../hooks/desviaciones/useMotivoInfraestructura";
import useMotivoRepuestos from "../../hooks/desviaciones/useMotivoRepuestos";
import useMotivoProcedimientos from "../../hooks/desviaciones/useMotivoProcedimientos";
import DesviacionesPlanificacionList from "../../components/desviaciones/DesviacionesPlanificacionList";
import { useParams } from "react-router-dom";

const DesviacionPage = (): ReactElement => {
  const [desviacionesFilters, setDesviacionesFilters] =
    useState<DesviacionesFilters>(getEmptyDesviacionesFilters());
  const galponDesviacionOptions = useGalponPlanificacion();
  const dimensionDesviacionOptions = useDimensionDesviacion();
  const motivoPersonalOptions = useMotivoPersonal();
  const motivoHerramientasOptions = useMotivoHerramientas();
  const motivoTOTOptions = useMotivoTOT();
  const motivoEquipoApoyoOptions = useMotivoEquipoApoyo();
  const motivoInfraestructuraOptions = useMotivoInfraestructura();
  const motivoRepuestosOptions = useMotivoRepuestos();
  const motivoProcedimientosOptions = useMotivoProcedimientos();
  const { id } = useParams<{ id: string }>();

  const { userRoles } = useContext(AuthContext);

  const [refresh, setRefresh] = useState(false);

  const handleRefresh = () => {
    setRefresh((prev) => !prev);
  };

  const desviacionesChange = (desviacionesFilters: DesviacionesFilters) => {
    setDesviacionesFilters(desviacionesFilters);
  };

  return (
    <Card className="cards-container">
      <Card.Body className="cards-body-groups">
        <div className="cards-header">
          <h4>Desviaciones</h4>
          <div className="filters-buttons">
            <NuevaDesviacion
              dimensionDesviacionOptions={dimensionDesviacionOptions}
              galponDesviacionOptions={galponDesviacionOptions}
              motivoPersonalOptions={motivoPersonalOptions}
              motivoHerramientasOptions={motivoHerramientasOptions}
              motivoTOTOptions={motivoTOTOptions}
              motivoEquipoApoyoOptions={motivoEquipoApoyoOptions}
              motivoInfraestructuraOptions={motivoInfraestructuraOptions}
              motivoProcedimientosOptions={motivoProcedimientosOptions}
              motivoRepuestosOptions={motivoRepuestosOptions}
              userPermission={getUserPermissionFunction(userRoles)({
                roles: ["ADMINISTRADOR", "PLANIFICACION", "JEFETALLER", "SUPERVISOR"],
              })}
              id={id}
              onDesviacionCreated={handleRefresh}
            />
          </div>
        </div>
        <DesviacionesPlanificacionList
          desviacionesFilters={desviacionesFilters}
          setDesviacionesFilters={desviacionesChange}
          id={id}
          refresh={refresh}
        />
      </Card.Body>
    </Card>
  );
};

export default DesviacionPage;
