import cogoToast from "cogo-toast";
import { ReactElement, useState } from "react";
import { Button, Card, Col, Modal, Row, Spinner } from "react-bootstrap";
import useFormState from "../../hooks/useFormState";
import useTOTOptions from "../../hooks/useTOTOptions";
import { OrdenProceso } from "../../models/estatusGeneral/OrdenProceso";
import { Evaluacion, EvaluacionForm } from "../../models/Evaluaciones";
import { formatEvaluacionForm, formatEvaluacionRequestBody, generarListadoInsumos } from "../../utils/evaluaciones/evaluaciones";
import EvaluacionFormComponent from "./EvaluacionFormComponent";
import EvaluacionReparacionComponenteResumen from "../evaluacionesReparaciones/EvaluacionReparacionComponenteResumen";
import { AcceptedRolesElements, AcceptedUsersElements, Persona } from "../../models/users/Users";
import CorreoEntregaEvaluacionReparacion from "../evaluacionesReparaciones/CorreoEntregaEvaluacionReparacion";
import { EvaluacionOrReparacionComponenteResumenEtapa } from "../../models/EvaluacionesReparaciones";
import { putEvaluacionCodigoOP, putEvaluacionRealizadoPor } from "../../api/EvaluacionesApi";
import DetallesEvaluacionReparacionApi from "../../api/EvaluacionesReparacionesApi";
import DocumentosEvaluacion from "../files/DocumentosEvaluacion";
import { canGenerateInforme, getRequestsAndGenerateInforme, isAprobacionTarea, titleWithoutIndex } from "../../utils/evaluacionesReparaciones/evaluacionesReparaciones";
import { useHistory } from "react-router-dom";

interface Props {
    evaluacion: Evaluacion,
    changeEvaluacion: (evaluacion: Evaluacion) => void,
    ordenProceso: OrdenProceso,
    evaulacionReparacionComponenteResumenEtapas?: EvaluacionOrReparacionComponenteResumenEtapa[],
    userPermissionFunction: (acceptedRolesElements?: AcceptedRolesElements, acceptedUsersElements?: AcceptedUsersElements) => boolean,
    userData: { userName: string, userEmail: string, userRoles: string[] },
    detallesApi: DetallesEvaluacionReparacionApi,
    participantesGenerales: Persona[],
    changeParticipantesGenerales: (resumen: EvaluacionOrReparacionComponenteResumenEtapa) => void
}

const EvaluacionDetails = ({
    evaluacion, changeEvaluacion, userPermissionFunction, ordenProceso, evaulacionReparacionComponenteResumenEtapas, userData, detallesApi, participantesGenerales, changeParticipantesGenerales
}: Props): ReactElement => {

    const history = useHistory();
    const [ isBusy, setIsBusy ] = useState<boolean>(false);
    const [ showEdit, setShowEdit ] = useState<boolean>(false);
    const [ isDownloading, setIsDownloading ] = useState<boolean>(false);
    const editPermission = userPermissionFunction({ roles: ['ADMINISTRADOR', 'JEFETALLER', 'CALIDAD', 'SUPERVISOR'] }, evaluacion.participantesRut ? { acceptedUsers: evaluacion.participantesRut } : undefined);
    const totOPtions = useTOTOptions();
    const formStateEdit = useFormState(formatEvaluacionForm(evaluacion));
    const isNotEvaluacionAprobada = !!evaulacionReparacionComponenteResumenEtapas &&
        !evaulacionReparacionComponenteResumenEtapas.find((resumenEtapa) => titleWithoutIndex(resumenEtapa.nombreEtapa) === 'Informe de evaluación')
            ?.tareas?.find((resumenEtapaTarea) => isAprobacionTarea(resumenEtapaTarea.nombreTarea))
                ?.valores.find((resumenEtapaTareaValor) => resumenEtapaTareaValor.columna === 'Fecha')?.valor;

    const handleShowEdit = () => setShowEdit(true);
    const handleCloseEdit = () => {
        formStateEdit.reset();
        setShowEdit(false);
    };

    const changeStates = (state: boolean) => {
        setIsDownloading(state);
        setIsBusy(state);
        // if (!addAttachments) {
        //     setIsDownloadingImages(state);
        //     setIsBusy(state);
        // }
    };

    const submitForm = (evaluacionForm: EvaluacionForm) => {
        putEvaluacionCodigoOP(formatEvaluacionRequestBody(evaluacionForm), ordenProceso.codigoOP)
            .then((response) => {
                formStateEdit.setSubmitting(false);
                formStateEdit.setForm(evaluacionForm);
                setShowEdit(false);
                changeEvaluacion(response.data);
                cogoToast.success('Evaluacion Editada');
            })
            .catch(() => {
                cogoToast.error('No fue posible editar la evaluación');
                formStateEdit.setSubmitting(false);
            })
    }

    return (
        <>
            <Card className = "cards-container">
                <Card.Header className = "cards-header">
                    <div className = "body-subtitle">
                        Evaluación de componente
                    </div>
                    {isDownloading ?
                        <div>Generando informe ... <Spinner animation="border" size="sm" /></div>
                    :
                        <div className = "buttons-group">
                            {editPermission &&
                                <Button onClick = {handleShowEdit} disabled = {isBusy}>{isBusy ? <Spinner animation="border" size="sm" /> : 'Editar'}</Button>
                            }
                            {userPermissionFunction({ roles: ['ADMINISTRADOR', 'JEFETALLER', 'CALIDAD', 'SUPERVISOR'] }) &&
                                <Button onClick = {() => history.push(`/planificacionesERP/${evaluacion.planificacionId}`)} disabled = {!evaluacion.planificacionId || isBusy}>{isBusy ? <Spinner animation="border" size="sm" /> : 'Ver planificación'}</Button>
                            }
                            {editPermission &&
                                <CorreoEntregaEvaluacionReparacion
                                    userData = {userData}
                                    ordenProceso = {ordenProceso}
                                    isBusy = {isBusy}
                                    setIsBusy = {(state: boolean) => setIsBusy(state)}
                                    // generateFile = {generateFile}
                                    fechaFinal = {evaluacion.fechaFinal}
                                    nombreEtapaProceso = "evaluación"
                                    // getFiles = {async (handleDocumentosResponse: (responseData?: Archivo[]) => void) => await getDocumentosEvaluacionTecnica(ordenProceso.codigoOP)
                                    //     .then((response) => handleDocumentosResponse(response.data))
                                    //     .catch(() => cogoToast.error('No fue posible obtener algunos documentos de evaluación'))
                                    // }
                                    userPermission = {userPermissionFunction({ roles: ['ADMINISTRADOR', 'JEFETALLER', 'CALIDAD', 'SUPERVISOR'] }) && !!evaluacion.fechaInicial}
                                    evaluacionUpdate = {async (realizadoPor?: string, grado?: string, notas?: string): Promise<void> => {
                                        await putEvaluacionRealizadoPor(ordenProceso.codigoOP, realizadoPor, grado, notas)
                                            .then().catch(() => cogoToast.error('No fue posible actualizar la evaluación'))
                                    }}
                                />
                            }
                            {canGenerateInforme(ordenProceso, evaulacionReparacionComponenteResumenEtapas) &&
                                <>
                                    <Button
                                        disabled = {isNotEvaluacionAprobada || isBusy}
                                        onClick = {async () => await getRequestsAndGenerateInforme(ordenProceso, detallesApi, changeStates)}
                                        variant = "success"
                                    >
                                        {isBusy ? <Spinner animation="border" size="sm" /> : 'Generar informe'}
                                    </Button>
                                    <Button
                                        disabled = {isNotEvaluacionAprobada || isBusy}
                                        variant = "success"
                                        onClick = {async () => {
                                            changeStates(true);
                                            await detallesApi.getEvaluacionOrReparacionComponenteEtapa('Informe de evaluación')
                                                .then((response) => {
                                                    if (response.data) {
                                                        generarListadoInsumos(ordenProceso, response.data);
                                                    } else {
                                                        cogoToast.error('No fue posible generar el listado de insumos');    
                                                    }
                                                    changeStates(false);
                                                }).catch((error) => {
                                                    console.log(error)
                                                    cogoToast.error('No fue posible generar el listado de insumos');
                                                    changeStates(false);
                                                })
                                        }}
                                    >
                                        {isBusy ? <Spinner animation="border" size="sm" /> : 'Generar listado insumos'}
                                    </Button>
                                </>
                            }
                        </div>
                    }
                </Card.Header>
                <Card.Body>
                    <Row>
                        <Col>
                            Fecha inicial
                            <h3>{evaluacion.fechaInicial}</h3>
                        </Col>
                        <Col>
                            Fecha final
                            <h3>{evaluacion.fechaFinal}</h3>
                        </Col>
                        <Col>
                            T.O.T.
                            <h3>{evaluacion.tot}</h3>
                        </Col>
                    </Row>
                </Card.Body>
            </Card>
            <DocumentosEvaluacion
                codigoOP = {ordenProceso.codigoOP}
                isBusy = {isBusy}
                changeBusyState = {(state: boolean) => setIsBusy(state)}
                userPermission = {editPermission && !!evaluacion.fechaInicial}
                addMessage = {ordenProceso.desarmeEvaluacionComponente}
                documentosListado = {evaluacion.documentosListado}
            />
            {evaulacionReparacionComponenteResumenEtapas && 
                <EvaluacionReparacionComponenteResumen
                    codigoOP = {ordenProceso.codigoOP}
                    evaulacionReparacionComponenteResumenEtapas = {evaulacionReparacionComponenteResumenEtapas}
                    userPermissionFunction = {userPermissionFunction}
                    userRoles = {userData.userRoles}
                    isBusy = {isBusy}
                    detallesApi = {detallesApi}
                    userName = {userData.userName}
                    participantesGenerales = {participantesGenerales}
                    changeParticipantesGenerales = {changeParticipantesGenerales}
                    doesFechaInicialExists = {!!evaluacion.fechaInicial}
                />
            }
            {showEdit && ordenProceso.recepcion?.fechaRecepcion && (
                <Modal show = {showEdit} onHide = {handleCloseEdit}>
                    <Modal.Header>
                        <Col className = "text-center">
                            <Modal.Title>Editar Evaluación</Modal.Title>
                        </Col>
                    </Modal.Header>
                    <Modal.Body>
                        <EvaluacionFormComponent
                            formState = {formStateEdit}
                            submitForm = {submitForm}
                            onCancel = {handleCloseEdit}
                            totOptions = {totOPtions}
                            minDateEvaluacion = {ordenProceso.recepcion?.fechaRecepcion}
                            withDesarmeEvaluacion = {ordenProceso.desarmeEvaluacionComponente}
                        />
                    </Modal.Body>
                </Modal>
            )}
        </>
    );
};

export default EvaluacionDetails;