import axios from "axios";
import cogoToast from "cogo-toast";
import jwt from 'jsonwebtoken';
import JWT from "../models/users/JWT";

export const requestInterceptor = (savePreviousLocationAndRedirectToLogin: () => void) => axios.interceptors.request.use((request) => {
    const jsonwebtoken = localStorage.getItem('jwt');
    if (request?.url !== 'api/auth/login') {
        if (jsonwebtoken && (jwt.decode(jsonwebtoken) as JWT).exp > Date.now()) {
            request.headers = {
                ...request.headers,
                jwt: jsonwebtoken
            };
        } else {
            cogoToast.error('No autorizado');
            savePreviousLocationAndRedirectToLogin();
            // throw new axios.Cancel();
            return Promise.reject(new axios.Cancel("No autorizado"));
        }
    }
    // if (!axios.defaults.baseURL) {
    //     // throw new axios.Cancel();
    //     return Promise.reject(new axios.Cancel("Base URL no configurada aún"))
    // }
    return request;
})

export const responseInterceptor = () => axios.interceptors.response.use(
    (response) => {
        if (response.data.message) {
            cogoToast.error(response.data.message);  
            throw new axios.Cancel();          
        }
        return response;
    }, (error) => {
        if (error.status === 401) {
            cogoToast.error('No autorizado');
        }
        if (error.status === 501) {
            cogoToast.error('No implementado');
            // throw new Error('No implementado');
        }
        // else {
        //     cogoToast.error(error.response?.data?.message || 'Error');
        //     // throw new Error(error.response?.data?.message || 'Error.');
        // }
        // redirectToLogin();
        throw new axios.Cancel();
    }
);

export const getAxiosBaseURL = (setCurrentAxiosBaseURL: (newAxiosBaseURL: string) => void, currentAxiosBaseURL?: string) => {
    const url = window.location.href.match(/^(https?:\/\/)?[^\/\\: ]+/i);
    if (url && (!currentAxiosBaseURL || currentAxiosBaseURL !== url[0])) {
        setCurrentAxiosBaseURL(url[0]);
        axios.defaults.baseURL = `${url[0]}:${process.env.REACT_APP_BACKEND_PORT || "3001"}`;
    }
}