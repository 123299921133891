import { CalculationsTableDetails } from "../../../models/EvaluacionesReparaciones";
import { roundNumber, stringToNumber } from "../../inputs";


class TablesGeneralCalculations {
    tableDetails: CalculationsTableDetails;

    constructor(tableDetails: CalculationsTableDetails) {
        this.tableDetails = tableDetails;
    }

    cellStringToNumber = (columnIndex: number): number => stringToNumber(this.tableDetails.table.rows[this.tableDetails.tableRowIndex][columnIndex].content[0] || '');

    checkVariableTaskNameOrStageNameInNamesArray = (names: string[], isTask?: boolean, isStage?: boolean): boolean => {
        let response = false;
        if (isTask && !isStage) {
            response = names.includes(this.tableDetails.taskName);
        } else if (!isTask && isStage) {
            response = names.includes(this.tableDetails.stage);
        } else if (!isTask && !isStage) {
            response = names.includes(this.tableDetails.variableName);
        }
        return response;
    }

    getPromedioOrColumnIndex = (columnName?: string): number => {
        let response = -1;
        for (let i = 0; i < this.tableDetails.table.columnsSettings.length; i++) {
            if (columnName ?
                    this.tableDetails.table.columnsSettings[i].columnTitle.includes(columnName)
                :
                    (this.tableDetails.table.columnsSettings[i].columnTitle.includes('Promedio') || this.tableDetails.table.columnsSettings[i].columnTitle.includes('Media'))
            ) {
                response = i;
                break;
            }
        }
        return response;
    }

    promedioCalc = (inputsIndex: number[]): string | undefined => {
        let promedioResponse;
        const promedioCellIndex = this.getPromedioOrColumnIndex();
        if (promedioCellIndex > -1) {
            promedioResponse = ((): string => {
                let decimalsCounter = 0;
                const getDecimalsDigitsFromNumber = (number?: number): number => {
                    let response = 0;
                    if (number && number !== 0) {
                        const numberIntergersAndDecimals = number.toString().split('.');
                        if (numberIntergersAndDecimals.length > 1) {
                            response = numberIntergersAndDecimals[1].length;
                        }
                    }
                    return response;
                }
                const limInferiorCellIndex = this.getPromedioOrColumnIndex('Límite inferior');
                const limSuperiorCellIndex = this.getPromedioOrColumnIndex('Límite superior');
                const limInferiorDecimalsCounter = getDecimalsDigitsFromNumber(limInferiorCellIndex > -1 ? this.cellStringToNumber(limInferiorCellIndex) : undefined);
                const limSuperiorDecimalsCounter = getDecimalsDigitsFromNumber(limSuperiorCellIndex > -1 ? this.cellStringToNumber(limSuperiorCellIndex) : undefined);
                if (limInferiorDecimalsCounter > decimalsCounter) {
                    decimalsCounter = limInferiorDecimalsCounter;
                }
                if (limSuperiorDecimalsCounter > decimalsCounter) {
                    decimalsCounter = limSuperiorDecimalsCounter;
                }
                const numbers = inputsIndex.map((inputIndex) => {
                    const number = this.cellStringToNumber(inputIndex);
                    const numberDecimalsDigits = getDecimalsDigitsFromNumber(number);
                    if (numberDecimalsDigits > decimalsCounter) {
                        decimalsCounter = numberDecimalsDigits;
                    }
                    return number;
                });
                let response = '';
                if (numbers.length > 0 && numbers.every((number) => !isNaN(number))) {
                    let average = numbers[0];
                    for (let i = 1; i < numbers.length; i++) {
                        average = average + numbers[i];
                    }
                    response = `${roundNumber(average / numbers.length, decimalsCounter < 1 ? 2 : decimalsCounter)}`;
                }
                return response;
            })();
            this.tableDetails.updateTable(promedioCellIndex, promedioResponse);
        }
        return promedioResponse;
    };

    standartMeasurements = (inputsIndex: number[], aproxDecimalToLimits?: boolean, customTitleColumns?: { minName?: string, maxName?: string, resultName?: string }, changeUnits?: (value: number) => number) => {
        const valoresNumber = !changeUnits ? inputsIndex.map((inputIndex) => this.cellStringToNumber(inputIndex)) : inputsIndex.map((inputIndex) => changeUnits(this.cellStringToNumber(inputIndex)));
        const columnsIndex = ((): { resultado: number, limiteInferior?: number, limiteSuperior?: number } => {
            const response = { resultado: -1, limiteInferior: -1, limiteSuperior: -1 };
            let breakCounter = 0;
            const breakCounterSum = () => breakCounter = breakCounter + 1;
            for (let i = 0; i < this.tableDetails.table.columnsSettings.length; i++) {
                if (this.tableDetails.table.columnsSettings[i].columnTitle === (customTitleColumns?.resultName || 'Resultado')) {
                    response.resultado = i;
                    breakCounterSum();
                } else {
                    if (this.tableDetails.table.columnsSettings[i].columnTitle.includes(customTitleColumns?.minName || 'Límite inferior')) {
                        response.limiteInferior = i;
                        breakCounterSum();
                    }
                    if (this.tableDetails.table.columnsSettings[i].columnTitle.includes(customTitleColumns?.maxName || 'Límite superior')) {
                        response.limiteSuperior = i;
                        breakCounterSum();
                    }
                }
                if (breakCounter > 2) {
                    break;
                }
            }
            return {
                resultado: response.resultado,
                limiteInferior: response.limiteInferior > -1 ? response.limiteInferior : undefined,
                limiteSuperior: response.limiteSuperior > -1 ? response.limiteSuperior : undefined 
            };
        })();
        this.tableDetails.updateTable(columnsIndex.resultado, ((): string => {
            const bottomLimit = (!columnsIndex.limiteInferior || this.tableDetails.table.rows[this.tableDetails.tableRowIndex][columnsIndex.limiteInferior].content[0] === 'N/A') ? undefined : this.cellStringToNumber(columnsIndex.limiteInferior);
            const topLimit = (!columnsIndex.limiteSuperior || this.tableDetails.table.rows[this.tableDetails.tableRowIndex][columnsIndex.limiteSuperior].content[0] === 'N/A') ? undefined : this.cellStringToNumber(columnsIndex.limiteSuperior);
            const decimalsCounter = ((): number | undefined => {
                let response;
                if (aproxDecimalToLimits) {
                    response = 0;
                    const bottomLimitIntergersAndDecimals = bottomLimit ? bottomLimit.toString().split('.') : undefined;
                    const topLimitIntergersAndDecimals = topLimit ? topLimit.toString().split('.') : undefined;
                    if (bottomLimitIntergersAndDecimals && topLimitIntergersAndDecimals) {
                        if (topLimitIntergersAndDecimals.length > 1 && bottomLimitIntergersAndDecimals.length > 1) {
                            response = topLimitIntergersAndDecimals[1].length > bottomLimitIntergersAndDecimals[1].length ? topLimitIntergersAndDecimals[1].length : bottomLimitIntergersAndDecimals[1].length;
                        } else if (!(topLimitIntergersAndDecimals.length < 2 && bottomLimitIntergersAndDecimals.length < 2)) {
                            if (bottomLimitIntergersAndDecimals.length < 2) {
                                response = topLimitIntergersAndDecimals[1].length;
                            } else {
                                response = bottomLimitIntergersAndDecimals[1].length;
                            }
                        }
                    } else if (!(!bottomLimitIntergersAndDecimals && !topLimitIntergersAndDecimals)) {
                        if (bottomLimitIntergersAndDecimals && bottomLimitIntergersAndDecimals.length > 1) {
                            response = bottomLimitIntergersAndDecimals[1].length;
                        } else if (topLimitIntergersAndDecimals && topLimitIntergersAndDecimals.length > 1) {
                            response = topLimitIntergersAndDecimals[1].length;
                        }
                    }
                }
                return response;
            })();
            if (valoresNumber.every((valorNumber) => {
                const fixedValorNumber = decimalsCounter ? roundNumber(valorNumber, decimalsCounter) : valorNumber;
                let dentroRangoResponse = false;
                if (!isNaN(fixedValorNumber)) {
                    if (bottomLimit !== undefined && topLimit !== undefined) {
                        if (bottomLimit <= fixedValorNumber && fixedValorNumber <= topLimit) {
                            dentroRangoResponse = true;
                        }
                    } else if (bottomLimit !== undefined) {
                        if (bottomLimit <= fixedValorNumber) {
                            dentroRangoResponse = true;
                        }
                    } else if (topLimit !== undefined) {
                        if (fixedValorNumber <= topLimit) {
                            dentroRangoResponse = true;
                        }
                    }
                }
                return dentroRangoResponse;
            })) {
                return 'Dentro de rango';
            } else {
                return 'Fuera de rango';
            }
        })());
    };
}

export default TablesGeneralCalculations;