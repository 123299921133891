import { ReactElement } from "react";
import { Row } from "react-bootstrap";
import { FormState } from "../../hooks/useFormState";
import { ReparacionForm } from "../../models/Reparaciones";
import FormButtons from "../form/buttons/FormButtons";
import Select from "../form/select/Select";
import { SelectOption } from "../../models/optionsFilters/Select";
import TOT from "../../models/TOT";
import DateSelect from "../form/DateSelect";

interface ReparacionFormComponentProps {
    formState: FormState<ReparacionForm>,
    submitForm: (reparacionForm: ReparacionForm) => void,
    onDelete?: () => void,
    onCancel: () => void,
    minDateReparacion: string,
    totOptions: SelectOption<TOT>[],
    isCreating?: boolean,
    withArmadoReparacion?: boolean
}

const ReparacionFormComponent = ({ formState, submitForm, onDelete, onCancel, minDateReparacion, totOptions, isCreating, withArmadoReparacion }: ReparacionFormComponentProps): ReactElement => {

    const {
        value: reparacionForm,
        handleFieldChange,
        areValidationsEnabled,
        setAreValidationsEnabled,
        isSubmitting,
        setSubmitting,
        isConfirmingDelete,
        setConfirmingDelete,
    } = formState;

    const handleDeleteClick = () => setConfirmingDelete(true);
    const cancelDelete = () => setConfirmingDelete(false);

    const isFormInvalid = (): boolean => {
        // return !reparacionForm.fechaInicial;
        return false;
    }

    const handleSubmitClick = (event: React.MouseEvent<HTMLElement, MouseEvent>) => {
        event.preventDefault();
        setAreValidationsEnabled(true);
        if (isFormInvalid()) return;
        setSubmitting(true);
        setAreValidationsEnabled(false);
        submitForm(reparacionForm);
    }

    return (
        <>
            <Row>
                <DateSelect
                    title = 'Fecha inicial'
                    name = 'fechaInicial'
                    onChangeField = {(fieldName: string, value: unknown) => {
                        handleFieldChange(fieldName, value);
                        if (!value || value === '') {
                            handleFieldChange('fechaFinal', undefined);
                            handleFieldChange('tot', { label: 'NO', value: { tot: 'NO' } });
                        }
                    }}
                    // invalid = {areValidationsEnabled && !reparacionForm.fechaInicial}
                    selected = {reparacionForm.fechaInicial}
                    disabled = {isSubmitting}
                    minDate = {minDateReparacion}
                    isClearable
                    // placeholder = 'Obligatorio'
                />
            </Row>
            {!withArmadoReparacion &&
                <>
                    {!isCreating &&
                        <Row>
                            <DateSelect
                                title = 'Fecha final'
                                name = 'fechaFinal'
                                onChangeField = {handleFieldChange}
                                selected = {reparacionForm.fechaFinal}
                                disabled = {!reparacionForm.fechaInicial || isSubmitting}
                                minDate = {reparacionForm.fechaInicial}
                                isClearable
                            />
                        </Row>
                    }
                    <Row>
                        <Select
                            title = "T.O.T."
                            name = "tot"
                            value = {reparacionForm.tot}
                            onSelectWithName = {handleFieldChange}
                            options = {totOptions}
                            disabled = {!reparacionForm.fechaInicial || isSubmitting}
                        />
                    </Row>
                </>
            }
            <FormButtons
                submitButtonContent="Guardar"
                onSubmitClick={handleSubmitClick}
                onCancelClick={onCancel}
                isConfirmingDelete={isConfirmingDelete}
                onDeleteClick={onDelete ? handleDeleteClick : undefined}
                onDeleteConfirm={onDelete}
                onCancelDelete={cancelDelete}
                deleteButtonContent="Eliminar"
                isDisabled={isSubmitting}
                isLoading={isSubmitting}
                isFormInvalid={isFormInvalid()}
                areValidationsEnabled={areValidationsEnabled}
            />
        </>
    )
}

export default ReparacionFormComponent;