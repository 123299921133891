import { ReactElement } from "react";
import { FormState } from "../../hooks/useFormState";
import { PlanificacionForm } from "../../models/planificacionesERP/PlanificacionERP";
import { Form, Row } from "react-bootstrap";
import { Input } from "../form/input/Input";
import FormButtons from "../form/buttons/FormButtons";
import { SelectOption } from "../../models/optionsFilters/Select";
import TipoGalpon from "../../models/planificacionesERP/TipoGalpon";
import TipoProceso from "../../models/planificacionesERP/TipoProceso";
import Select from "../form/select/Select";
import TipoMotivo from "../../models/planificacionesERP/TipoMotivo";
import TipoMes from "../../models/planificacionesERP/TipoMes";

interface PlanificacionFormComponentProps {
  formState: FormState<PlanificacionForm>;
  submitForm: (planificacionForm: PlanificacionForm) => void;
  onDelete?: () => void;
  onCancel: () => void;
  procesoPlanificacionOptions: SelectOption<TipoProceso>[];
  galponPlanificacionOptions: SelectOption<TipoGalpon>[];
  motivoReparacionOptions: SelectOption<TipoMotivo>[];
  mesPlanificacionOptions: SelectOption<TipoMes>[];
  isCreating?: boolean;
}

const PlanificacionFormComponent = ({
  formState,
  submitForm,
  onDelete,
  onCancel,
  procesoPlanificacionOptions,
  galponPlanificacionOptions,
  motivoReparacionOptions,
  mesPlanificacionOptions,
  isCreating,
}: PlanificacionFormComponentProps): ReactElement => {
  const {
    value: planificacionForm,
    handleFieldChange,
    areValidationsEnabled,
    setAreValidationsEnabled,
    isSubmitting,
    setSubmitting,
    isConfirmingDelete,
    setConfirmingDelete,
  } = formState;

  const isFormInvalid =
    !planificacionForm.galpon ||
    !planificacionForm.prioridad ||
    (isCreating && !planificacionForm.proceso);

  const handleSubmitClick = (
    event: React.MouseEvent<HTMLElement, MouseEvent>
  ) => {
    event.preventDefault();
    setAreValidationsEnabled(true);
    if (isFormInvalid) return;
    setSubmitting(true);
    setAreValidationsEnabled(false);
    submitForm(planificacionForm);
  };

  const handleDeleteClick = () => setConfirmingDelete(true);
  const cancelDelete = () => setConfirmingDelete(false);

  const isEditting = (): boolean => {
    if (isCreating) {
      return isSubmitting;
    } else {
      return true;
    }
  };

  return (
    <>
      <Form>
        <Row>
          <Input
            title="Prioridad"
            name="prioridad"
            value={planificacionForm.prioridad}
            type="number"
            onChangeWithName={handleFieldChange}
            invalid={areValidationsEnabled && !planificacionForm.prioridad}
            invalidText="Ingrese prioridad"
            placeholder="Obligatorio"
          />
        </Row>
        <Row>
          <Select
            title="Proceso"
            name="proceso"
            value={planificacionForm.proceso}
            options={procesoPlanificacionOptions}
            onSelectWithName={handleFieldChange}
            disabled={isEditting()}
          />
        </Row>
        {planificacionForm.proceso?.value.proceso === "REPARACIÓN" && (
          <>
            <Row>
              <Select
                title="Motivo reparación"
                name="motivoReparacion"
                value={planificacionForm.motivoReparacion}
                options={motivoReparacionOptions}
                onSelectWithName={handleFieldChange}
              />
            </Row>

            <Row>
              <Input
                title="Valor reparación"
                name="valorReparacion"
                value={planificacionForm.valorReparacion!}
                type="number"
                onChangeWithName={handleFieldChange}
                invalidText="Ingrese valor reparación"
                placeholder="Obligatorio"
              />
            </Row>
          </>
        )}
        <Row>
          <Select
            title="Galpón"
            name="galpon"
            value={planificacionForm.galpon}
            options={galponPlanificacionOptions}
            onSelectWithName={handleFieldChange}
          />
        </Row>
        <Row>
          <div className="checkBox-form">
            <input
              className="largeCheckBox"
              type="checkbox"
              id="isMeta"
              checked={planificacionForm.isMeta}
              onChange={(e) => handleFieldChange("isMeta", e.target.checked)}
            />
            <label htmlFor="isMeta">Meta</label>
          </div>    
        </Row>
        {planificacionForm.isMeta && (
          <Row>
            <Select
              title="Mes"
              name="mes"
              value={planificacionForm.mes}
              options={mesPlanificacionOptions}
              onSelectWithName={handleFieldChange}
            />
          </Row>
        )}
        <FormButtons
          submitButtonContent="Guardar"
          onSubmitClick={handleSubmitClick}
          onCancelClick={onCancel}
          isConfirmingDelete={isConfirmingDelete}
          onDeleteClick={onDelete ? handleDeleteClick : undefined}
          onDeleteConfirm={onDelete}
          onCancelDelete={cancelDelete}
          deleteButtonContent="Eliminar"
          isDisabled={isSubmitting}
          isLoading={isSubmitting}
          isFormInvalid={isFormInvalid}
          areValidationsEnabled={areValidationsEnabled}
        />
      </Form>
    </>
  );
};

export default PlanificacionFormComponent;
