import { Archivo, ProcesoPhotoRequest } from "../../models/Archivos";
import { Evaluacion, EvaluacionForm, EvaluacionRequestBody } from "../../models/Evaluaciones";
import { EvaluacionOrReparacionComponenteEtapa } from "../../models/EvaluacionesReparaciones";
import { OrdenProceso } from "../../models/estatusGeneral/OrdenProceso";
import { titleWithoutIndex } from "../evaluacionesReparaciones/evaluacionesReparaciones";
import { CoordinatesRange, pdfElements } from "../pdfElements";

export const getEmptyEvaluacionForm = (): EvaluacionForm => {
    return {
        fechaInicial: '',
        tot: { label: 'NO', value: { tot: 'NO' } }
    }
}

export const formatEvaluacionRequestBody = (evaluacionForm: EvaluacionForm): EvaluacionRequestBody => {
    return {
        fechaInicial: evaluacionForm.fechaInicial,
        tot: evaluacionForm.tot.value.tot,
        fechaFinal: evaluacionForm.fechaFinal
    }
}

export const formatEvaluacionForm = (evaluacion: Evaluacion): EvaluacionForm => {
    return {
        fechaInicial: evaluacion.fechaInicial,
        fechaFinal: evaluacion.fechaFinal || '',
        tot: { label: evaluacion.tot, value: { tot: evaluacion.tot } }
    }
}

export const formatProcesoPhotoRequest = (etapa: string, tarea: string, variable: string, realizadoPor?: string, imagen?: Archivo): ProcesoPhotoRequest => ({
    nombreEtapa: etapa,
    nombreTarea: tarea,
    nombreVariable: variable,
    realizadoPor: realizadoPor,
    imagen: imagen
})

// export const componenteTableMeasurements = (tipoComponente: string, tableDetails: {
//     stage: string,
//     table: EvaluacionOrReparacionComponenteDetallesTable,
//     taskName: string,
//     variableName: string,
//     tableRowIndex: number,
//     updateTable: (calculationsTableRowElementIndex: number, calculatedValue: string) => void
// }) => {
//     if (tipoComponente === 'BLOWER') {
//         evaluacionBlowerTableCalculations(tableDetails);
//     } else if (tipoComponente === 'MOTOR DE TRACCION')
// }

// export const generarInformeEvaluacionComponente = (ordenProceso: OrdenProceso, finalEtapa: EvaluacionOrReparacionComponenteEtapa, etapas: EvaluacionComponenteEtapaInforme[], fotos?: ProcesoPhotoRequest[], isPreview?: boolean, anexo?: Archivo): void | Attachment => {
//     if (ordenProceso.componente?.tipoComponente === 'BLOWER') {
//         return generarInformeEvaluacionBlower(ordenProceso, finalEtapa, etapas, fotos, isPreview, anexo);
//     } else if (ordenProceso.componente?.tipoComponente === 'MOTOR DE TRACCION') {
//         return generarInformeEvaluacionMotorTraccion(ordenProceso, finalEtapa, etapas, fotos, isPreview, anexo);
//     }
// }

export const generarListadoInsumos = (ordenProceso: OrdenProceso, finalEtapa: EvaluacionOrReparacionComponenteEtapa) => {
    const informesElements = new pdfElements(ordenProceso, finalEtapa, undefined, undefined, undefined, false, true);
    // const docSettings = pdfElements.getPdfSettings();
    // const doc = new jsPDF('p', 'mm', [ docSettings.x, docSettings.y ]);
    const componenteTitle = `${ordenProceso.componente?.tipoComponente.toUpperCase()} ${ordenProceso.componente?.modelo.toUpperCase()}${ordenProceso.recepcion?.subModeloComponente?.toUpperCase()}`;
    // const mainTitleHeight = informesElements.generalFontSize + 2;
    // doc.setLineWidth(0);
    // doc.setDrawColor(0, 0, 0);
    const writeTextInTable = (text: string, yCoordinates: CoordinatesRange, bold: boolean, xCoordinates?: CoordinatesRange) =>
        informesElements.writeTextInCurrentDocumentPage(text, informesElements.generalFontSize, yCoordinates, false, bold, true, xCoordinates, true);
    // Página 1
    // let yCoordinate = docSettings.margin;
    // Título
    informesElements.writeTextInCurrentDocumentPage(
        `LISTADO DE REPUESTOS E INSUMOS ${componenteTitle}`,
        informesElements.generalFontSize + 2,
        { start: informesElements.currentYCoordinate },
        true,
        true,
        false,
    );
    informesElements.newCurrentYCoordinate(informesElements.doc.getTextDimensions(componenteTitle).h);
    // yCoordinate = yCoordinate + doc.getTextDimensions(title).h + docSettings.spaceBetweenElements;
    // Identificación
    const rectangleRowsHeight = 10;
    const rectangleColumnsWidth = informesElements.totalUsableWidth / 4;
    const rectangleHeight = rectangleRowsHeight * 2;
    informesElements.doc.rect(informesElements.margin, informesElements.currentYCoordinate, informesElements.totalUsableWidth, rectangleHeight);
    const rectMargin = 1.5;
    const firstColumnTextXCoordinate = informesElements.margin + rectMargin;
    const firstColumnFinalXCoordinate = informesElements.margin + rectangleColumnsWidth;
    const secondColumnTextXCoordinate = firstColumnFinalXCoordinate + rectMargin;
    const secondColumnFinalXCoordinate = firstColumnFinalXCoordinate + rectangleColumnsWidth;
    const thirdColumnTextXCoordinate = secondColumnFinalXCoordinate + rectMargin;
    const thirdColumnFinalXCoordinate = secondColumnFinalXCoordinate + rectangleColumnsWidth;
    const fourthColumnTextXCoordinate = thirdColumnFinalXCoordinate + rectMargin;
    const firstRowBottomYCoordinate = informesElements.currentYCoordinate + rectangleRowsHeight;
    const firstRowTextYCoordinate = firstRowBottomYCoordinate - rectMargin;
    writeTextInTable('CLIENTE:', { start: firstRowTextYCoordinate }, true, { start: firstColumnTextXCoordinate });
    writeTextInTable(ordenProceso.cliente.toUpperCase(), { start: firstRowTextYCoordinate }, false, { start: secondColumnTextXCoordinate });
    writeTextInTable('FECHA:', { start: firstRowTextYCoordinate }, true, { start: thirdColumnTextXCoordinate });
    writeTextInTable((ordenProceso.evaluacion?.fechaFinal || ''), { start: firstRowTextYCoordinate }, false, { start: fourthColumnTextXCoordinate });
    const secondRowBottomYCoordinate = firstRowBottomYCoordinate + rectangleRowsHeight;
    const secondRowTextYCoordinate = secondRowBottomYCoordinate - rectMargin;
    writeTextInTable('COMPONENTE:', { start: secondRowTextYCoordinate }, true, { start: firstColumnTextXCoordinate });
    writeTextInTable((ordenProceso.componente?.descripcion || '').toUpperCase(), { start: secondRowTextYCoordinate }, false, { start: secondColumnTextXCoordinate });
    writeTextInTable('OP:', { start: secondRowTextYCoordinate }, true, { start: thirdColumnTextXCoordinate });
    writeTextInTable(ordenProceso.codigoOP.toUpperCase(), { start: secondRowTextYCoordinate }, false, { start: fourthColumnTextXCoordinate });
    informesElements.newCurrentYCoordinate(undefined, secondRowBottomYCoordinate);
    // yCoordinate = yCoordinate + rectangleHeight + docSettings.spaceBetweenElements;
    // Tablas insumos
    finalEtapa.tareas.find((tarea) => titleWithoutIndex(tarea.nombre) === 'Listado de insumos')?.insumosLists?.forEach((insumosList) => {
        informesElements.standarTable(
            [{ text: 'P/N ELECTRANS', boldColumn: false }, { text: 'DESCRIPCIÓN', boldColumn: false }, { text: 'CANT. SOLICITADA', boldColumn: false }],
            insumosList.insumos.map((insumo) => ([insumo.electransPartNumber || '', insumo.name, insumo.amountRequested || ''])),
            insumosList.name.toUpperCase()
        )
    });
    informesElements.saveDocument('REPUESTOS E INSUMOS');
}

// export const generarListadoInsumos2 = (ordenProceso: OrdenProceso, finalEtapa: EvaluacionOrReparacionComponenteEtapa) => {
//     const informesElements = new pdfElements(ordenProceso, finalEtapa);
//     const titleFontSize = informesElements.tablesFontSize + 2;
//     interface insumoRowElement {
//         rowsSets: {
//             rows: {
//                 text: string,
//                 bold?: boolean
//                 cellHeightInUnits?: number,
//                 cellsWidthInUnits?: number
//             }[][],
//             rowCellsHeightInUnits?: number,
//             rowsColor?: RGBColor,
//             totalCellsWidthInUnits?: number,
//             allCellsTogether?: boolean
//         }[]
//     },

//     finalEtapa.tareas.find((tarea) => tarea.nombre === 'Listado de insumos')?.insumosLists?.forEach((insumosList) => {
//         const checkSpace = (expectedYCoordinate: number) => {
//             if (expectedYCoordinate > informesElements.y - informesElements.margin) {
//                 informesElements.addPage();
//             }
//         };
//         const insumoElements = ((): any => {
//             const response: {

//             }
//         })();
//         checkSpace(titleFontSize + informesElements.spaceBetweenElements + insumoElements[0].rowCellsHeightInUnits);
//         informesElements.writeTextInCurrentDocumentPage(insumosList.name.toUpperCase(), titleFontSize, { start: informesElements.currentYCoordinate }, true, true, false, { start: informesElements.margin + 6 });
//         informesElements.newCurrentYCoordinate(undefined, titleFontSize);
//     });
// }