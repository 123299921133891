import { ReactElement, useState } from "react";
import { Button, Card, Col, Modal, Row } from "react-bootstrap";
import useFormState from "../../hooks/useFormState";
import { OrdenProceso } from "../../models/estatusGeneral/OrdenProceso";
import { useHistory } from "react-router-dom";
import { formatEvaluacionRequestBody, getEmptyEvaluacionForm } from "../../utils/evaluaciones/evaluaciones";
import useTOTOptions from "../../hooks/useTOTOptions";
import { EvaluacionForm } from "../../models/Evaluaciones";
import { deleteEvaluacion, postEvaluacion } from "../../api/EvaluacionesApi";
import cogoToast from "cogo-toast";
import ConfirmDeletionModal from "../form/ConfirmDeletionModal";
import EvaluacionFormComponent from "./EvaluacionFormComponent";
import { AcceptedRolesElements, AcceptedUsersElements } from "../../models/users/Users";

interface EvaluacionOPProps {
    ordenProceso: OrdenProceso,
    onOrdenProcesoChange: (ordenProceso: OrdenProceso) => void,
    userPermissionFunction: (acceptedRolesElements?: AcceptedRolesElements, acceptedUsersElements?: AcceptedUsersElements) => boolean
}

const EvaluacionOP = ({ordenProceso, onOrdenProcesoChange, userPermissionFunction}: EvaluacionOPProps): ReactElement => {

    const editPermission = userPermissionFunction({ roles: ['JEFETALLER', 'ADMINISTRADOR', 'PLANIFICACION'] });
    const viewPermission = userPermissionFunction({ roles: ['PLANIFICACION', 'ADMINISTRADOR', 'JEFETALLER', 'OPERARIO', 'CALIDAD', 'SUPERVISOR'], notExactRol: true });

    const formState = useFormState(getEmptyEvaluacionForm());
    const history = useHistory();
    const totOptions = useTOTOptions();

    const [show, setShow] = useState(false);
    const handleShow = () => setShow(true);
    const handleClose = () => {
        formState.reset();
        setShow(false);
    };

    // const canItBeAdded = (): boolean => {
    //     if (ordenProceso.estado !== 'NUEVA' && ordenProceso.estado !== 'CERRADA') {
    //         return true;
    //     } else {
    //         return false;
    //     }
    // }

    const submitForm = (evaluacion: EvaluacionForm) => {
        postEvaluacion(formatEvaluacionRequestBody(evaluacion), ordenProceso.codigoOP)
            .then((response) =>  {
                if (response.data.codigoOP) {
                    formState.setSubmitting(false);
                    setShow(false);
                    onOrdenProcesoChange(response.data);
                    cogoToast.success('Evaluación de componente añadida');
                    history.replace(`/ordenesProceso/${response.data.codigoOP}/evaluacion`);
                } else {
                    formState.setSubmitting(false);
                    // cogoToast.error(response.data);
                    cogoToast.error('No fue posible agregar la evaluación');
                }
            })
            .catch(() => {
                formState.setSubmitting(false);
                // cogoToast.error(error);
                cogoToast.error('No fue posible agregar la evaluación');
            })
    }

    const showEvaluacion = () => {
        history.push(`/ordenesProceso/${ordenProceso.codigoOP}/evaluacion`);
    }

    const deleteCurrentEvaluacion = (setIsDeleting: () => void) => {
        deleteEvaluacion(ordenProceso.codigoOP)
            .then((response) => {
                if (response.data.codigoOP) {
                    onOrdenProcesoChange(response.data);
                    cogoToast.success('Evaluación eliminada');
                } else {
                    cogoToast.error('No fue posible eliminar la evaluación');
                }
                setIsDeleting();
            })
            .catch(() => {
                // cogoToast.error(error);
                cogoToast.error('No fue posible eliminar la evaluación');
                setIsDeleting();
            })
    }

    return (
        <>
            <Card className = "cards-container">
                <Card.Header className = "cards-header">
                    <div className = "body-subtitle">
                        {ordenProceso.evaluacion ? 'Evaluación' : 'Sin evaluación'}
                    </div>
                    {ordenProceso.evaluacion ?
                        <div className = "buttons-group">
                            {editPermission && !ordenProceso.reparacion && ordenProceso.estado === 'RECEPCIONADA' && (
                                <ConfirmDeletionModal
                                    text = 'Si elimina la evaluación también se borrarán los informes de evaluación asociados'
                                    onDelete = {deleteCurrentEvaluacion}
                                />
                            )}
                            {viewPermission &&
                                <Button onClick = {showEvaluacion} variant = "success" className = "body-buttons">
                                    Detalles
                                </Button>
                            }
                        </div>
                    :
                        editPermission &&
                            <div className = "buttons-group">
                                <Button onClick = {handleShow} disabled = {(ordenProceso.estado === 'NUEVA' || ordenProceso.estado === 'CERRADA')}>
                                    Agregar Evaluación
                                </Button>
                            </div>
                    }
                </Card.Header>
                {ordenProceso.evaluacion &&
                    (<Card.Body>
                        <Row>
                            <Col>
                                <u>Fecha inicial</u>
                                <h3>{ordenProceso.evaluacion.fechaInicial}</h3>
                            </Col>
                            <Col>
                                <u>Fecha final</u>
                                <h3>{ordenProceso.evaluacion.fechaFinal}</h3>
                            </Col>
                            <Col>
                                <u>T.O.T</u>
                                <h3>{ordenProceso.evaluacion.tot}</h3>
                            </Col>
                        </Row>
                    </Card.Body>)
                }
            </Card>
            {show && ordenProceso.recepcion?.fechaRecepcion &&
                (<Modal show = {show} onHide = {handleClose}>
                    <Modal.Header>
                        <Col className = "text-center">
                            <Modal.Title>Evaluación de Componente</Modal.Title>
                        </Col>
                    </Modal.Header>
                    <Modal.Body>
                        <EvaluacionFormComponent
                            formState = {formState}
                            submitForm = {submitForm}
                            onCancel = {handleClose}
                            totOptions = {totOptions}
                            minDateEvaluacion = {ordenProceso.recepcion?.fechaRecepcion}
                            withDesarmeEvaluacion = {ordenProceso.desarmeEvaluacionComponente}
                            isCreating
                        />
                    </Modal.Body>
                </Modal>
            )}
        </>
    );
}

export default EvaluacionOP;