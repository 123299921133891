import cogoToast from "cogo-toast";
import { ReactElement, useEffect, useState } from "react";
import { Spinner } from "react-bootstrap";

import { getDesviaciones } from "../../api/DesviacionesApi";
import Table, { TableColumn } from "../form/table/Table";
import {
  DesviacionesFilters,
  DesviacionTabla,
} from "../../models/desviaciones/Desviaciones";
import DesviacionIndicadores from "./DesviacionIndicadores";

interface DesviacionesListProps {
  desviacionesFilters: DesviacionesFilters;
  setDesviacionesFilters: (desviacionesFilters: DesviacionesFilters) => void;
}

const DesviacionesList = ({
  desviacionesFilters,
  setDesviacionesFilters,
}: DesviacionesListProps): ReactElement => {
  const [desviaciones, setDesviaciones] = useState<DesviacionTabla[]>([]);
  const [totalPages, setTotalPages] = useState<number>();
  const [isLoading, setIsLoading] = useState<boolean>(true);

  useEffect(() => {
    getDesviaciones(desviacionesFilters)
      .then((response) => {
        if (response.data.desviaciones) {
          setDesviaciones(response.data.desviaciones);
          setTotalPages(response.data.totalPages);
        } else {
          cogoToast.error("No fue posible obtener las desviaciones");
        }
        setIsLoading(false);
      })
      .catch(() => {
        cogoToast.error("No fue posible obtener las desviaciones");
        setIsLoading(false);
      });
  }, [desviacionesFilters]);

  const tableColumns: TableColumn<DesviacionTabla>[] = [
    {
      title: "Código",
      accessor: "codigoOP",
    },
    {
      title: "Galpón",
      accessor: "galpon",
    },
    {
      title: "Proceso",
      accessor: "proceso",
    },
    {
      title: "Dimensión",
      accessor: "dimension",
    },
    {
      title: "Motivo",
      accessor: "motivo",
    },
    {
      title: "Fecha inicio",
      accessor: "fechaInicio",
    },
    {
      title: "Fecha término",
      accessor: "fechaTermino",
    },
    {
      title: "Estado",
      accessor: "estado",
    },
  ];

  if (!desviaciones) {
    if (isLoading) {
      return (
        <div className="text-center">
          <Spinner animation="border" />
        </div>
      );
    } else {
      return (
        <p className="text-center">No fue posible cargar las desviaciones</p>
      );
    }
  } else {
    return (
      <>
        <DesviacionIndicadores desviaciones={desviaciones} />
        <Table
          hover
          items={desviaciones}
          columns={tableColumns}
          pages={totalPages}
          activePage={desviacionesFilters.page}
          onPageSelect={(page) =>
            setDesviacionesFilters({ ...desviacionesFilters, page: page })
          }
        />
      </>
    );
  }
};

export default DesviacionesList;
