import { ReactElement, ReactText } from "react";
import { SelectOption } from "../../models/optionsFilters/Select";
import TipoMes from "../../models/planificacionesERP/TipoMes";
import Select from "../form/select/Select";

interface MesFilterComponentProps {
  mesesOptions: SelectOption<TipoMes>[];
  onMesChange: (mes: string) => void;
}

const MesFilterComponent = ({
  mesesOptions,
  onMesChange,
}: MesFilterComponentProps): ReactElement => {
  const handleFilterChange = (name: string, value?: ReactText): void => {
    onMesChange(value ? value.toString() : "");
  };

  return (
    <Select
      title="Seleccione un mes"
      name="mes"
      options={mesesOptions}
      onSelectWithName={(name, mes) => {
        handleFilterChange(name, mes.value.mes.toString());
      }}
      onClearWithName={(name) => handleFilterChange(name, "")}
    />
  );
};

export default MesFilterComponent;
