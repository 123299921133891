import { ReactElement, useState } from "react";
import { Persona, UsersFilters, UserTabla } from "../../models/users/Users";
import { getEmptyUsersFilters } from "../../utils/users";
import { Button, Card, Col, Modal, Row, Spinner } from "react-bootstrap";
import UsersFiltersComponent from "./UsersFiltersComponent";
import UsersCheckList from "./UsersCheckList";
import Table, { TableColumn } from "../form/table/Table";
import { SelectOption } from "../../models/optionsFilters/Select";

interface Props {
    title: string,
    userPermission: boolean,
    subjectsName?: string,
    existingPersonas: Persona[],
    currentPersonas?: Persona[],
    addPersonas: (personas: Persona[], hideModal: () => void, setStateToFalse: () => void) => void,
    deletePersona: (persona: Persona, hideModal: () => void, setStateToFalse: () => void) => void,
    rolUsuarioOptions: SelectOption<string>[],
    isLoadingAddresses?: boolean,
    addressLoadFailed?: boolean,
    isUpdatingTarea?: boolean,
    isNotCard?: boolean
    isButtonDisabled?: boolean,
    isWaiting?: boolean,
    // disponibilidadOptions?: SelectOption<string>[],
    disponibilidadOptions?: boolean,
    // evaulacionComponenteResumenEtapa: EvaluacionComponenteResumenEtapa[],
    // codigoOP: string,
    // changeEvaulacionComponenteResumenEtapa: (evaulacionComponenteResumen: EvaluacionComponenteResumenEtapa) => void
    isEmailRequired?: boolean
}

const EditPersonasComponent = ({
    title,
    subjectsName,
    existingPersonas,
    currentPersonas,
    addPersonas,
    deletePersona,
    rolUsuarioOptions,
    isNotCard,
    isLoadingAddresses,
    addressLoadFailed,
    isUpdatingTarea,
    userPermission,
    isButtonDisabled,
    isWaiting,
    disponibilidadOptions,
    isEmailRequired
}: Props): ReactElement => {

    const [ usersFilters, setUsersFilters ] = useState<UsersFilters>(getEmptyUsersFilters({
        roles: rolUsuarioOptions,
        existingUsers: existingPersonas,
        isEmailRequired: isEmailRequired,
        isEvaluacionDesarmeReparacionArmado: disponibilidadOptions
        // evaulacionComponenteResumenEtapa[0].participantes && evaulacionComponenteResumenEtapa[0].participantes.map((participante) => participante.email),
    }));
    const [ showAgregarPersona, setShowAgregarPersona ] = useState<boolean>(false);
    // const estadoUsuariosOptions = useEstadoUsuarioOptions();
    // const rolUsuarioOptions = useRolUsuarioOptions('evaluacion');
    const [ usersSelected, setUsersSelected ] = useState<UserTabla[]>([]);
    const [ isAddingParticipantes, setIsAddingParticipantes ] = useState<boolean>(false);
    const [ personaSelectedDeleting, setPersonaSelectedDeleting ] = useState<Persona>();
    const [ showRemoverParticipante, setShowRemoverParticipante ] = useState<boolean>(false);
    const [ isDeletingParticipante, setIsDeletingParticipante ] = useState<boolean>(false);

    const hideRemoverParticipante = () => setShowRemoverParticipante(false);
    const hideAgregarParticipante = () => {
        setUsersSelected([]);
        setShowAgregarPersona(false);
    };
    const changeUsersSelected = (newUsersSelected: UserTabla[]) => setUsersSelected(newUsersSelected);

    const selectPersonaForDeletion = (persona: Persona) => {
        setPersonaSelectedDeleting(persona);
        setShowRemoverParticipante(true);
    }

    const handleAddPersonas = () => {
        if (usersSelected.length > 0) {
            setIsAddingParticipantes(true);
            addPersonas(
                usersSelected.map((user) => ({ name: `${user.nombre} ${user.apellidos}`, email: user.email, rut: user.rut })),
                hideAgregarParticipante,
                () => setIsAddingParticipantes(false)
            );
        }
    };

    const handleDeleteParticipante = () => {
        if (personaSelectedDeleting) {
            setIsDeletingParticipante(true);
            deletePersona(personaSelectedDeleting, hideRemoverParticipante, () => setIsDeletingParticipante(false));
        }
    }

    const tableColumns: TableColumn<Persona>[] = ((): TableColumn<Persona>[] => {
        let response: TableColumn<Persona>[] = [
            {
                title: 'Nombre',
                accessor: 'name'
            }
        ];
        if (disponibilidadOptions) {
            response.push({
                title: 'RUT',
                accessor: 'rut'
            });
        }
        response.push({
            title: 'Email / ID',
            accessor: 'email',
            hideUntil: 'sm'
        });
        return response;
    })();

    const getTableHTML = (): ReactElement => {
        if (isWaiting) {
            return <div className = "text-center">En espera de parámetros ...</div>
        } else if (isLoadingAddresses) {
            if (addressLoadFailed) {
                return <>No fue posible cargar los {subjectsName || title}</>
            } else {
                return <div className = "text-center">Cargando {subjectsName || title} ...<Spinner animation = "border" size = "sm"/></div>
            }
        } else {
            const items = currentPersonas || existingPersonas;
            return (
                <Table
                    hover = {items.length > 0 && userPermission && !isButtonDisabled && !isUpdatingTarea}
                    items = {items}
                    columns = {tableColumns}
                    onItemClick = {(items.length > 0 && userPermission) ? selectPersonaForDeletion : undefined}
                    // pages = {totalPages}
                    // activePage = {usersFilters.page}
                    // onPageSelect = {(page) => setUsersFilters({ ...usersFilters, page: page})}
                />
            )
        }
    }

    const getButtonHTML = (): ReactElement => {
        if (addressLoadFailed) {
            return <></>
        } else {
            return (
                <>
                    {userPermission &&
                        <div className = "buttons-group">
                            <Button onClick = {() => setShowAgregarPersona(true)} disabled = {isLoadingAddresses || isUpdatingTarea || isButtonDisabled}>
                                {/* {isOnTask ? <Spinner animation="border" size="sm" /> : `Agregar ${subjectsName}`} */}
                                {`Agregar ${subjectsName || title}`}
                            </Button>
                        </div>
                    }
                </>
            );
        }
    }

    const getModalText = (modalTask: string): string => {
        if (!subjectsName || subjectsName === title) {
            return (`${modalTask} ${title}`);
        } else {
            return (`${title}: ${modalTask} ${subjectsName}`);
        }
    }

    return (
        <>
            {!isNotCard ?
                <Card className = "cards-container">
                    <Card.Header className = "cards-header">
                        <div className = "body-subtitle">
                            {title}:
                        </div>
                        {getButtonHTML()}
                    </Card.Header>
                    <Card.Body className = "cards-body-groups">
                        {getTableHTML()}
                    </Card.Body>
                </Card> :
                <Card>
                    <Card.Body className = "cards-body-groups">
                        <div className = "cards-header">
                            <div className = "body-subtitle">
                                {title}:
                            </div>
                            {getButtonHTML()}
                        </div>
                        <div className = "cards-body-groups">
                            {getTableHTML()}
                        </div>
                    </Card.Body>
                </Card>
            }
            {showAgregarPersona &&
                <Modal show = {showAgregarPersona} onHide = {hideAgregarParticipante}>
                    <Modal.Header>
                        <Col className = "text-center">
                            <Modal.Title>{getModalText('Agregar')}</Modal.Title>
                        </Col>
                    </Modal.Header>
                    <Modal.Body>
                        <UsersFiltersComponent
                            // estadoUsuariosOptions = {estadoUsuariosOptions}
                            isEvaluacionDesarmeReparacionArmado = {disponibilidadOptions}
                            isEmailRequired = {isEmailRequired}
                            rolUsuariosOptions = {rolUsuarioOptions}
                            usersFilters = {usersFilters}
                            onFiltersChange = {setUsersFilters}
                            existingPersonas = {existingPersonas}
                        />
                        <UsersCheckList
                            usersFilters = {usersFilters}
                            changeUsersFilters = {(filters: UsersFilters) => setUsersFilters(filters)}
                            usersSelected = {usersSelected}
                            changeUsersSelected = {changeUsersSelected}
                        />
                        <div className = "modal-buttons">
                            <Button variant = "success" onClick = {handleAddPersonas} disabled = {isAddingParticipantes || !usersSelected}>
                                {isAddingParticipantes ? <Spinner animation="border" size="sm" /> : 'Guardar'}
                            </Button>
                            <Button variant = "secondary" onClick = {hideAgregarParticipante} disabled = {isAddingParticipantes}>
                                {isAddingParticipantes ? <Spinner animation="border" size="sm" /> : 'Cancelar'}
                            </Button>
                        </div>
                    </Modal.Body>
                </Modal>
            }
            {showRemoverParticipante && personaSelectedDeleting && 
                (<Modal show = {showRemoverParticipante} onHide = {hideRemoverParticipante}>
                    <Modal.Header>
                        <Col className = "text-center">
                            <Modal.Title>{getModalText('Remover')}</Modal.Title>
                        </Col>
                    </Modal.Header>
                    <Modal.Body>
                        <Row>
                            <Col>
                                <h5>{personaSelectedDeleting.name}</h5>
                            </Col>
                            <Col>
                                <h5>{personaSelectedDeleting.email}</h5>
                            </Col>
                        </Row>
                        <div className = "modal-buttons">
                            <Button variant = "danger" onClick = {handleDeleteParticipante} disabled = {isDeletingParticipante}>
                                {isDeletingParticipante ? <Spinner animation="border" size="sm" /> : 'Eliminar'}
                            </Button>
                            <Button variant = "secondary" onClick = {hideRemoverParticipante} disabled = {isDeletingParticipante}>
                                {isDeletingParticipante ? <Spinner animation="border" size="sm" /> : 'Cancelar'}
                            </Button>
                        </div>
                    </Modal.Body>
                </Modal>)
            }
        </>
    )
}

export default EditPersonasComponent;