import { ReactElement } from "react";
import { FormState } from "../../hooks/useFormState";
import { DesviacionForm } from "../../models/desviaciones/Desviaciones";
import TipoDimension from "../../models/desviaciones/TipoDimension";
import { SelectOption } from "../../models/optionsFilters/Select";
import TipoGalpon from "../../models/planificacionesERP/TipoGalpon";
import { Form, Row } from "react-bootstrap";
import Select from "../form/select/Select";
import FormButtons from "../form/buttons/FormButtons";
import TipoMotivo from "../../models/desviaciones/TipoMotivo";
import { Input } from "../form/input/Input";

interface DesviacionFormComponentProps {
  formState: FormState<DesviacionForm>;
  submitForm: (DesviacionForm: DesviacionForm) => void;
  onDelete?: () => void;
  onCancel: () => void;
  dimensionDesviacionOptions: SelectOption<TipoDimension>[];
  galponDesviacionOptions: SelectOption<TipoGalpon>[];
  motivoPersonalOptions: SelectOption<TipoMotivo>[];
  motivoHerramientasOptions: SelectOption<TipoMotivo>[];
  motivoTOTOptions: SelectOption<TipoMotivo>[];
  motivoEquipoApoyoOptions: SelectOption<TipoMotivo>[];
  motivoInfraestructuraOptions: SelectOption<TipoMotivo>[];
  motivoRepuestosOptions: SelectOption<TipoMotivo>[];
  motivoProcedimientosOptions: SelectOption<TipoMotivo>[];
  isCreating?: boolean;
}

const DesviacionFormComponent = ({
  formState,
  submitForm,
  onDelete,
  onCancel,
  dimensionDesviacionOptions,
  motivoPersonalOptions,
  motivoHerramientasOptions,
  motivoTOTOptions,
  motivoEquipoApoyoOptions,
  motivoInfraestructuraOptions,
  motivoRepuestosOptions,
  motivoProcedimientosOptions,
  galponDesviacionOptions,
  isCreating,
}: DesviacionFormComponentProps): ReactElement => {
  const {
    value: desviacionForm,
    handleFieldChange,
    areValidationsEnabled,
    setAreValidationsEnabled,
    isSubmitting,
    setSubmitting,
    isConfirmingDelete,
    setConfirmingDelete,
  } = formState;

  const isFormInvalid = !desviacionForm.dimension || !desviacionForm.motivo;

  const handleSubmitClick = (
    event: React.MouseEvent<HTMLElement, MouseEvent>
  ) => {
    event.preventDefault();
    setAreValidationsEnabled(true);
    if (isFormInvalid) return;
    setSubmitting(true);
    setAreValidationsEnabled(false);
    submitForm(desviacionForm);
  };

  const handleDeleteClick = () => setConfirmingDelete(true);
  const cancelDelete = () => setConfirmingDelete(false);

  const getMotivoOptions = (): SelectOption<TipoMotivo>[] => {
    if (!desviacionForm.dimension) return [];

    switch (desviacionForm.dimension.value.dimension) {
      case "PERSONAL":
        return motivoPersonalOptions;
      case "HERRAMIENTAS":
        return motivoHerramientasOptions;
      case "TOT":
        return motivoTOTOptions;
      case "EQUIPO APOYO":
        return motivoEquipoApoyoOptions;
      case "INFRAESTRUCTURA":
        return motivoInfraestructuraOptions;
      case "REPUESTOS":
        return motivoRepuestosOptions;
      case "PROCEDIMIENTOS":
        return motivoProcedimientosOptions;
      default:
        return [];
    }
  };

  // const isEditting = (): boolean => {
  //   if (isCreating) {
  //     return isSubmitting;
  //   } else {
  //     return true;
  //   }
  // };

  return (
    <>
      <Form>
        <Row>
          <Select
            title="Dimensión"
            name="dimension"
            value={desviacionForm.dimension}
            options={dimensionDesviacionOptions}
            onSelectWithName={handleFieldChange}
          />
        </Row>
        <Row>
          {desviacionForm.dimension.value.dimension === "OTROS" ? (
            <Input
              title="Motivo"
              name="motivo"
              value={desviacionForm.motivo.value.motivo}
              type="textarea"
              onChangeWithName={handleFieldChange}
              invalid={
                areValidationsEnabled && !desviacionForm.motivo.value.motivo
              }
              invalidText="Ingrese motivo"
              placeholder="Obligatorio"
            />
          ) : (
            <Select
              title="Motivo"
              name="motivo"
              value={desviacionForm.motivo}
              options={getMotivoOptions()}
              onSelectWithName={handleFieldChange}
            />
          )}
        </Row>
        <FormButtons
          submitButtonContent="Guardar"
          onSubmitClick={handleSubmitClick}
          onCancelClick={onCancel}
          isConfirmingDelete={isConfirmingDelete}
          onDeleteClick={onDelete ? handleDeleteClick : undefined}
          onDeleteConfirm={onDelete}
          onCancelDelete={cancelDelete}
          deleteButtonContent="Eliminar"
          isDisabled={isSubmitting}
          isLoading={isSubmitting}
          isFormInvalid={isFormInvalid}
          areValidationsEnabled={areValidationsEnabled}
        />
      </Form>
    </>
  );
};

export default DesviacionFormComponent;
