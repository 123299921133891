import { ReactElement, useContext, useEffect, useState } from "react";
import { Button, Col, Modal } from "react-bootstrap";
import { updateEstadoPuestoTrabajo } from "../../api/GalponesApi";
import cogoToast from "cogo-toast";
import { formatUpdatePuestoRequestBody } from "../../utils/galpones";
import {
  PlanificacionesFilters,
  PlanificacionTabla,
} from "../../models/planificacionesERP/PlanificacionERP";
import { getEmptyPlanificacionesFilters } from "../../utils/planificaciones";
import useEstadosPlanificacion from "../../hooks/planificaciones/useEstadosPlanificacion";
import usePrioridadPlanificacion from "../../hooks/planificaciones/usePrioridadPlanificacion";
import useGalponPlanificacion from "../../hooks/planificaciones/useGalponPlanificacion";
import useProcesoPlanificacion from "../../hooks/planificaciones/useProcesoPlanificacion";
import useTiposComponentesOptions from "../../hooks/componentes/useTiposComponentes";
import PlanificacionesFiltersComponent from "../planificaciones/PlanificacionesFiltersComponent";
import PlanificacionesList from "../planificaciones/PlanificacionesList";
import NuevaDesviacion from "../desviaciones/NuevaDesviacion";
import { getUserPermissionFunction } from "../../utils/users";
import useDimensionDesviacion from "../../hooks/desviaciones/useDimensionDesviacion";
import useMotivoPersonal from "../../hooks/desviaciones/useMotivoPersonal";
import useMotivoHerramientas from "../../hooks/desviaciones/useMotivoHerramientas";
import useMotivoTOT from "../../hooks/desviaciones/useMotivoTOT";
import useMotivoEquipoApoyo from "../../hooks/desviaciones/useMotivoEquipoApoyo";
import useMotivoInfraestructura from "../../hooks/desviaciones/useMotivoInfraestructura";
import useMotivoRepuestos from "../../hooks/desviaciones/useMotivoRepuestos";
import useMotivoProcedimientos from "../../hooks/desviaciones/useMotivoProcedimientos";
import AuthContext from "../../contexts/AuthContext";
import { DesviacionDetails } from "../../models/desviaciones/Desviaciones";
import { getDesviacionesList, putDesviacion } from "../../api/DesviacionesApi";
import { formatDesviacion } from "../../utils/desviaciones";
import { format } from "date-fns";

interface UpdatePuestoProps {
  userPermission: boolean;
  onCreated: () => void;
  id: string;
  areaIndex: number;
  puestoIndex: number;
  estado: string;
  codigo: string;
}

const UpdatePuesto = ({
  userPermission,
  onCreated,
  id,
  areaIndex,
  puestoIndex,
  estado,
  codigo,
}: UpdatePuestoProps): ReactElement => {
  const [planificacionesFilters, setPlanificacionesFilters] =
    useState<PlanificacionesFilters>(getEmptyPlanificacionesFilters());
  const estadosPlanificacionOptions = useEstadosPlanificacion();
  const prioridadPlanificacionOptions = usePrioridadPlanificacion();
  const galponPlanificacionOptions = useGalponPlanificacion();
  const procesoPlanificacionOptions = useProcesoPlanificacion();
  const tiposComponentesOptions = useTiposComponentesOptions();

  const dimensionDesviacionOptions = useDimensionDesviacion();
  const motivoPersonalOptions = useMotivoPersonal();
  const motivoHerramientasOptions = useMotivoHerramientas();
  const motivoTOTOptions = useMotivoTOT();
  const motivoEquipoApoyoOptions = useMotivoEquipoApoyo();
  const motivoInfraestructuraOptions = useMotivoInfraestructura();
  const motivoRepuestosOptions = useMotivoRepuestos();
  const motivoProcedimientosOptions = useMotivoProcedimientos();

  const { userRoles } = useContext(AuthContext);

  const [show, setShow] = useState(false);
  const handleClose = () => setShow(false);
  const handleShow = () => setShow(true);

  const [showDesviacion, setShowDesviacion] = useState(false);
  const handleCloseDesviacion = () => setShowDesviacion(false);
  const handleShowDesviacion = () => setShowDesviacion(true);

  const [desviaciones, setDesviaciones] = useState<DesviacionDetails[]>([]);

  const planificacionChange = (
    planificacionesFilters: PlanificacionesFilters
  ) => {
    setPlanificacionesFilters(planificacionesFilters);
  };

  const submitForm = (newEstado: string, newPlanificacionId?: string) => {
    updateEstadoPuestoTrabajo(
      formatUpdatePuestoRequestBody(
        id,
        areaIndex,
        puestoIndex,
        newEstado,
        codigo,
        newPlanificacionId
      )
    )
      .then((response) => {
        if (response.data) {
          cogoToast.success("Puesto de trabajo actualizado");
          onCreated();
        } else {
          cogoToast.error("No fue posible actualizar el puesto de trabajo");
        }
      })
      .catch(() => {
        cogoToast.error("No fue posible actualizar el puesto de trabajo");
      });
  };

  const addPlanificacionToPuesto = (planificacion: PlanificacionTabla) => {
    submitForm("OCUPADO", planificacion._id);
    handleClose();
  };

  const handleTerminar = () => {
    submitForm("DISPONIBLE", undefined);
  };

  const handleFueraDeServicio = () => {
    submitForm("FUERA DE SERVICIO", undefined);
    handleShowDesviacion();
  };

  const handleCreated = () => {
    handleCloseDesviacion();
  };

  const handleEstadoDesviacion = () => {
    const desviacion = desviaciones.find(
      (d) => d.codigoOP === codigo && d.estado === "PENDIENTE"
    );
    putDesviacion(
      formatDesviacion(desviacion!._id, {
        fechaTermino: format(new Date(), "dd/MM/yyyy"),
        estado: "SOLUCIONADA",
      })
    )
      .then((response) => {
        if (response.data._id) {
          cogoToast.success("Desviación solucionada");
        } else {
          cogoToast.error("No fue posible solucionar la desviación");
        }
      })
      .catch(() => {
        cogoToast.error("No fue posible solucionar la desviación");
      });
  };

  useEffect(() => {
    const fetchData = async () => {
      try {
        const response = await getDesviacionesList();
        setDesviaciones(response.data);
      } catch (error) {
        console.error(error);
        cogoToast.error("Error al cargar los galpones");
      }
    };

    fetchData();
  }, [codigo]);

  return (
    <>
      {userPermission && (
        <div className="buttons-group">
          {estado === "DISPONIBLE" && (
            <>
              <Button onClick={handleShow}>Asignar</Button>
              <Button onClick={handleFueraDeServicio} variant="danger">
                Fuera de Servicio
              </Button>
            </>
          )}
          {estado === "OCUPADO" && (
            <>
              <Button onClick={handleTerminar} variant="success">
                Terminado
              </Button>
              <Button onClick={handleFueraDeServicio} variant="danger">
                Fuera de Servicio
              </Button>
            </>
          )}
          {estado === "FUERA DE SERVICIO" && (
            <Button
              onClick={() => {
                handleTerminar();
                handleEstadoDesviacion();
              }}
              variant="success"
            >
              Terminado
            </Button>
          )}
        </div>
      )}
      {show && (
        <Modal show={show} onHide={handleClose}>
          <Modal.Header>
            <Col className="text-center">
              <Modal.Title>Seleccione una planificación</Modal.Title>
            </Col>
          </Modal.Header>
          <Modal.Body>
            <div>
              <PlanificacionesFiltersComponent
                planificacionesFilters={planificacionesFilters}
                tiposComponentesOptions={tiposComponentesOptions}
                estadosPlanificacionOptions={estadosPlanificacionOptions}
                prioridadPlanificacionOptions={prioridadPlanificacionOptions}
                galponPlanificacionOptions={galponPlanificacionOptions}
                procesoPlanificacionOptions={procesoPlanificacionOptions}
                onFiltersChange={setPlanificacionesFilters}
              />
            </div>
            <PlanificacionesList
              planificacionesFilters={planificacionesFilters}
              setPlanificacionesFilters={planificacionChange}
              addPlanificacionToPuesto={addPlanificacionToPuesto}
              isModal
            />
          </Modal.Body>
        </Modal>
      )}

      {showDesviacion && (
        <Modal show={showDesviacion} onHide={handleCloseDesviacion}>
          <Modal.Header>
            <Col className="text-center">
              <Modal.Title>Nueva Desviación</Modal.Title>
            </Col>
          </Modal.Header>
          <Modal.Body>
            <NuevaDesviacion
              dimensionDesviacionOptions={dimensionDesviacionOptions}
              galponDesviacionOptions={galponPlanificacionOptions}
              motivoPersonalOptions={motivoPersonalOptions}
              motivoHerramientasOptions={motivoHerramientasOptions}
              motivoTOTOptions={motivoTOTOptions}
              motivoEquipoApoyoOptions={motivoEquipoApoyoOptions}
              motivoInfraestructuraOptions={motivoInfraestructuraOptions}
              motivoProcedimientosOptions={motivoProcedimientosOptions}
              motivoRepuestosOptions={motivoRepuestosOptions}
              userPermission={getUserPermissionFunction(userRoles)({
                roles: ["ADMINISTRADOR", "PLANIFICACION"],
              })}
              id={undefined}
              codigo={codigo}
              onDesviacionCreated={handleCreated}
            />
          </Modal.Body>
        </Modal>
      )}
    </>
  );
};

export default UpdatePuesto;
