import { format } from "date-fns";
import {
  ServicioExternoForm,
  ServicioExternoRequestBody,
  ServiciosExternosFilters,
} from "../models/serviciosExternos/ServicioExterno";

export const getEmptyServiciosExternosFilters =
  (): ServiciosExternosFilters => ({
    page: 1,
  });

export const getEmptyServicioExternoForm = (): ServicioExternoForm => ({
  galpon: { label: "", value: { galpon: "" } },
  motivo: "",
});

export const formatServicioExternoRequestBody = (
  personal: string[],
  servicioExternoForm: ServicioExternoForm
): ServicioExternoRequestBody => ({
  personal: personal,
  galpon: servicioExternoForm.galpon.value.galpon,
  motivo: servicioExternoForm.motivo,
  fechaInicio: format(new Date(), "dd/MM/yyyy"),
  estado: "PENDIENTE",
});

export const formatServicioExterno = (
  id: string
): ServicioExternoRequestBody => ({
  _id: id,
  fechaTermino: format(new Date(), "dd/MM/yyyy"),
  estado: "TERMINADO",
});
