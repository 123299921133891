import { SelectOption } from "../../models/optionsFilters/Select";
import TipoEstado from "../../models/planificacionesERP/TipoEstado";

const useEstadoDesviacion = (): SelectOption<TipoEstado>[] => {
  return [
    { label: "PENDIENTE", value: { estado: "PENDIENTE" } },
    { label: "SOLUCIONADA", value: { estado: "SOLUCIONADA" } },
  ];
};

export default useEstadoDesviacion;
