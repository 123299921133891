import TipoDimension from "../../models/desviaciones/TipoDimension";
import { SelectOption } from "../../models/optionsFilters/Select";

const useDimensionDesviacion = (): SelectOption<TipoDimension>[] => {
  return [
    { label: "INFRAESTRUCTURA", value: { dimension: "INFRAESTRUCTURA" } },
    { label: "PERSONAL", value: { dimension: "PERSONAL" } },
    { label: "EQUIPO APOYO", value: { dimension: "EQUIPO APOYO" } },
    { label: "HERRAMIENTAS", value: { dimension: "HERRAMIENTAS" } },
    { label: "TOT", value: { dimension: "TOT" } },
    { label: "REPUESTOS", value: { dimension: "REPUESTOS" } },
    { label: "PROCEDIMIENTOS", value: { dimension: "PROCEDIMIENTOS" } },
    { label: "OTROS", value: { dimension: "OTROS" } },
  ];
};

export default useDimensionDesviacion;
