import { SelectOption } from "../models/optionsFilters/Select";
import {
  IPlanificacionDetails,
  PlanificacionesFilters,
  PlanificacionForm,
  PlanificacionRequestBody,
} from "../models/planificacionesERP/PlanificacionERP";
import TipoGalpon from "../models/planificacionesERP/TipoGalpon";
import TipoMes from "../models/planificacionesERP/TipoMes";
import TipoMotivo from "../models/planificacionesERP/TipoMotivo";
import TipoProceso from "../models/planificacionesERP/TipoProceso";

export const getEmptyPlanificacionesFilters = (): PlanificacionesFilters => ({
  tipo: "",
  prioridad: "asc",
  proceso: "",
  codigoOP: "",
  cliente: "",
  modeloComponente: "",
  fechaInicio: "",
  fechaTermino: "",
  fechaInicioReal: "",
  fechaTerminoReal: "",
  galpon: "",
  estado: "",
  mes: "",
  isMeta: "",
  page: 1,
});

export const getEmptyPlanificacionForm = (): PlanificacionForm => ({
  prioridad: 0,
  valorReparacion: 0,
});

export const formatPlanificacionRequestBody = (
  ordenProceso: string,
  planificacionForm: PlanificacionForm
): PlanificacionRequestBody => ({
  prioridad: planificacionForm.prioridad,
  proceso: planificacionForm.proceso?.value.proceso,
  codigoOP: ordenProceso,
  motivoReparacion: planificacionForm.motivoReparacion?.value.motivo,
  valorReparacion: planificacionForm?.valorReparacion,
  galpon: planificacionForm.galpon?.value.galpon,
  isMeta: planificacionForm.isMeta,
  mes: planificacionForm.mes?.value.mes,
});

export const formatPlanificacion = (
  id: string,
  planificacionForm: PlanificacionForm
): PlanificacionRequestBody => ({
  _id: id,
  prioridad: planificacionForm.prioridad,
  proceso: planificacionForm.proceso?.value.proceso,
  motivoReparacion: planificacionForm.motivoReparacion?.value.motivo,
  valorReparacion: planificacionForm?.valorReparacion,
  galpon: planificacionForm.galpon?.value.galpon,
  isMeta: planificacionForm.isMeta,
  mes: planificacionForm.mes?.value.mes,
});

export const formatPlanificacionEstado = (
  id: string,
  estado: string
): PlanificacionRequestBody => ({
  _id: id,
  estado: estado,
});

export const formatPlanificacionObs = (
  id: string,
  observacion: string
): PlanificacionRequestBody => ({
  _id: id,
  observacion: observacion,
});

export const formatPlanificacionForm = (
  planificacion: IPlanificacionDetails,
  galponPlanificacionOptions: SelectOption<TipoGalpon>[],
  procesoPlanificacionOptions: SelectOption<TipoProceso>[],
  motivoReparacionOptions: SelectOption<TipoMotivo>[],
  mesPlanificacionOptions: SelectOption<TipoMes>[],
): PlanificacionForm => {
  const galpon = galponPlanificacionOptions.find(
    (galpon) => galpon.value.galpon === planificacion.galpon
  );
  const proceso = procesoPlanificacionOptions.find(
    (proceso) => proceso.value.proceso === planificacion.proceso
  );
  const motivoReparacion = motivoReparacionOptions.find(
    (motivo) => motivo.value.motivo === planificacion.motivoReparacion
  );
  const mes = mesPlanificacionOptions.find((mes) => mes.value.mes === planificacion.meta.mes);

  return {
    prioridad: planificacion.prioridad,
    valorReparacion: planificacion.valorReparacion,
    galpon: galpon,
    proceso: proceso,
    motivoReparacion: motivoReparacion,
    isMeta: planificacion.meta.isMeta,
    mes: mes,
  };
};
