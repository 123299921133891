import { ReactElement, useState } from "react";
import useFormState from "../../hooks/useFormState";
import { PuestoForm } from "../../models/galpones/Galpon";
import { Button, Col, Modal } from "react-bootstrap";
import { postPuestoTrabajoGalpon } from "../../api/GalponesApi";
import cogoToast from "cogo-toast";
import {
  formatPuestoRequestBody,
  getEmptyPuestoForm,
} from "../../utils/galpones";
import PuestoFormComponent from "./PuestoFormComponent";

interface NuevoPuestoProps {
  userPermission: boolean;
  onCreated: () => void;
  id: string;
  areaIndex: number;
}

const NuevoPuesto = ({
  userPermission,
  onCreated,
  id,
  areaIndex,
}: NuevoPuestoProps): ReactElement => {
  const formState = useFormState(getEmptyPuestoForm());

  const [show, setShow] = useState(false);
  const handleClose = () => {
    setShow(false);
    formState.reset();
  };
  const handleShow = () => setShow(true);

  const submitForm = (puestoForm: PuestoForm) => {
    postPuestoTrabajoGalpon(formatPuestoRequestBody(id, areaIndex, puestoForm))
      .then((response) => {
        if (response.data) {
          cogoToast.success("Puesto de trabajo creado");
          onCreated();
          handleClose();
        } else {
          cogoToast.error("No fue posible crear el puesto de trabajo");
        }
        formState.setSubmitting(false);
      })
      .catch(() => {
        formState.setSubmitting(false);
        cogoToast.error("No fue posible crear el puesto de trabajo");
      });
  };

  return (
    <>
      {userPermission && <Button onClick={handleShow}>Nuevo Puesto</Button>}
      {show && (
        <Modal show={show} onHide={handleClose}>
          <Modal.Header>
            <Col className="text-center">
              <Modal.Title>Nueva Puesto de Trabajo</Modal.Title>
            </Col>
          </Modal.Header>
          <Modal.Body>
            <PuestoFormComponent
              formState={formState}
              submitForm={submitForm}
              onCancel={handleClose}
              isCreating
            />
          </Modal.Body>
        </Modal>
      )}
    </>
  );
};

export default NuevoPuesto;
