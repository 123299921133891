import { ReactElement, useState } from "react";
import useFormState from "../../hooks/useFormState";
import { AreaForm } from "../../models/galpones/Galpon";
import { Button, Col, Modal } from "react-bootstrap";
import { postAreaGalpon } from "../../api/GalponesApi";
import cogoToast from "cogo-toast";
import { formatAreaRequestBody, getEmptyAreaForm } from "../../utils/galpones";
import AreaFormComponent from "./AreaFormComponent";

interface NuevoGalponProps {
  userPermission: boolean;
  onCreated: () => void;
  id: string;
}

const NuevaArea = ({
  userPermission,
  onCreated,
  id,
}: NuevoGalponProps): ReactElement => {
  const formState = useFormState(getEmptyAreaForm());

  const [show, setShow] = useState(false);
  const handleClose = () => {
    setShow(false);
    formState.reset();
  };
  const handleShow = () => setShow(true);

  const submitForm = (areaForm: AreaForm) => {
    postAreaGalpon(formatAreaRequestBody(id, areaForm))
      .then((response) => {
        if (response.data) {
          cogoToast.success("Área creada");
          onCreated();
          handleClose();
        } else {
          cogoToast.error("No fue posible crear el área");
        }
        formState.setSubmitting(false);
      })
      .catch(() => {
        formState.setSubmitting(false);
        cogoToast.error("No fue posible crear el área");
      });
  };

  return (
    <>
      {userPermission && <Button onClick={handleShow}>Nueva Área</Button>}
      {show && (
        <Modal show={show} onHide={handleClose}>
          <Modal.Header>
            <Col className="text-center">
              <Modal.Title>Nueva Área</Modal.Title>
            </Col>
          </Modal.Header>
          <Modal.Body>
            <AreaFormComponent
              formState={formState}
              submitForm={submitForm}
              onCancel={handleClose}
              isCreating
            />
          </Modal.Body>
        </Modal>
      )}
    </>
  );
};

export default NuevaArea;
