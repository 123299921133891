import { Archivo, ProcesoPhotoRequest } from "../../../models/Archivos";
import { OrdenProceso } from "../../../models/estatusGeneral/OrdenProceso";
import { EvaluacionOrReparacionComponenteEtapa, EvaluacionOrReparacionComponenteEtapaInforme } from "../../../models/EvaluacionesReparaciones";
import { ColumnWithValue, getTableCellValueWithUnits, pdfElements } from "../../pdfElements";
import portadaMotorTraccion from "../../../assets/images/motoresTraccion/portadaMotorTraccion.jpg";
import condicionConjuntoPlanetario from "../../../assets/images/motoresTraccion/5GEB25/condicionConjuntoPlanetario.jpg";
import estatorAislacionSurgeTest from "../../../assets/images/motoresElectricos/estatorAislacionSurgeTest.jpg";
import { pruebaLineasFrenosItem } from "../../evaluacionesReparaciones/informesCommonElements/5GEB25";
import { stringToNumber } from "../../inputs";

const informeReparacion5GEB25 = (ordenProceso: OrdenProceso, finalEtapa: EvaluacionOrReparacionComponenteEtapa, etapas: EvaluacionOrReparacionComponenteEtapaInforme[], fotos?: ProcesoPhotoRequest[], isPreview?: boolean, anexo?: Archivo) => {
    const informeElements = new pdfElements(ordenProceso, finalEtapa, etapas, fotos, anexo, true);
    const informeTitle = 'INFORME DE REPARACIÓN\nMOTOR DE TRACCIÓN';
    const anexosEtapa = etapas.find((etapa) => etapa.nombre === 'Anexos');
    const pruebasDinamicasEtapa = etapas.find((etapa) => etapa.nombre === 'Pruebas dinámicas');

    informeElements.addFrontPage(informeTitle, portadaMotorTraccion);

    informeElements.addPage();
    informeElements.identificacionCliente([
        { name: 'WHEELMOTOR S/N', value: informeElements.ordenProceso.numeroSerieComponente || '' },
        { name: 'ESTATOR S/N', value: anexosEtapa?.tareas.find((tarea) => tarea.nombre === 'Datos de estator')?.inputs?.find((input) => input.name === 'Estator S/N')?.value || '' },
        { name: 'ROTOR S/N', value: anexosEtapa?.tareas.find((tarea) => tarea.nombre === 'Datos de rotor')?.inputs?.find((input) => input.name === 'Rotor S/N')?.value || '' },
        { name: 'PIÑÓN SOLAR S/N', value: pruebasDinamicasEtapa?.tareas.find((tarea) => tarea.nombre === 'Instalar piñón solar')?.inputs?.find((input) => input.name === 'Piñón solar S/N')?.value || '' },
        { name: 'OP MOTOR ELÉC.', value: etapas.find((etapa) => etapa.nombre === 'Instalación de motor eléctrico')?.tareas.find((tarea) => tarea.nombre === 'Registrar OP motor eléctrico')?.inputs?.find((input) => input.name === 'OP motor eléctrico')?.value || '' },
        { name: 'TUBO DE TORQUE S/N', value: etapas.find((etapa) => etapa.nombre === 'Armado de tubo torque')?.tareas.find((tarea) => tarea.nombre === 'Limpiar tubo de torque')?.inputs?.find((input) => input.name === 'Tubo de torque N/S')?.value || '' },
        { name: 'OP FRENOS', value: anexosEtapa?.tareas.find((tarea) => tarea.nombre === 'OP de frenos')?.inputs?.find((input) => input.name === 'OP de frenos')?.value || '' },
        { name: 'CUBO DE RUEDA S/N', value: etapas.find((etapa) => etapa.nombre === 'Acondicionamiento cubo de rueda')?.tareas.find((tarea) => tarea.nombre === 'Repaso y solapado de hilos 3/4" y 1”')?.inputs?.find((input) => input.name === 'Cubo de rueda S/N')?.value || '' },
    ], 'G.E.', 'Overhaul');
    informeElements.actividadesEvaluacionOrReparacion(((): string[] => {
        const actividades: string[] = [];
        finalEtapa.tareas.find((tarea) => tarea.nombre === 'Actividades de reparación realizadas')?.finalCheckList?.checkList.forEach((check) => {
            if (check.check) {
                actividades.push(check.name);
            }
        });
        return actividades;
    })());

    informeElements.addPage();
    pruebaLineasFrenosItem(informeElements);
    informeElements.docSubtitleRectangle('CONDICIÓN DE CONJUNTO PLANETARIOS DURANTE LA REPARACIÓN');
    const registroNSTask = etapas.find((etapa) => etapa.nombre === 'Armado de planetarios')?.tareas.find((tarea) => tarea.nombre === 'Dejar registro N/S eje y corona de planetario');
    const nsEjeCoronaTable = registroNSTask?.tables?.find((table) => table.name === '1');
    const getNsEjeCoronaValues = (columnsNameWithValue: ColumnWithValue[], targetColumnName: string) =>
        getTableCellValueWithUnits(columnsNameWithValue, targetColumnName, registroNSTask?.applyElements.apply, nsEjeCoronaTable, undefined, true);
    const medirAxialTask = etapas.find((etapa) => etapa.nombre === 'Instalación de planetarios')?.tareas.find((tarea) => tarea.nombre === 'Medir axial');
    const medirAxialTable = medirAxialTask?.tables?.find((table) => table.name === '1');
    const getMedirAxialValues = (columnsNameWithValue: ColumnWithValue[], targetColumnName: string) =>
        getTableCellValueWithUnits(columnsNameWithValue, targetColumnName, medirAxialTask?.applyElements.apply, medirAxialTable, '"');
    const medicionBacklashTask = etapas.find((etapa) => etapa.nombre === 'Instalación tapa de planetarios')?.tareas.find((tarea) => tarea.nombre === 'Medición de backlash');
    const backlashTable = medicionBacklashTask?.tables?.find((table) => table.name === 'Backlash');
    const getBacklashValues = (columnsNameWithValue: ColumnWithValue[], targetColumnName: string) =>
        getTableCellValueWithUnits(columnsNameWithValue, targetColumnName, medicionBacklashTask?.applyElements.apply, backlashTable, '"');
    const backlashA = getBacklashValues([{ column: 'Elemento', value: 'Backlash A' }], 'Valor (" pulgadas)');
    const backlashB = getBacklashValues([{ column: 'Elemento', value: 'Backlash B' }], 'Valor (" pulgadas)');
    const backlashC = getBacklashValues([{ column: 'Elemento', value: 'Backlash C' }], 'Valor (" pulgadas)');
    informeElements.addTable([
        {
            rows: [[
                { text: 'Planetario', color: informeElements.titleCellsFillColor, bold: true },
                { text: 'S/N', color: informeElements.titleCellsFillColor, bold: true },
                { text: 'Backlash', color: informeElements.titleCellsFillColor, bold: true },
                { text: 'Endplay', color: informeElements.titleCellsFillColor, bold: true },
                { text: 'Dif max < 0,100"', color: informeElements.titleCellsFillColor, bold: true },
            ]],
            rowsColor: informeElements.titleCellsFillColor
        },
        {
            rows: [[
                { text: 'A', bold: true, cellHeightInUnits: 2 },
                { text: `Eje: ${getNsEjeCoronaValues([{ column: 'Planetario', value: 'A' }], 'N/S Eje')}\nEng: ${getNsEjeCoronaValues([{ column: 'Planetario', value: 'A' }], 'N/S Corona')}`, cellHeightInUnits: 2 },
                { text: backlashA, cellHeightInUnits: 2 },
                { text: getMedirAxialValues([{ column: 'Elemento', value: 'Axial A' }], 'Valor (" pulgadas)'), cellHeightInUnits: 2 },
                {
                    text: `Diferencia máx.\nBacklash entre\nplanetarios = ${((): string => {
                        const notNANBacklashs = [stringToNumber(backlashA), stringToNumber(backlashB), stringToNumber(backlashC)].filter((backlash) => !Number.isNaN(backlash)).sort((a, b) => a - b);
                        return (notNANBacklashs.length > 1 ? (notNANBacklashs[notNANBacklashs.length - 1] - notNANBacklashs[0]).toString() : 'N/A')
                    })()}`,
                    cellHeightInUnits: 6
                }
            ]]
        },
        {
            rows: [[
                { text: 'B', bold: true },
                { text: `Eje: ${getNsEjeCoronaValues([{ column: 'Planetario', value: 'B' }], 'N/S Eje')}\nEng: ${getNsEjeCoronaValues([{ column: 'Planetario', value: 'B' }], 'N/S Corona')}` },
                { text: backlashB },
                { text: getMedirAxialValues([{ column: 'Elemento', value: 'Axial B' }], 'Valor (" pulgadas)') },
            ]],
            rowCellsHeightInUnits: 2,
            allCellsTogether: true
        },
        {
            rows: [[
                { text: 'C', bold: true },
                { text: `Eje: ${getNsEjeCoronaValues([{ column: 'Planetario', value: 'C' }], 'N/S Eje')}\nEng: ${getNsEjeCoronaValues([{ column: 'Planetario', value: 'C' }], 'N/S Corona')}` },
                { text: backlashC },
                { text: getMedirAxialValues([{ column: 'Elemento', value: 'Axial C' }], 'Valor (" pulgadas)') },
            ]],
            rowCellsHeightInUnits: 2,
            allCellsTogether: true
        }
    ]);
    informeElements.addImage(
        condicionConjuntoPlanetario,
        informeElements.cellsHeight * 8,
        informeElements.totalUsableWidth / 2.5,
        undefined,
        undefined,
        true
    );
    informeElements.docSubtitleRectangle('JUEGO AXIAL RODAMIENTOS CUBO DE RUEDAS');
    informeElements.addTable([
        {
            rows: [[
                { text: 'Juego Axial rodamientos Hub Wheel', color: informeElements.titleCellsFillColor, bold: true },
                { text: `${((): string => {
                    let response = 'N/A';
                    const axialCuboRuedaTask = etapas.find((etapa) => etapa.nombre === 'Instalación de cubo de ruedas')?.tareas.find((tarea) => tarea.nombre === 'Medir axial cubo de rueda');
                    const axialCuboRuedaTable = axialCuboRuedaTask?.tables?.find((table) => table.name === 'Mediciones axial cubo de rueda');
                    const getAxialValues = (columnsNameWithValue: ColumnWithValue[], targetColumnName: string) =>
                        getTableCellValueWithUnits(columnsNameWithValue, targetColumnName, axialCuboRuedaTask?.applyElements.apply, axialCuboRuedaTable, undefined, true);
                    const valores = ((): number[] => {
                        const response: number[] = [];
                        [
                            stringToNumber(getAxialValues([{ column: 'Elemento', value: 'Posición A' }], 'Valor (" pulgadas)')),
                            stringToNumber(getAxialValues([{ column: 'Elemento', value: 'Posición B' }], 'Valor (" pulgadas)')),
                            stringToNumber(getAxialValues([{ column: 'Elemento', value: 'Posición C' }], 'Valor (" pulgadas)')),
                            stringToNumber(getAxialValues([{ column: 'Elemento', value: 'Posición D' }], 'Valor (" pulgadas)'))
                        ].forEach((axial) => {
                            if (!Number.isNaN(axial)) {
                                response.push(axial);
                            }
                        });
                        return response;
                    })();
                    if (valores.length > 0) {
                        let sumaTotal = 0;
                        valores.forEach((valor) => sumaTotal = sumaTotal + valor);
                        response = `${(sumaTotal / valores.length).toString()}” milésimas de pulgadas`;
                    }
                    return response;
                })()}` },
            ]]
        }
    ]);

    informeElements.addPage();
    const datosEstatorTask = etapas.find((etapa) => etapa.nombre === 'Anexos')?.tareas.find((tarea) => tarea.nombre === 'Datos de estator');
    const datosEstatorTable = datosEstatorTask?.tables?.find((table) => table.name === '1');
    const getDatosEstatorValues = (columnsNameWithValue: ColumnWithValue[], targetColumnName: string, unit?: string) =>
        getTableCellValueWithUnits(columnsNameWithValue, targetColumnName, datosEstatorTask?.applyElements.apply, datosEstatorTable, unit);
    informeElements.docSubtitleRectangle('CONJUNTO MOTOR ELÉCTRICO (Ver Reporte de Motor Eléctrico)');
    informeElements.docSubtitleRectangle('ESTATOR', undefined, true);
    informeElements.addParagraph('En el componente se realizan pruebas eléctricas según estándar de normas IEEE 43 y 95 para aislación y Hi-Pot y IEEE 522 para Surge Test. Las pruebas se realizaron con instrumento analizador de motores Schleich MTC-2 de 15 KV y están en detalle en el informe final del motor eléctrico.');
    informeElements.addImage(
        estatorAislacionSurgeTest,
        informeElements.cellsHeight * 6,
        informeElements.cellsHeight * 10,
        undefined,
        undefined,
        true
    );
    informeElements.addParagraph('El resumen de las pruebas eléctricas finales se muestra en la tabla siguiente:');
    informeElements.addTable([
        {
            rows: [
                [
                    { text: 'Fase', color: informeElements.titleCellsFillColor, bold: true },
                    { text: 'Resistencia (mΩ)', color: informeElements.titleCellsFillColor, bold: true },
                    { text: 'Temperatura (°C)', color: informeElements.titleCellsFillColor, bold: true },
                    { text: 'Voltaje Megger (1000V)', color: informeElements.titleCellsFillColor, bold: true },
                    { text: 'I Fuga Megger', color: informeElements.titleCellsFillColor, bold: true },
                ],
                [
                    { text: 'A-B', bold: true },
                    { text: getDatosEstatorValues([{ column: 'Fase', value: 'A-B' }], 'Resistencia (mΩ)') },
                    { text: getDatosEstatorValues([{ column: 'Fase', value: 'A-B' }], 'Temperatura (°C)') },
                    { text: getDatosEstatorValues([{ column: 'Fase', value: 'A-B' }], 'Voltaje Megger (1000V)') },
                    { text: getDatosEstatorValues([{ column: 'Fase', value: 'A-B' }], 'I Fuga Megger') },
                ],
                [
                    { text: 'B-C', bold: true },
                    { text: getDatosEstatorValues([{ column: 'Fase', value: 'B-C' }], 'Resistencia (mΩ)') },
                    { text: getDatosEstatorValues([{ column: 'Fase', value: 'B-C' }], 'Temperatura (°C)') },
                    { text: getDatosEstatorValues([{ column: 'Fase', value: 'B-C' }], 'Voltaje Megger (1000V)') },
                    { text: getDatosEstatorValues([{ column: 'Fase', value: 'B-C' }], 'I Fuga Megger') },
                ],
                [
                    { text: 'A-C', bold: true },
                    { text: getDatosEstatorValues([{ column: 'Fase', value: 'A-C' }], 'Resistencia (mΩ)') },
                    { text: getDatosEstatorValues([{ column: 'Fase', value: 'A-C' }], 'Temperatura (°C)') },
                    { text: getDatosEstatorValues([{ column: 'Fase', value: 'A-C' }], 'Voltaje Megger (1000V)') },
                    { text: getDatosEstatorValues([{ column: 'Fase', value: 'A-C' }], 'I Fuga Megger') },
                ]
            ]
        }
    ]);
    informeElements.rectangleObs('Observaciones estator', informeElements.obsArrayBuilder([datosEstatorTask?.observations]));

    informeElements.conclusionesGenerales();
    informeElements.addPhotosToDoc();
    informeElements.approvingTable(isPreview);

    informeElements.addHeaders(informeTitle, 'ELE-FR/SGC-033');
    informeElements.saveDocument();
}

export default informeReparacion5GEB25;