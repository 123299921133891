import { ReactElement, useContext, useEffect, useState } from "react";
import { PlanificacionesFilters } from "../../models/planificacionesERP/PlanificacionERP";
import { getEmptyPlanificacionesFilters } from "../../utils/planificaciones";
import useBreadcrumb from "../../hooks/useBreadcrumb";
import AuthContext from "../../contexts/AuthContext";
import { Card } from "react-bootstrap";
import PlanificacionesFiltersComponent from "../../components/planificaciones/PlanificacionesFiltersComponent";
import PlanificacionesList from "../../components/planificaciones/PlanificacionesList";
import NuevaPlanificacion from "../../components/planificaciones/NuevaPlanificacion";
import { getUserPermissionFunction } from "../../utils/users";
import useTiposComponentesOptions from "../../hooks/componentes/useTiposComponentes";
import useEstadosPlanificacion from "../../hooks/planificaciones/useEstadosPlanificacion";
import usePrioridadPlanificacion from "../../hooks/planificaciones/usePrioridadPlanificacion";
import useGalponPlanificacion from "../../hooks/planificaciones/useGalponPlanificacion";
import useProcesoPlanificacion from "../../hooks/planificaciones/useProcesoPlanificacion";
import useMotivoReparacion from "../../hooks/planificaciones/useMotivoReparacion";
import { Tab, TabList, TabPanel, Tabs } from "react-tabs";
import GalponesPage from "../galpones/GalponesPage";
import DesviacionesPage from "../desviaciones/DesviacionesPage";
import IndicadoresMetaPage from "./IndicadoresMetaPage";
// import useMesPlanificacion from "../../hooks/planificaciones/useMesPlanificacion";
import useMetaPlanificacion from "../../hooks/planificaciones/useMetaPlanificacion";

const PlanificacionesPage = (): ReactElement => {
  const [planificacionesFilters, setPlanificacionesFilters] =
    useState<PlanificacionesFilters>(getEmptyPlanificacionesFilters());
  const estadosPlanificacionOptions = useEstadosPlanificacion();
  const prioridadPlanificacionOptions = usePrioridadPlanificacion();
  const galponPlanificacionOptions = useGalponPlanificacion();
  const procesoPlanificacionOptions = useProcesoPlanificacion();
  const tiposComponentesOptions = useTiposComponentesOptions();
  const motivoReparacionOptions = useMotivoReparacion();
  // const mesesOptions = useMesPlanificacion();
  const metaOptions = useMetaPlanificacion();

  const breadcrumb = useBreadcrumb();
  const { userRoles } = useContext(AuthContext);

  const planificacionChange = (
    planificacionesFilters: PlanificacionesFilters
  ) => {
    setPlanificacionesFilters(planificacionesFilters);
  };

  useEffect(() => {
    breadcrumb.setItems([
      {
        label: "Planificaciones",
        inactive: true,
      },
    ]);
  }, [breadcrumb]);

  return (
    <Tabs>
      <TabList>
        <Tab>
          <h5>PLANIFICACIONES</h5>
        </Tab>
        <Tab>
          <h5>INDICADORES META</h5>
        </Tab>
        <Tab>
          <h5>DESVIACIONES</h5>
        </Tab>
        <Tab>
          <h5>INFRAESTRUCTURA</h5>
        </Tab>
      </TabList>

      <TabPanel>
        <Card className="cards-container">
          <Card.Body className="cards-body-groups">
            <div className="cards-header">
              <PlanificacionesFiltersComponent
                planificacionesFilters={planificacionesFilters}
                tiposComponentesOptions={tiposComponentesOptions}
                estadosPlanificacionOptions={estadosPlanificacionOptions}
                prioridadPlanificacionOptions={prioridadPlanificacionOptions}
                galponPlanificacionOptions={galponPlanificacionOptions}
                procesoPlanificacionOptions={procesoPlanificacionOptions}
                metaOptions={metaOptions}
                onFiltersChange={setPlanificacionesFilters}
              />
              <div className="filters-buttons">
                <NuevaPlanificacion
                  galponPlanificacionOptions={galponPlanificacionOptions}
                  motivoReparacionOptions={motivoReparacionOptions}
                  userPermission={getUserPermissionFunction(userRoles)({
                    roles: ["ADMINISTRADOR", "PLANIFICACION"],
                  })}
                />
              </div>
            </div>
            <PlanificacionesList
              planificacionesFilters={planificacionesFilters}
              setPlanificacionesFilters={planificacionChange}
            />
          </Card.Body>
        </Card>
      </TabPanel>

      <TabPanel>
        <IndicadoresMetaPage />
      </TabPanel>

      <TabPanel>
        <DesviacionesPage />
      </TabPanel>

      <TabPanel>
        <GalponesPage />
      </TabPanel>
    </Tabs>
  );
};

export default PlanificacionesPage;
