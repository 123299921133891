import { ReactElement } from "react";
import { Form, Row } from "react-bootstrap";
import { FormState } from "../../hooks/useFormState";
import { EvaluacionForm } from "../../models/Evaluaciones";
import { SelectOption } from "../../models/optionsFilters/Select";
import TOT from "../../models/TOT";
import FormButtons from "../form/buttons/FormButtons";
import Select from "../form/select/Select";
import DateSelect from "../form/DateSelect";

interface EvaluacionFormComponentProps {
    formState: FormState<EvaluacionForm>,
    submitForm: (evaluacionForm: EvaluacionForm) => void,
    onDelete?: () => void,
    onCancel: () => void,
    totOptions: SelectOption<TOT>[],
    minDateEvaluacion: string,
    withDesarmeEvaluacion?: boolean,
    isCreating?: boolean
}

const EvaluacionFormComponent = ({ formState, submitForm, onDelete, onCancel, totOptions, minDateEvaluacion, withDesarmeEvaluacion, isCreating }: EvaluacionFormComponentProps): ReactElement => {

    const {
        value: evaluacionForm,
        handleFieldChange,
        areValidationsEnabled,
        setAreValidationsEnabled,
        isSubmitting,
        setSubmitting,
        isConfirmingDelete,
        setConfirmingDelete,
    } = formState;

    
    const handleDeleteClick = () => setConfirmingDelete(true);
    const cancelDelete = () => setConfirmingDelete(false);

    // const isFormInvalid = (): boolean => {
    //     return !evaluacionForm.fechaInicial;
    // }

    const handleSubmitClick = (event: React.MouseEvent<HTMLElement, MouseEvent>) => {
        event.preventDefault();
        setAreValidationsEnabled(true);
        // if (isFormInvalid()) return;
        setSubmitting(true);
        setAreValidationsEnabled(false);
        submitForm(evaluacionForm);
    }

    return (
        <>
            <Form>
                <Row>
                    <DateSelect
                        title = 'Fecha inicial'
                        name = 'fechaInicial'
                        onChangeField = {(fieldName: string, value: unknown) => {
                            handleFieldChange(fieldName, value);
                            if (!value || value === '') {
                                handleFieldChange('fechaFinal', undefined);
                                handleFieldChange('tot', { label: 'NO', value: { tot: 'NO' } });
                            }
                        }}
                        // invalid = {areValidationsEnabled && !evaluacionForm.fechaInicial}
                        selected = {evaluacionForm.fechaInicial}
                        disabled = {isSubmitting}
                        minDate = {minDateEvaluacion}
                        isClearable
                        // placeholder = 'Obligatorio'
                    />
                </Row>
                {!withDesarmeEvaluacion &&
                    <>
                        {!isCreating &&
                            <Row>
                                <DateSelect
                                    title = 'Fecha final'
                                    name = 'fechaFinal'
                                    onChangeField = {handleFieldChange}
                                    // invalid = {areValidationsEnabled && !evaluacionForm.fechaFinal}
                                    selected = {evaluacionForm.fechaFinal}
                                    disabled = {!evaluacionForm.fechaInicial || isSubmitting}
                                    minDate = {evaluacionForm.fechaInicial}
                                    isClearable
                                    // placeholder = 'Obligatorio'
                                />
                            </Row>
                        }
                        <Row>
                            <Select
                                title = "T.O.T."
                                name = "tot"
                                value = {evaluacionForm.tot}
                                onSelectWithName = {handleFieldChange}
                                options = {totOptions}
                                disabled = {!evaluacionForm.fechaInicial || isSubmitting}
                            />
                        </Row>
                    </>
                }
                <FormButtons
                    submitButtonContent="Guardar"
                    onSubmitClick={handleSubmitClick}
                    onCancelClick={onCancel}
                    isConfirmingDelete={isConfirmingDelete}
                    onDeleteClick={onDelete ? handleDeleteClick : undefined}
                    onDeleteConfirm={onDelete}
                    onCancelDelete={cancelDelete}
                    deleteButtonContent="Eliminar"
                    isDisabled={isSubmitting}
                    isLoading={isSubmitting}
                    // isFormInvalid={isFormInvalid()}
                    areValidationsEnabled={areValidationsEnabled}
                />
            </Form>
        </>
    );
}

export default EvaluacionFormComponent;