import TipoMotivo from "../../models/desviaciones/TipoMotivo";
import { SelectOption } from "../../models/optionsFilters/Select";

const useMotivoEquipoApoyo = (): SelectOption<TipoMotivo>[] => {
  return [
    { label: "SIN PUENTE GRÚA", value: { motivo: "SIN PUENTE GRÚA" } },
    { label: "SIN GRÚA HORQUILLA", value: { motivo: "SIN GRÚA HORQUILLA" } },
    {
      label: "SIN BANCO DE PRUEBAS",
      value: { motivo: "SIN BANCO DE PRUEBAS" },
    },
    { label: "FALTA SCHLEICH", value: { motivo: "FALTA SCHLEICH" } },
    { label: "FALTA MULTIMETRO", value: { motivo: "FALTA MULTIMETRO" } },
    { label: "FALTA MICRÓMETRO", value: { motivo: "FALTA MICRÓMETRO" } },
    { label: "FALTA MEGGER", value: { motivo: "FALTA MEGGER" } },
    { label: "FALTA HI-TOR", value: { motivo: "FALTA HI-TOR" } },
    {
      label: "HIDRO LAVADORA DESHABILITADA",
      value: { motivo: "HIDRO LAVADORA DESHABILITADA" },
    },
    {
      label: "FALTA TORQUE HIDRÁULICO",
      value: { motivo: "FALTA TORQUE HIDRÁULICO" },
    },
    { label: "FALTA PIE DE METRO", value: { motivo: "FALTA PIE DE METRO" } },
    {
      label: "FALTA COMPARADOR DE CARATULA",
      value: { motivo: "FALTA COMPARADOR DE CARATULA" },
    },
    { label: "FALTA SCANNER 3D", value: { motivo: "FALTA SCANNER 3D" } },
    {
      label: "FALTA EQUIPO DE VIBRACIONES AIRIUS II",
      value: { motivo: "FALTA EQUIPO DE VIBRACIONES AIRIUS II" },
    },
    { label: "FALTA DINAMÓMETRO", value: { motivo: "FALTA DINAMÓMETRO" } },
    { label: "EN CALIBRACIÓN", value: { motivo: "EN CALIBRACIÓN" } },
  ];
};

export default useMotivoEquipoApoyo;
