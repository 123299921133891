import axios, { AxiosPromise } from "axios";
import {
  ServicioExternoDetails,
  ServicioExternoRequestBody,
  ServicioExternoTablaResponse,
  ServiciosExternosFilters,
} from "../models/serviciosExternos/ServicioExterno";

export function getServiciosExternos(
  requestBody: ServiciosExternosFilters
): AxiosPromise<ServicioExternoTablaResponse> {
  return axios.get("api/serviciosExternos", { params: requestBody });
}

export function postServicioExterno(
  requestBody: ServicioExternoRequestBody
): AxiosPromise<ServicioExternoDetails> {
  return axios.post("api/serviciosExternos", requestBody);
}

export function getServicioExterno(
  requestBody: string
): AxiosPromise<ServicioExternoDetails> {
  return axios.get(`api/serviciosExternos/${requestBody}`);
}

export function putServicioExterno(
  requestBody: ServicioExternoRequestBody
): AxiosPromise<ServicioExternoDetails> {
  return axios.put(`api/serviciosExternos/${requestBody._id}`, requestBody);
}

export function deleteServicioExterno(requestBody: string): AxiosPromise<void> {
  return axios.delete(`api/serviciosExternos/${requestBody}`);
}
