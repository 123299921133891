import { ReactElement, useContext, useEffect, useState } from "react";
import { IPlanificacionDetails } from "../../models/planificacionesERP/PlanificacionERP";
import { useParams } from "react-router-dom";
import useBreadcrumb from "../../hooks/useBreadcrumb";
import AuthContext from "../../contexts/AuthContext";
import { getPlanificacion } from "../../api/PlanificacionesERPApi";
import cogoToast from "cogo-toast";
import { Card, Spinner } from "react-bootstrap";
import PlanificacionDetails from "../../components/planificaciones/PlanificacionDetails";
import { getUserPermissionFunction } from "../../utils/users";

const PlanificacionPage = (): ReactElement => {
  const [planificacion, setPlanificacion] = useState<IPlanificacionDetails>();
  const { id } = useParams<{ id: string }>();
  const breadcrumb = useBreadcrumb();
  const { userRoles } = useContext(AuthContext);
  const [isLoading, setIsLoading] = useState<boolean>(true);

  useEffect(() => {
    if (!planificacion) {
      getPlanificacion(id)
        .then((response) => {
          if (response.data._id) {
            setPlanificacion(response.data);
          } else {
            cogoToast.error("No fue posible obtener la planificación");
          }
          setIsLoading(false);
        })
        .catch(() => {
          cogoToast.error("No fue posible obtener la planificación");
          setIsLoading(false);
        });
    }
  }, [id]);

  useEffect(() => {
    if (planificacion) {
      breadcrumb.setItems([
        {
          label: "Planificaciones",
          href: "/planificacionesERP",
        },
        {
          label: `${planificacion.codigoOP} - ${planificacion.proceso}`,
          inactive: true,
        },
      ]);
    }
  }, [id, planificacion]);

  const handlePlanificacionChange = (
    planificacion: IPlanificacionDetails
  ): void => {
    setPlanificacion(planificacion);
  };

  if (!planificacion) {
    if (isLoading) {
      return (
        <Card>
          <Card.Body className="text-center">
            <Spinner animation="border" />
          </Card.Body>
        </Card>
      );
    } else {
      return (
        <p className="text-center">No fue posible cargar la planificación</p>
      );
    }
  } else {
    return (
      <>
        <PlanificacionDetails
          planificacion={planificacion}
          id={planificacion._id}
          onPlanificacionChange={handlePlanificacionChange}
          userPermission={getUserPermissionFunction(userRoles)({
            roles: ["ADMINISTRADOR", "PLANIFICACION", "JEFETALLER", "SUPERVISOR"],
          })}
        />
      </>
    );
  }
};

export default PlanificacionPage;
