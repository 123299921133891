import axios, { AxiosPromise } from "axios";
import {
  PlanificacionIdResponse,
  IPlanificacionDetails,
  PlanificacionesFilters,
  PlanificacionRequestBody,
  PlanificacionTablaResponse,
} from "../models/planificacionesERP/PlanificacionERP";

export function getPlanificaciones(
  requestBody: PlanificacionesFilters
): AxiosPromise<PlanificacionTablaResponse> {
  return axios.get("api/planificacionesERP", { params: requestBody });
}

export function postPlanificacion(
  requestBody: PlanificacionRequestBody
): AxiosPromise<PlanificacionIdResponse> {
  return axios.post("api/planificacionesERP", requestBody);
}

export function getPlanificacion(
  requestBody: string
): AxiosPromise<IPlanificacionDetails> {
  return axios.get(`api/planificacionesERP/${requestBody}`);
}

export function getPlanificacionesOP(
  requestBody: string
): AxiosPromise<IPlanificacionDetails[]> {
  return axios.get(`api/planificacionesERP/op/${requestBody}`);
}

export function getPlanificacionesMeta(
  requestBody?: string
): AxiosPromise<IPlanificacionDetails[]> {
  return axios.get(`api/planificacionesERP/meta`, {params: {mes: requestBody}});
}

export function putPlanificacion(
  requestBody: PlanificacionRequestBody
): AxiosPromise<IPlanificacionDetails> {
  return axios.put(`api/planificacionesERP/${requestBody._id}`, requestBody);
}

export function deletePlanificacion(requestBody: string): AxiosPromise<void> {
  return axios.delete(`api/planificacionesERP/${requestBody}`);
}
