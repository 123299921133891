import axios, { AxiosPromise } from "axios";
import { Destinatario } from "../models/Email";
import { ParticipantesAdicionalesRequest, Persona, PersonasResponse, RolOperarioEmail, User, UsersFilters, UsersTablaResponse } from "../models/users/Users";
//import axios from './axiosToken';

export function getUsers(requestBody: UsersFilters): AxiosPromise<UsersTablaResponse> {
    return axios.get('api/users', { params: requestBody });
}

export function getUser(requestBody: string): AxiosPromise<User> {
    return axios.get(`api/users/${requestBody}`);
}

export function putUser(requestBody: User, userRut: String): AxiosPromise<User> {
    return axios.put(`api/users/${userRut}`, requestBody);
}

export function deleteUser(requestBody: String): AxiosPromise<void> {
    return axios.delete(`api/users/${requestBody}`);
}

export function postUser(requestBody: User): AxiosPromise<User> {
    return axios.post('api/users', requestBody);
}

export function getDestinatariosAperturaOP(): AxiosPromise<PersonasResponse> {
    return axios.get('api/users/correosPersonas/lista');
}

export function deleteDestinatarioAperturaOP(requestBody: string): AxiosPromise<PersonasResponse> {
    return axios.delete(`api/users/correosPersonas/lista/${requestBody}`);
}

export function postDestinatariosAperturaOP(requestBody: Destinatario[]): AxiosPromise<PersonasResponse> {
    return axios.post(`api/users/correosPersonas/lista`, { destinatarios: requestBody });
}

export function getCorreosEmisorOperarios(): AxiosPromise<RolOperarioEmail[]> {
    return axios.get('api/users/correos/operarios');
}

export function getCorreoEmisorOperario(rol: string): AxiosPromise<RolOperarioEmail> {
    return axios.get(`api/users/correos/operarios/${rol}`);
}

export function putCorreosEmisorOperarios(emails: RolOperarioEmail[]): AxiosPromise<RolOperarioEmail[]> {
    return axios.put(`api/users/correos/operarios`, { operariosEmails: emails });
}

export function getParticipantesAdicionales(requestBody: ParticipantesAdicionalesRequest): AxiosPromise<Persona[]> {
    return axios.get(`api/users/participantesAdicionales/participantes`, { params: requestBody });
}

export function deleteParticipanteAdicional(participante: ParticipantesAdicionalesRequest): AxiosPromise<Persona[]> {
    return axios.delete('api/users/participantesAdicionales/participantes', { params: participante });
}

export function postParticipantesAdicionales(participantes: ParticipantesAdicionalesRequest): AxiosPromise<Persona[]> {
    return axios.post('api/users/participantesAdicionales/participantes', participantes);
}

export function getDestinatariosEntregaEvaluacionReparacion(): AxiosPromise<PersonasResponse> {
    return axios.get('api/users/correosEntregaEvaluacionesReparaciones/personas');
}

export function deleteDestinatarioEntregaEvaluacionReparacion(requestBody: string): AxiosPromise<PersonasResponse> {
    return axios.delete(`api/users/correosEntregaEvaluacionesReparaciones/personas/${requestBody}`);
}

export function postDestinatarioEntregaEvaluacionReparacion(requestBody: Destinatario[]): AxiosPromise<PersonasResponse> {
    return axios.post(`api/users/correosEntregaEvaluacionesReparaciones/personas`, { destinatarios: requestBody });
}