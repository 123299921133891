import { ReactElement, useState } from "react";
import TipoDimension from "../../models/desviaciones/TipoDimension";
import { SelectOption } from "../../models/optionsFilters/Select";
import TipoGalpon from "../../models/planificacionesERP/TipoGalpon";
import useFormState from "../../hooks/useFormState";
import {
  formatDesviacionRequestBody,
  getEmptyDesviacionForm,
} from "../../utils/desviaciones";
import { DesviacionForm } from "../../models/desviaciones/Desviaciones";
import { postDesviacion } from "../../api/DesviacionesApi";
import cogoToast from "cogo-toast";
import { Button, Col, Modal } from "react-bootstrap";
import DesviacionFormComponent from "./DesviacionFormComponent";
import TipoMotivo from "../../models/desviaciones/TipoMotivo";

interface NuevaDesviacionProps {
  userPermission: boolean;
  galponDesviacionOptions: SelectOption<TipoGalpon>[];
  dimensionDesviacionOptions: SelectOption<TipoDimension>[];
  motivoPersonalOptions: SelectOption<TipoMotivo>[];
  motivoHerramientasOptions: SelectOption<TipoMotivo>[];
  motivoTOTOptions: SelectOption<TipoMotivo>[];
  motivoEquipoApoyoOptions: SelectOption<TipoMotivo>[];
  motivoInfraestructuraOptions: SelectOption<TipoMotivo>[];
  motivoRepuestosOptions: SelectOption<TipoMotivo>[];
  motivoProcedimientosOptions: SelectOption<TipoMotivo>[];
  onDesviacionCreated: () => void;
  id?: string;
  codigo?: string;
}

const NuevaDesviacion = ({
  userPermission,
  galponDesviacionOptions,
  dimensionDesviacionOptions,
  motivoPersonalOptions,
  motivoHerramientasOptions,
  motivoTOTOptions,
  motivoEquipoApoyoOptions,
  motivoInfraestructuraOptions,
  motivoRepuestosOptions,
  motivoProcedimientosOptions,
  onDesviacionCreated,
  id,
  codigo,
}: NuevaDesviacionProps): ReactElement => {
  const formState = useFormState(getEmptyDesviacionForm());

  const [showForm, setShowForm] = useState(false);
  const handleCloseForm = () => {
    setShowForm(false);
    formState.reset();
  };
  const handleShowForm = () => setShowForm(true);

  const submitForm = async (desviacionForm: DesviacionForm) => {
    try {
      let requestBody;
      if (id) {
        requestBody = formatDesviacionRequestBody(desviacionForm, id);
      } else {
        let galpon = "";
        if (codigo?.includes("G1")) {
          galpon = "G1";
        } else if (codigo?.includes("G2")) {
          galpon = "G2";
        } else if (codigo?.includes("G4")) {
          galpon = "G4";
        }
        requestBody = formatDesviacionRequestBody(
          desviacionForm,
          id,
          codigo,
          galpon
        );
      }

      const response = await postDesviacion(requestBody);

      if (response.data._id) {
        cogoToast.success("Desviación creada");
        onDesviacionCreated();
        handleCloseForm();
      } else {
        cogoToast.error("No fue posible crear la desviación");
      }
    } catch (error) {
      cogoToast.error("No fue posible crear la desviación");
    } finally {
      formState.setSubmitting(false);
    }
  };

  return (
    <>
      {userPermission && (
        <Button onClick={handleShowForm}>Nueva Desviación</Button>
      )}
      {showForm && (
        <Modal show={showForm} onHide={handleCloseForm}>
          <Modal.Header>
            <Col className="text-center">
              <Modal.Title>Nueva Desviación</Modal.Title>
            </Col>
          </Modal.Header>
          <Modal.Body>
            <DesviacionFormComponent
              formState={formState}
              submitForm={submitForm}
              onCancel={handleCloseForm}
              dimensionDesviacionOptions={dimensionDesviacionOptions}
              motivoPersonalOptions={motivoPersonalOptions}
              motivoHerramientasOptions={motivoHerramientasOptions}
              motivoTOTOptions={motivoTOTOptions}
              motivoEquipoApoyoOptions={motivoEquipoApoyoOptions}
              motivoInfraestructuraOptions={motivoInfraestructuraOptions}
              motivoProcedimientosOptions={motivoProcedimientosOptions}
              motivoRepuestosOptions={motivoRepuestosOptions}
              galponDesviacionOptions={galponDesviacionOptions}
              isCreating
            />
          </Modal.Body>
        </Modal>
      )}
    </>
  );
};

export default NuevaDesviacion;
