import {
  AreaForm,
  AreaGalponRequestBody,
  GalponForm,
  GalponRequestBody,
  PuestoForm,
  PuestoTrabajoGalponRequestBody,
  UpdateEstadoPuestoTrabajoRequestBody,
} from "../models/galpones/Galpon";

export const getEmptyGalponForm = (): GalponForm => ({
  nombre: "",
});

export const getEmptyAreaForm = (): AreaForm => ({
  id: "",
  nombre: "",
});

export const getEmptyPuestoForm = (): PuestoForm => ({
  id: "",
  areaIndex: 0,
  codigo: "",
});

export const formatGalponRequestBody = (
  galponForm: GalponForm
): GalponRequestBody => ({
  nombre: galponForm.nombre,
});

export const formatAreaRequestBody = (
  id: string,
  areaForm: AreaForm
): AreaGalponRequestBody => ({
  _id: id,
  nombre: areaForm.nombre,
});

export const formatPuestoRequestBody = (
  id: string,
  areaIndex: number,
  puestoForm: PuestoForm
): PuestoTrabajoGalponRequestBody => ({
  _id: id,
  areaIndex: areaIndex,
  codigo: puestoForm.codigo,
});

export const formatUpdatePuestoRequestBody = (
  id: string,
  areaIndex: number,
  puestoIndex: number,
  estado: string,
  codigo: string,
  planificacionId?: string
): UpdateEstadoPuestoTrabajoRequestBody => ({
  _id: id,
  areaIndex: areaIndex,
  puestoIndex: puestoIndex,
  estado: estado,
  codigo: codigo,
  planificacionId: planificacionId || undefined,
});
