import TipoMotivo from "../../models/desviaciones/TipoMotivo";
import { SelectOption } from "../../models/optionsFilters/Select";

const useMotivoTOT = (): SelectOption<TipoMotivo>[] => {
  return [
    { label: "SIN PROVEEDOR", value: { motivo: "SIN PROVEEDOR" } },
    { label: "RETRASADO", value: { motivo: "RETRASADO" } },
    { label: "PROBLEMA DE CALIDAD", value: { motivo: "PROBLEMA DE CALIDAD" } },
    { label: "NO ENVIADO", value: { motivo: "NO ENVIADO" } },
  ];
};

export default useMotivoTOT;
