import { SelectOption } from "../../models/optionsFilters/Select";
import TipoPrioridad from "../../models/planificacionesERP/TipoPrioridad";

const usePrioridadPlanificacion = (): SelectOption<TipoPrioridad>[] => {
  return [
    { label: "ASCENDENTE", value: { prioridad: "asc" } },
    { label: "DESCENDENTE", value: { prioridad: "desc" } },
  ];
};

export default usePrioridadPlanificacion;
